import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Paper from '@mui/material/Paper';
import './index.scss';
import { List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import TvIcon from '@mui/icons-material/Tv';
import BugReportIcon from '@mui/icons-material/BugReport';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import BadgeIcon from '@mui/icons-material/Badge';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material/styles';
import { ClickAwayListener } from '@mui/base';
import { logout } from '~/actions/auth';
import { APP_NAME, DRAWER_WIDTH } from '~/constants';
import { isolateIsFullScreen, isolateIsMenuOpen } from '~/selectors/ui';
import { closeMenu, openMenu } from '~/actions/ui';
import { isolateUserAccounts } from '~/selectors/user';
import { IUserAccountModel } from '~/api/users';
import { setUserAccount } from '~/actions/user';
import LoaderIcon from '~/components/loader-icon';
import Popper from '~/components/popper';
import { isolateUserAccountCode } from '~/selectors/auth';
import Copyright from '~/components/copyright';
import { isolateApiConfiguration } from '~/selectors/api';
import { ApiEnvironment } from '~/@types';

const AuthenticatedLayout = (props: IAuthenticatedLayoutProps) => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const { palette } = useTheme();
	const userAccounts = useSelector(isolateUserAccounts);
	// const [open, setOpen] = React.useState(false);
	const open = useSelector(isolateIsMenuOpen);
	const fullscreen = useSelector(isolateIsFullScreen);
	const drawerWidth: number = DRAWER_WIDTH;
	const [accountPopperEl, setAccountPopperEl] = useState<any>();
	const userAccountCode = useSelector(isolateUserAccountCode);
	const [currentAccount, setCurrentAccount] = useState(userAccounts.find(v => v.accountCode === userAccountCode));
	const [selectAccountOpen, setSelectAccountOpen] = useState(false);
	const { env } = useSelector(isolateApiConfiguration);
	const showDebug = env !== ApiEnvironment.prod;

	useEffect(() => {
		setCurrentAccount(userAccounts.find(v => v.accountCode === userAccountCode));
	}, [userAccountCode])

	interface AppBarProps extends MuiAppBarProps {
		open?: boolean;
	}

	const AppBar = styled(MuiAppBar, {
		shouldForwardProp: (prop) => prop !== 'open',
	})<AppBarProps>(({ theme, open }) => ({
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		...(open && {
			marginLeft: drawerWidth,
			width: `calc(100% - ${drawerWidth}px)`,
			transition: theme.transitions.create(['width', 'margin'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.enteringScreen,
			}),
		}),
	}));

	const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
		({ theme, open }) => ({
			'& .MuiDrawer-paper': {
				position: 'relative',
				whiteSpace: 'nowrap',
				width: drawerWidth,
				transition: theme.transitions.create('width', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.enteringScreen,
				}),
				boxSizing: 'border-box',
				...(!open && {
					overflowX: 'hidden',
					transition: theme.transitions.create('width', {
						easing: theme.transitions.easing.sharp,
						duration: theme.transitions.duration.leavingScreen,
					}),
					width: theme.spacing(7),
					[theme.breakpoints.up('sm')]: {
						width: theme.spacing(9),
					},
				}),
			},
		}),
	);

	const toggleDrawer = () => {
		if (open) dispatch(closeMenu());
		else dispatch(openMenu());
		// setOpen(!open);
	};

	const libNavigate = (s: string) => {
		navigate(s);
	}

	const pressAccount = (event: React.MouseEvent<HTMLDivElement>) => {
		const { currentTarget } = event;

		let ot = 0;
		let ol = 0;
		let par: HTMLElement = currentTarget;
		while (par) {
			const hPar = (par);
			ot += hPar.offsetTop;
			ol += hPar.offsetLeft;
			par = hPar.offsetParent as HTMLElement;
		}
		const top = ot + currentTarget.clientHeight;
		const left = ol;
		setAccountPopperEl({ getBoundingClientRect: () => { const v = currentTarget.getBoundingClientRect(); const a = { ...v.toJSON(), x: 0, y: 0, width: left, top, left }; return a as DOMRect; }, });
		setSelectAccountOpen(!selectAccountOpen);
	}

	const changeUserAccount = (v: IUserAccountModel) => {
		dispatch(setUserAccount(v.accountCode));
	}

	const renderUserAccount = (v: IUserAccountModel, i: number) => {
		return <ListItemButton key={`ib-sel-account-${i}`} onClick={() => changeUserAccount(v)}>
			<ListItemText primary={v.accountName} />
		</ListItemButton>;
	}

	return <div className={`authenticated-layout ${open ? 'menu-open' : 'menu-closed'} ${fullscreen ? 'fullscreen' : ''}`}>
		<Box sx={{ display: 'flex', flexGrow: 1 }}>
			<AppBar position="absolute" sx={{ color: fullscreen ? palette.text.primary : 'inherit', backgroundColor: fullscreen ? palette.background.default : '' }} open={open}>
				<Toolbar
					sx={{
						pr: '24px', // keep right padding when drawer closed
					}}
				>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={toggleDrawer}
						sx={{
							marginRight: '36px',
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						sx={{ flexGrow: 1 }}
					>
						{props.title} {fullscreen ? '' : ''}
					</Typography>
					{/* <IconButton color="inherit">
					<Badge badgeContent={4} color="secondary">
						<NotificationsIcon />
					</Badge>
				</IconButton> */}
				</Toolbar>
			</AppBar>
			<Drawer className={'drawer'} variant="permanent" open={open}>
				<Toolbar
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'space-between',
						px: [1],
					}}
				>
					<div className="toolbar-top">{APP_NAME}</div>
					<IconButton onClick={toggleDrawer}>
						<ChevronLeftIcon />
					</IconButton>
				</Toolbar>
				<Divider />
				{userAccounts.length > 1 && <ClickAwayListener onClickAway={() => setSelectAccountOpen(false)}>
					<List>
						<ListItemButton onClick={(e) => pressAccount(e)}>
							<ListItemIcon>
								<BadgeIcon />
							</ListItemIcon>
							<ListItemText className='user-account-select-option' primary={`${currentAccount?.accountName || userAccountCode}`} secondary={`${userAccounts.length === 1 ? currentAccount?.accountCode : `${userAccounts.length} Accounts`}`} />
						</ListItemButton>
					</List>

				</ClickAwayListener>}
				<Divider />
				<List component="nav">
					<ListItemButton onClick={() => libNavigate('/displays')}>
						<ListItemIcon>
							<TvIcon />
						</ListItemIcon>
						<ListItemText primary="Displays" />
					</ListItemButton>
					{showDebug &&
						<ListItemButton onClick={() => libNavigate('/debug')}>
							<ListItemIcon>
								<BugReportIcon />
							</ListItemIcon>
							<ListItemText primary="Debug" />
						</ListItemButton>
					}
					<Divider sx={{ my: 1 }} />
					<ListItemButton onClick={() => dispatch(logout())}>
						<ListItemIcon>
							<ExitToAppIcon />
						</ListItemIcon>
						<ListItemText primary="Logout" />
					</ListItemButton>
					<ListItemButton disabled={true} className='copyright'>
						<ListItemText primary={<Copyright />}>

						</ListItemText>
					</ListItemButton>
				</List>
			</Drawer>
			<Box
				className='main'
				component="main"
			>
				{/* <Toolbar /> */}


				{props.children}

			</Box>
		</Box>
		<Popper open={selectAccountOpen} anchorEl={accountPopperEl} className='paper-account-select'>
			<Paper>
				{userAccounts.length === 0 ? <LoaderIcon /> :
					<List>

						{userAccounts.map(renderUserAccount)}
					</List>
				}
			</Paper>

		</Popper>
	</div>
}

interface IAuthenticatedLayoutProps {
	children: JSX.Element;
	title: string;
}

export default AuthenticatedLayout;