import React, { useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import './details.scss';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertColor } from '@mui/material/Alert';
import { useDispatch, useSelector } from 'react-redux';
import * as ScreensHelpers from './helpers';
import FullScreenButtonBar from '~/components/full-screen-button-bar';
import LoaderIcon from '~/components/loader-icon';
import RouterTabs from '~/components/router-tabs';
import TabPanel from '~/components/tab-panel';
import DisplaysApi, * as DisplayTypes from '~/api/displays';
import { isolatePermissions, selectHasPermissions } from '~/selectors/auth';
import PermissionsService from '~/permissions';
import { fullScreenOff, fullScreenOn } from '~/actions/ui';
import RouterTab from '~/components/router-tab';
import { isolateLayouts, isolateTemplates, isolateWidgets } from '~/selectors/reference';
import { IDecodeWidgetFileUploadResponse, WIDGET_FILE_SEPARATOR, decodeWidgetFileUploadValue } from '~/utilities';
import AzureStorageService, { IQueuedUploadResponse } from '~/services/azure-storage';

const a11yProps = (index: number) => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	};
}

const DetailsTabPanel = (props: { index: number, children: JSX.Element, value: number }) => {
	const { children, index, value, } = props;
	return <TabPanel value={value} index={index} key={`tab-${index}`}>
		<Box sx={{ margin: '16px', }}>
			{children}
		</Box>
	</TabPanel>

}

const runOnEachWidget = (layouts: DisplayTypes.IDisplayDetailLayoutModel[], codeToRun: (decoded: IDecodeWidgetFileUploadResponse | undefined, prop: string, widgetData: DisplayTypes.IDisplayDetailsWidgetDataModel) => void) => {
	layouts.forEach(layout => {
		layout.regions.forEach(region => {
			for (const prop in region.widgetData) {
				const propVal = region.widgetData[prop];
				if (propVal && typeof propVal === 'string' && propVal.indexOf(WIDGET_FILE_SEPARATOR) !== -1) {
					const decoded = decodeWidgetFileUploadValue(propVal);
					codeToRun(decoded, prop, region.widgetData);
				}
			}
		})
	})
}

const removeUploadIdsFromLayouts = (layouts: DisplayTypes.IDisplayDetailLayoutModel[]) => {
	runOnEachWidget(layouts, (d, p, w) => {
		if (d) {
			w[p] = d.name;
		}
	});
	return layouts;
}

const getUploadIdsFromLayouts = (layouts: DisplayTypes.IDisplayDetailLayoutModel[]) => {
	const ids: number[] = [];
	runOnEachWidget(layouts, (d, p, w) => {
		if (d) {
			if (d.fileUploadId) ids.push(d.fileUploadId);
		}
	});
	return ids;
}

const replacePathOnUploadedFiles = (layouts: DisplayTypes.IDisplayDetailLayoutModel[], uploadResponses: IQueuedUploadResponse[]) => {
	runOnEachWidget(layouts, (d, p, w) => {
		if (d) {
			if (d.fileUploadId) {
				const resp = uploadResponses.find(response => response.id === d.fileUploadId);
				if (resp) {
					const { model, uploadComplete } = resp;
					if (uploadComplete) {
						const { libraryPath } = model;
						const flp = libraryPath;
						w[p] = flp;
					}
				}
			}
		}
	});
}

const DisplayDetails = () => {
	const [loading, setLoading] = React.useState(false);
	const [tabIndex, setTabIndex] = React.useState(0);
	const [disableSave, setDisableSave] = React.useState(false);
	const { pathname } = useLocation();
	const id = parseInt(useParams()?.displayId || '0', 10);
	const [displayName, setDisplayName] = React.useState<string>();
	const [displayId, setDisplayId] = React.useState(id);
	const [displaySiteId, setDisplaySiteId] = React.useState<number>();
	const [screenManufacturerId, setScreenManufacturerId] = React.useState<number>();
	const [screenModelId, setScreenModelId] = React.useState<number>();
	const [screenLongitude, setScreenLongitude] = React.useState<number>();
	const [screenLatitude, setScreenLatitude] = React.useState<number>();
	const [displayLongitude, setDisplayLongitude] = React.useState<number>();
	const [displayLatitude, setDisplayLatitude] = React.useState<number>();
	const [screenSerialNumber, setScreenSerialNumber] = React.useState<string>();
	const [displayLayouts, setDisplayLayouts] = React.useState<DisplayTypes.IDisplayDetailLayoutModel[]>([]);
	const [displayLayoutsLoaded, setDisplayLayoutsLoaded] = React.useState<DisplayTypes.IDisplayDetailLayoutModel[]>([]);
	const [displayDefaultOperatingModeId, setDisplayDefaultOperatingModeId] = React.useState<number>();
	const [displayCurrentOperatingModeId, setDisplayCurrentOperatingModeId] = React.useState<number>();
	const [displayTags, setDisplayTags] = React.useState<string[]>([]);
	const [displayRegions, setDisplayRegions] = React.useState<DisplayTypes.IDisplayDetailRegionModel[]>([]);
	const [displayConfigurationData, setDisplayConfigurationData] = React.useState<DisplayTypes.IDisplayDetailsConfigurationDataModel>();
	const [displayScreenshots, setDisplayScreenshots] = React.useState<DisplayTypes.IDisplayScreenScreenshotModel[]>([]);
	const [displayUptime, setDisplayUptime] = React.useState<DisplayTypes.IDisplayScreenUptimeModel[]>([]);
	const [snackbarOpen, setSnackbarOpen] = React.useState(false);
	const [snackbarMessage, setSnackbarMessage] = React.useState('');
	const [snackbarSeverity, setSnackbarSeverity] = React.useState<AlertColor>();
	const [layoutError, setLayoutError] = React.useState(false);
	const [detailsError, setDetailsError] = React.useState(false);
	const [widgetConfigurationError, setWidgetConfigurationError] = React.useState(false);
	const permissions: string[] = useSelector(isolatePermissions);
	const hasLayoutRPermission = selectHasPermissions(permissions, [PermissionsService.getName('display', 'configuration')], true);
	const hasLayoutWPermission = selectHasPermissions(permissions, [PermissionsService.getName('display', 'configuration', true)], true);
	const referenceWidgets = useSelector(isolateWidgets);
	const referenceLayouts = useSelector(isolateLayouts);
	const referenceTemplates = useSelector(isolateTemplates);

	const dispatch = useDispatch();

	const validate = () => {
		const model = getDisplayDetailModel();
		return ScreensHelpers.validateScreen({ entity: model, data: { referenceLayouts, referenceTemplates, referenceWidgets } });
	}

	const evaluateSave = () => {
		const { collection, definition } = validate();
		const le = !definition.layouts || widgetConfigurationError;
		setLayoutError(le);
		const de = !definition.displayName || !definition.latitude || !definition.longitude || !definition.siteId;
		setDetailsError(de)
		const newDisableSave = collection.indexOf(false) !== -1 || le;
		// console.log(`newDisableSave ${newDisableSave}
		// le ${le}
		// de ${de}
		// collection ${JSON.stringify(collection)}
		// definition ${JSON.stringify(definition)}
		// `)
		setDisableSave(newDisableSave);
		// setDisableSave(false)
	}

	useEffect(() => {
		if (displayId && !displayName) void getData();
		dispatch(fullScreenOn());
		return () => {
			dispatch(fullScreenOff());
		}
	}, []);


	useEffect(() => {
		return () => {
			unqueueUploads();
		}
	}, [id])

	useEffect(() => evaluateSave());

	const unqueueUploads = () => {
		AzureStorageService.dequeueUpload({ context: { model: { displayId: id }, matcher: (a: { displayId: number }, b: { displayId: number }) => a.displayId === b.displayId } });
	}

	const getData = async () => {
		setLoading(true);
		if (loading) return;
		
		const getDisplay = DisplaysApi.getDisplays(displayId).then(v => {

			if (v) {
				const { configurationData, latitude, longitude, layouts, siteId, displayName, displayTags, currentOperatingModeId, defaultOperatingModeId, screens } = v;
				const screen = screens && screens.length > 0 ? screens[0] : undefined;
				setDisplayName(displayName || '');
				setDisplaySiteId(siteId);
				setDisplayCurrentOperatingModeId(currentOperatingModeId);
				setDisplayDefaultOperatingModeId(defaultOperatingModeId);
				setDisplayLayouts(layouts);
				setDisplayLayoutsLoaded(layouts);
				setDisplayConfigurationData(configurationData)
				setDisplayTags(displayTags);
				setDisplayLatitude(latitude);
				setDisplayLongitude(longitude);
				if (screen) {
					const { manufacturerId, modelId, serialNumber, recentScreenshots, recentUptime } = screen;
					setScreenManufacturerId(manufacturerId);
					setScreenModelId(modelId);
					setScreenSerialNumber(serialNumber);
					setDisplayUptime(recentUptime || []);
					setDisplayScreenshots(recentScreenshots || []);
				}
			}

		});

		await getDisplay.finally(() => { setLoading(false); });
	}

	const getDisplayDetailModel = () => {
		const display: DisplayTypes.IDisplayDetailModel = {
			displayId,
			displayName: displayName || '',
			configurationData: displayConfigurationData,
			siteId: displaySiteId || 0,
			displayTags: displayTags || [],
			layouts: sanitizeLayouts(displayLayouts) || [],
			currentOperatingModeId: displayCurrentOperatingModeId || 0,
			defaultOperatingModeId: displayDefaultOperatingModeId || 0,
			latitude: displayLatitude,
			longitude: displayLongitude,
		};
		return display;
	}

	const sanitizeLayouts = (layouts: DisplayTypes.IDisplayDetailLayoutModel[]) => {
		return layouts.filter(v => v.operatingModeId !== -1);
	}

	const handleCancelSaveDetails = () => {
		void getData();
	}

	const getSaveDisplayDetailModel = (model: DisplayTypes.IDisplayDetailModel) => {
		const { layouts, ...rest } = model;
		return { ...rest, layouts: removeUploadIdsFromLayouts(layouts) };
	}

	const handleSaveDetails = async () => {
		const screen = getDisplayDetailModel();

		const ids = getUploadIdsFromLayouts(screen.layouts);
		// console.log(`upload ids ${ids.join(', ')}`)
		const uploadResponses = await AzureStorageService.processQueuedUploads(ids.length > 0 ? ids : []);

		if (uploadResponses.findIndex(v => v.uploadComplete === false) === -1) {
			replacePathOnUploadedFiles(screen.layouts, uploadResponses);

			setDisplayLayouts(screen.layouts);

			const saveModel = getSaveDisplayDetailModel(screen);

			await DisplaysApi.setDisplay(saveModel).then(() => {
				setSnackbarOpen(true);
				setSnackbarSeverity('success');
				setSnackbarMessage('Display saved');
			}).catch(() => {
				setSnackbarOpen(true);
				setSnackbarSeverity('error');
				setSnackbarMessage('There was an error saving the display');
			});
		} else {
			setSnackbarOpen(true);
			setSnackbarSeverity('error');
			setSnackbarMessage('There was an error saving one or more uploads for the display. Please try again.');
		}
	}

	const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
		if (reason === 'clickaway') {
			return;
		}

		setSnackbarOpen(false);
	}


	const outletContext = {
		displayName, setDisplayName,
		displaySiteId, setDisplaySiteId,
		displayId, setDisplayId,
		screenSerialNumber, setScreenSerialNumber,
		displayTags, setDisplayTags,
		displayLayouts, setDisplayLayouts,
		displayRegions, setDisplayRegions,
		screenManufacturerId, setScreenManufacturerId,
		screenModelId, setScreenModelId,
		displayCurrentOperatingModeId, setDisplayCurrentOperatingModeId,
		displayDefaultOperatingModeId, setDisplayDefaultOperatingModeId,
		displayLatitude, setDisplayLatitude,
		displayLongitude, setDisplayLongitude,
		displayConfigurationData, setDisplayConfigurationData,
		screenLatitude, setScreenLatitude,
		screenLongitude, setScreenLongitude,
		layoutError, setLayoutError,
		displayScreenshots, setDisplayScreenshots,
		displayUptime, setDisplayUptime,
		readLayout: hasLayoutRPermission,
		writeLayout: hasLayoutWPermission
	};

	const outlet = <Outlet context={outletContext} />;

	return <div className="screens-details">
		{loading && <LoaderIcon />}
		{!loading && <>
			<Box>
				<RouterTabs value={tabIndex} onChange={(e, v: number) => { setTabIndex(v) }} routes={[`/displays/${displayId}/details`, `/displays/${displayId}/configuration`, `/displays/${displayId}/screenshots`, `/displays/${displayId}/status`]} aria-label="Screen Details Tabs">
					<RouterTab error={detailsError} errorText={detailsError ? 'There are issues validating the display details' : undefined} label="Details" {...a11yProps(0)} />
					{hasLayoutRPermission && <RouterTab error={layoutError} errorText={layoutError ? 'There are issues validating the configuration' : undefined} label="Configuration" {...a11yProps(1)} />}
					<RouterTab label="Screenshots" {...a11yProps(2)} />
					<RouterTab label="Status" {...a11yProps(3)} />
				</RouterTabs>
			</Box>
			<DetailsTabPanel value={tabIndex} index={0}>
				{outlet}
			</DetailsTabPanel>
			<DetailsTabPanel value={tabIndex} index={1}>
				{outlet}
			</DetailsTabPanel>
			<DetailsTabPanel value={tabIndex} index={2}>
				{outlet}
			</DetailsTabPanel>
			<DetailsTabPanel value={tabIndex} index={3}>
				{outlet}
			</DetailsTabPanel>
		</>
		}
		<Snackbar
			open={snackbarOpen}
			onClose={handleSnackbarClose}
			autoHideDuration={2000}>
			<MuiAlert variant='filled' severity={snackbarSeverity || 'info'}>{snackbarMessage}</MuiAlert>
		</Snackbar>
		<FullScreenButtonBar onSave={handleSaveDetails} onCancel={handleCancelSaveDetails} saveDisabled={disableSave} />
	</div>
}

export interface IDisplayDetailsOutletContext {
	displayName: string;
	setDisplayName: React.Dispatch<React.SetStateAction<string>>;
	displayId: number;
	setDisplayId: React.Dispatch<React.SetStateAction<number>>;
	displayLayouts: DisplayTypes.IDisplayDetailLayoutModel[];
	setDisplayLayouts: React.Dispatch<React.SetStateAction<DisplayTypes.IDisplayDetailLayoutModel[]>>;
	screenModelId: number;
	setScreenModelId: React.Dispatch<React.SetStateAction<number>>;
	displaySiteId: number;
	setDisplaySiteId: React.Dispatch<React.SetStateAction<number>>;
	displayLatitude: number;
	setDisplayLatitude: React.Dispatch<React.SetStateAction<number>>;
	displayLongitude: number;
	setDisplayLongitude: React.Dispatch<React.SetStateAction<number>>;
	displayCurrentOperatingModeId: number;
	setDisplayCurrentOperatingModeId: React.Dispatch<React.SetStateAction<number>>;
	displayDefaultOperatingModeId: number;
	setDisplayDefaultOperatingModeId: React.Dispatch<React.SetStateAction<number>>;
	screenManufacturerId: number;
	setScreenManufacturerId: React.Dispatch<React.SetStateAction<number>>;
	screenSerialNumber: string;
	setScreenSerialNumber: React.Dispatch<React.SetStateAction<string>>;
	screenLatitude: number;
	setScreenLatitude: React.Dispatch<React.SetStateAction<number>>;
	screenLongitude: number;
	setScreenLongitude: React.Dispatch<React.SetStateAction<number>>;
	displayTags: string[];
	setDisplayTags: React.Dispatch<React.SetStateAction<string[]>>;
	displayConfigurationata: DisplayTypes.IDisplayDetailsConfigurationDataModel;
	setDisplayConfigurationData: React.Dispatch<React.SetStateAction<DisplayTypes.IDisplayDetailsConfigurationDataModel>>;
	layoutError: boolean;
	setLayoutError: React.Dispatch<React.SetStateAction<boolean>>;
	displayScreenshots: DisplayTypes.IDisplayScreenScreenshotModel[];
	setDisplayScreenshots: React.Dispatch<React.SetStateAction<DisplayTypes.IDisplayScreenScreenshotModel[]>>;
	displayUptime: DisplayTypes.IDisplayScreenUptimeModel[];
	setDisplayUptime: React.Dispatch<React.SetStateAction<DisplayTypes.IDisplayScreenUptimeModel[]>>;
	readLayout: boolean;
	writeLayout: boolean;
}

export default DisplayDetails;