import merge from 'deepmerge';
import { isNullOrEmpty } from './utilities';
class PermissionsResolver {
	public getName(section: 'display', key: string = '', isWrite: boolean = false): string {
		const name = `${section}.${isWrite ? 'write' : 'read'}${!isNullOrEmpty(key) ? `.${key}` : ''}`;
		const log = false;
		if (log) console.log(`get perm name (${name}) for
		section ${section}
		isWrite ${isWrite}
		key ${key}`);
		return name;
	}

	public getModelForRole(name: string): string[] {
		try {
			switch (name) {
				case 'ContentManager': return this.convertModelToStrings(contentManagerPermissions);
				case 'SuperUser': return this.convertModelToStrings(superUserPermissions);
				case 'ReadOnly': return this.convertModelToStrings(readOnlyPermissions);
				default: return [];
			}
		} catch (ex) {
			console.error(ex);
			return [];
		}
	}

	private convertModelToStrings(perm: IAppPermissions) {
		return this.iterator(perm);
	}

	private iterator(v: IAppPermissions | IAppPermissionReadWriteModel<any, any>, parentProps: string[] = []): string[] {
		const trueProps: string[] = [];
		for (const prop in v) {
			if (typeof prop === 'string') {
				const value = (v as { [x: string]: string })[prop] as any;
				if (typeof value === 'object') trueProps.push(...this.iterator(value as { [x: string]: string }, parentProps.length > 0 ? [...parentProps, prop] : [prop]));
				else if (typeof value === 'boolean' && value) trueProps.push([...parentProps, prop].join('.'));
			}
		}
		return trueProps;
	}
}

/* To add permissions, find the corresponding read and write models, then add either a boolean or an object (with accompanying interface) which has boolean properties. Then update the role maps below to reflect your new permissions as needed.
   The permission can be checked using the name of the property as described in dot notation (e.g. train.read.facilities or train.write.actions.cancel) */

interface IAppPermissions {
	display?: IAppPermissionReadWriteModel<IScreenReadPermissionModel, IScreenWritePermissionModel>;

}

interface IAppPermissionReadWriteModel<R, W> {
	read?: R;
	write?: W;
}

// #region trains

interface IScreenReadPermissionModel {
	configuration?: boolean;
}


interface IScreenWritePermissionModel {
	configuration?: boolean;
}

// #endregion trains 

// #region maps

const readOnlyPermissions: IAppPermissions = {
	display: {
		read: {
			configuration: true,
		},
	},
}

let source = readOnlyPermissions;

const contentManagerPermissions: IAppPermissions = merge(source, {
	display: {
		write: {
			configuration: true,
		}
	}
});

source = contentManagerPermissions;

const superUserPermissions: IAppPermissions = merge(source, {});

// #endregion maps

const PermissionsService = new PermissionsResolver();
export default PermissionsService;
