import FormControl from '@mui/material/FormControl';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import React from 'react';

const InputText = (props: TextFieldProps) => {
	return <FormControl fullWidth>
		<TextField variant="outlined" {...props} />
	</FormControl>
}

export default InputText;