import axiosClient from './client';
// import axiosClient from './client';

const API_ROOT = 'display'

class DisplaysApiBase {
	public async searchDisplays(): Promise<IDisplaySearchModel[]> {
		return await axiosClient.get(`${API_ROOT}`);
	}

	public async getDisplays(id: number): Promise<IDisplayDetailModel> {
		const model = await axiosClient.get<IDisplayDetailModel, IDisplayDetailSaveModel>(`${API_ROOT}/${id}`);
		return this.dataMapper([model])[0];
	}

	public async setDisplay(models: IDisplayDetailModel): Promise<IDisplayDetailSaveModel> {
		const converted = this.dataMapper([models], 'out');
		const model = converted.length === 1 ? converted[0] as IDisplayDetailSaveModel : undefined;
		return await axiosClient.post<IDisplayDetailSaveModel, IDisplayDetailSaveModel>(`${API_ROOT}/${models.displayId}`, model);
	}

	private dataMapper(models: IDisplayDetailModel[] | IDisplayDetailSaveModel[], direction: 'in' | 'out' = 'in') {
		return models.map(display => {
			const isIn = direction === 'in';
			const { configurationData, layouts, ...rest } = display;
			const mappedLayouts = isIn ? layouts.map(layout => {
				const { regions, ...irest } = layout;
				const mappedRegions = regions.map(v => { const { widgetData, ...iirest } = v; const mappedWidgetData = JSON.parse(widgetData.toString()); return { widgetData: mappedWidgetData, ...iirest } });
				return { regions: mappedRegions, ...irest };
			}) : layouts.map(layout => {
				const { regions, ...irest } = layout;
				const mappedRegions = regions.map(v => { const { widgetData, ...iirest } = v; const mappedWidgetData = JSON.stringify(widgetData); return { widgetData: mappedWidgetData, ...iirest } });
				return { regions: mappedRegions, ...irest };
			});

			const retVal = isIn ? { configurationData: configurationData ? JSON.parse(configurationData as string) : undefined, layouts: mappedLayouts,  ...rest } : { configurationData: configurationData ? JSON.stringify(configurationData) : undefined, layouts: mappedLayouts, ...rest };
			return retVal;
		});
	}
}

export interface IDisplayKeyModel {
	displayId: number;
}

export interface IDisplayModel extends IDisplayKeyModel {
	displayName: string;
	siteId: number;
	latitude?: number;
	longitude?: number;
	defaultOperatingModeId: number;
	currentOperatingModeId: number;
	displayTags: string[]
}

export interface IDisplaySearchModel extends IDisplayModel {
	online?: boolean;
	lastCommunication?: Date;
}


export interface IDisplayDetailModel extends IDisplayModel {
	configurationData?: IDisplayDetailsConfigurationDataModel;
	layouts: IDisplayDetailLayoutModel[];
	screens?: IDisplayScreenModel[];
}

export interface IDisplayScreenModel {
	manufacturerId: number;
	modelId: number;
	serialNumber: string;
	lastHeartbeat: Date;
	recentScreenshots: IDisplayScreenScreenshotModel[];
	recentUptime: IDisplayScreenUptimeModel[];
}

export interface IDisplayScreenScreenshotModel {
	timestamp: Date;
	storagePath: string;
}

export interface IDisplayScreenUptimeModel {
	date: Date;
	periods: IDisplayScreenUptimePeriodModel[];
}

export interface IDisplayScreenUptimePeriodModel {
	status: DisplayScreenUptimePeriodStatus;
	duration: number;
}

export enum DisplayScreenUptimePeriodStatus {
	Offline = 'Offline',
	Online = 'Online',
	Unknown = 'Unknown',
}

interface IDisplayDetailSaveModel extends Omit<IDisplayDetailModel, 'configurationData' | 'layouts'> {
	configurationData?: string;
	layouts: IDisplayDetailLayoutSaveModel[];
}

export interface IDisplayDetailsConfigurationDataModel {
	[x: string]: any;
}

export interface IDisplayDetailLayoutModel {
	templateId: number;
	layoutId: number;
	operatingModeId: number;
	regions: IDisplayDetailRegionModel[];
}

interface IDisplayDetailLayoutSaveModel extends Omit<IDisplayDetailLayoutModel, 'regions'> {
	regions: IDisplayDetailRegionSaveModel[];
}

export interface IDisplayDetailRegionModel {
	regionId: number;
	widgetData: IDisplayDetailsWidgetDataModel;
}

interface IDisplayDetailRegionSaveModel extends Omit<IDisplayDetailRegionModel, 'widgetData'> {
	widgetData: string;
}

export interface IDisplayDetailsWidgetDataModel {
	[x: string]: any;
}

const DisplaysApi = new DisplaysApiBase();
export default DisplaysApi;