import axiosClient from './client';

const API_ROOT = 'user'

class UsersApiBase {
	public async getAvailableAccounts(): Promise<IUserAccountModel[]> {
		return await axiosClient.get(`${API_ROOT}/accounts`);
	}

	public async setCurrentAccount(accountCode: string): Promise<void> {
		return await axiosClient.post(`${API_ROOT}/active-account?accountCode=${accountCode}`);
	}
}

export interface IUserAccountModel {
	accountName: string;
	accountCode: string;
}

const UsersApi = new UsersApiBase();
export default UsersApi;