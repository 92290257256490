import { NavigationClient, NavigationOptions } from '@azure/msal-browser';
import { NavigateOptions } from 'react-router-dom';
import { sleep } from '~/utilities';

/**
 * Extending the default NavigationClient allows you to overwrite just navigateInternal while continuing to use the default navigateExternal function
 * If you would like to overwrite both you can implement INavigationClient directly instead
 */
class CustomNavigationClient extends NavigationClient {
	private navigate: (to: string, options?: NavigateOptions | undefined) => void;

	constructor(navigate: (to: string, options?: NavigateOptions | undefined) => void) {
		super();
		this.navigate = navigate // Passed in from useNavigate hook provided by react-router-dom;
	}

	// This function will be called anytime msal needs to navigate from one page in your application to another
	public async navigateInternal(url: string, options: NavigationOptions) {
		// alert('navigate internal')
		await sleep(0);
		console.debug(`%caoqwpslk  navigate internal 
		url ${url}
		options ${JSON.stringify(options)}`,'color: orange')
		// url will be absolute, you will need to parse out the relative path to provide to the history API
		const relativePath = url.replace(window.location.origin, '');
		if (options.noHistory) {
			this.navigate(relativePath, { replace: true });
		} else {
			this.navigate(relativePath);
		}

		return false;
	}
}

export default CustomNavigationClient;