import { AuthenticationResult } from '@azure/msal-browser';
import { Action } from 'redux';

export interface IAppAction extends Action<any> {
	type: string;
	payload?: any;
	message?: string;
}

export interface ITokenResponse {
	container: string;
	token: string;
}

export enum ApiEnvironment {
	dev = 'dev',
	stage = 'stage',
	qa = 'qa',
	prod = 'prod',
	training = 'training'
}
export interface IBBXCustomCredentials extends IAuthAuthTokenClaims {
	accessToken: string;
	idToken?: string | undefined;
	refreshToken?: string | undefined;
	expiresIn: number;
	scope: string;
	tokenType: string;
}

export interface IBBXCustomUserProfile extends IAuthIdTokenClaims {
	email: string;
	emailVerified: boolean;
	familyName: string;
	givenName: string;
	name: string;
	nickname: string;
	picture: string;
	sub: string;
	updatedAt: string;
}

export interface IAuthIdTokenClaims {
	userId: number;
	currentAccountCode: string;
	role: string;
	env: string[];
}

export interface IAuthAuthTokenClaims {
	userId: number;
	currentAccountCode: string;
	role: string;
}

export type BBXAuthenticationResult = AuthenticationResult & {
	idTokenClaims: BBXIDDokenClaims;
}

export type BBXIDDokenClaims = {
	exp: number;
	nbf: number;
	ver: string;
	iss: string;
	sub: string;
	aud: string;
	nonce: string;
	iat: number;
	auth_time: number;
	given_name: string;
	family_name: string;
	name: string;
	email: string;
	idp: string;
	applicationId: string;
	userId: number;
	currentAccountCode: string;
	role: string;
	env: string[],
	tfp: string,
}