import { AnyAction, } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import * as actions from '~/actions';
import { BBXAuthenticationResult } from '~/@types';

const INITIAL_STATE: IAuthState = { isRefreshing: false, expiry: undefined };

const stateReducer = (state: IAuthState = INITIAL_STATE, action: AnyAction | PayloadAction<BBXAuthenticationResult>): IAuthState => {

	switch (action.type) {
		case actions.AUTH_REFRESH_SUCCESS: {
			return { ...state, isRefreshing: false, expiry: dayjs(new Date()).add(60, 'minutes').toDate() };
		}
		case actions.AUTH_SUCCESS: {
			const typedAction: PayloadAction<BBXAuthenticationResult> = action as PayloadAction<BBXAuthenticationResult>;
			const { payload } = typedAction;
			const { idTokenClaims } = payload;
			const { exp } = idTokenClaims;
			return { ...state, isRefreshing: false, expiry: dayjs(exp * 1000).toDate() }

		}
		case actions.AUTH_FAILURE:
		case actions.AUTH_REFRESH_FAILURE:
		// fall through
		case actions.AUTH_LOGOUT_SUCCESS:
			return INITIAL_STATE;
		case actions.AUTH_REFRESH_REQUESTED:
			return { ...state, isRefreshing: true };
		default:
			return state;
	}
}

export interface IAuthState {
	isRefreshing: boolean;
	expiry: Date | undefined;
}

export default stateReducer;