import React from 'react';
import { useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box/Box';
import Grid from '@mui/material/Grid';
import { IDisplayDetailsOutletContext } from '../../details';
import EditDisplayLayoutConfiguration from './configuration/layout-configuration';
import { isolateLayouts, isolateOperationModes } from '~/selectors/reference';
import * as ReferenceTypes from '~/api/reference';
import './configuration.scss';



const EditDisplayLayoutDetails = () => {
	const {
		displayCurrentOperatingModeId,
		displayDefaultOperatingModeId,
		displayLayouts,
		readLayout, writeLayout
	} = useOutletContext<IDisplayDetailsOutletContext>();
	const operatingModes: ReferenceTypes.IOperatingModeModel[] = useSelector(isolateOperationModes);
	const screenLayouts = useSelector(isolateLayouts);
	const currentOperatingMode = operatingModes.find(v => v.operatingModeId === displayCurrentOperatingModeId);
	const defaultOperatingMode = operatingModes.find(v => v.operatingModeId === displayDefaultOperatingModeId);
	const [activeOperatingModeId, setActiveOperatingModeId] = React.useState<number>(displayCurrentOperatingModeId);

	const pressOperatingMode = (mode: number) => {
		setActiveOperatingModeId(mode);
	}

	return !readLayout ? <></> : <Box>
		<Grid className='layout-editor' container spacing={2}>
			<Grid item xs={12}>
				<EditDisplayLayoutConfiguration operatingModeId={activeOperatingModeId || 0}></EditDisplayLayoutConfiguration>
			</Grid>
		</Grid>
	</Box >
}

export default EditDisplayLayoutDetails;