import { LogLevel } from '@azure/msal-browser';

const TENANT_NAME = 'blackboxrail';
const MSAL_CLIENT_ID: string = '9c694842-c33c-4649-92f9-7d2d42963715';
export const MSAL_API_CLIENT_ID: string = 'frontline-api';

export const MSAL_CONFIG = {
	auth: {
		clientId: MSAL_CLIENT_ID,
		authorityBase: `https://${TENANT_NAME}.b2clogin.com/${TENANT_NAME}.onmicrosoft.com`,
		policies: { signInSignUp: 'B2C_1A_SIGNUP_SIGNIN', passwordReset: 'B2C_1A_PASSWORDRESET' },
		postLogoutRedirectUri: '/login',
		redirectUri: '/login',
	},
	system: {
		loggerOptions: {
			loggerCallback: (level: LogLevel, message: string, containsPii: boolean): void => {
				if (containsPii) {
					return;
				}
				switch (level) {
					case LogLevel.Error:
						console.error(message);
						return;
					case LogLevel.Info:
						console.info(message);
						return;
					case LogLevel.Verbose:
						console.debug(message);
						return;
					case LogLevel.Warning:
						console.warn(message);
						return;
				}
			},
			piiLoggingEnabled: false
		},
		windowHashTimeout: 60000,
		iframeHashTimeout: 6000,
		loadFrameTimeout: 0,
		asyncPopups: false
	}
};


const MSAL_LOGIN_REQUEST = {
	scopes: [`https://${TENANT_NAME}.onmicrosoft.com/${MSAL_API_CLIENT_ID}/api:access`]
};

export const getEnvironmentClaim = (environmentName: string) => `https://${TENANT_NAME}.onmicrosoft.com/${MSAL_API_CLIENT_ID}/env:${environmentName}`

export const getLoginRequest = (environmentName: string) => {
	return {
		...MSAL_LOGIN_REQUEST,
		scopes: [
			...MSAL_LOGIN_REQUEST.scopes,
			getEnvironmentClaim(environmentName)
		]
	};
}
