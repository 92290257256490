import React, { useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { useTheme } from '@mui/material/styles';
import InputButton from '../input-button';
import './index.scss';

const FullScreenButtonBar = (props: IFullScreenButtonBarProps) => {
	const { palette } = useTheme();
	const { onCancel, onSave, saveDisabled } = props;
	const [loading, setLoading] = useState(false);
	const [success, setSuccess] = useState(false);

	const handleSaveClick = () => {
		setLoading(true);
		if (onSave) void onSave().finally(() => setLoading(false));
	}

	const handleCancelClick = () => {
		if (onCancel) onCancel();

	}

	const background = palette.background.default;

	return <div className="full-screen-button-bar" style={{ background: `linear-gradient(to bottom right, transparent 47%, ${background})` }}>
		<div className="cancel-wrapper">
			<InputButton
				style={{ background }}
				onClick={handleCancelClick}
			>
				Cancel
			</InputButton>
		</div>
		<div className="stacked-save">
			<InputButton
				variant='contained'
				disabled={loading || saveDisabled}
				onClick={handleSaveClick}
			>
				Save
			</InputButton>
			{loading && <CircularProgress size={24} />}
		</div>
	</div>
}

interface IFullScreenButtonBarProps {
	onCancel: () => void;
	onSave: () => Promise<void>;
	saveDisabled: boolean;
}

export default FullScreenButtonBar;