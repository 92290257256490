import { PayloadAction } from '@reduxjs/toolkit';
import { ApiEnvironment } from '~/@types/index';
import { API_UPDATE_ENVIRONMENT_REQUESTED } from '~/actions';
import { isNullOrEmpty } from '~/utilities';
import { API_HOST, WEB_HOST } from '~/constants';

const USE_LOCAL_API = false;
const LOCAL_API_URL = 'http://localhost:12345';

const getIsLocalhost = () => {
	return window.location.host.indexOf('localhost') !== -1;
}

const g = (s: string, sep: string = '.') => {
	try {
		const sp = s.split(sep);
		const sg = sp.slice(1);
		return { p: sp[0], s: sg.join(sep) };
	} catch (ex) {
		console.error(ex);
		return { p: undefined, s: undefined };
	}
}

const getWebEnvFromUrl = () => {
	const fallbackEnv = ApiEnvironment.prod;

	try {
		const w = window;
		const url = w.location.host.toString();
		const prefSufRec = g(WEB_HOST)
		const whPref = prefSufRec?.p || undefined;
		const whSuf = prefSufRec?.s || undefined;
		const valPrefAndSuff = whPref !== undefined && whSuf !== undefined;
		const isHostedUrl = valPrefAndSuff ? url.indexOf(whSuf) !== -1 && url.indexOf(whPref) !== -1 : false;
		const isLocalhost = url.indexOf('localhost') !== -1;
		if (isHostedUrl) {
			if (url.indexOf('dev') !== -1) return ApiEnvironment.dev;
			if (url.indexOf('qa') !== -1) return ApiEnvironment.qa;
			if (url.indexOf('staging') !== -1) return ApiEnvironment.stage;
			if (url.indexOf('training') !== -1) return ApiEnvironment.training;
			else return ApiEnvironment.prod;
		} else if (isLocalhost)
			return ApiEnvironment.dev;

		return fallbackEnv;
	} catch (ex) {
		return fallbackEnv;
	}
}

const WEB_ENV = getWebEnvFromUrl();

const DEFAULT_ENVIRONMENT = WEB_ENV;
const BASE_URL_SPLIT = g(API_HOST);
const BASE_URL_PREF: string = `https://${BASE_URL_SPLIT.p}`
const BASE_URL_SUF: string = `.${BASE_URL_SPLIT.s}/`

export const ENV_URLS = {
	dev: `${BASE_URL_PREF}.dev${BASE_URL_SUF}`,
	stage: `${BASE_URL_PREF}.staging${BASE_URL_SUF}`,
	qa: `${BASE_URL_PREF}.qa${BASE_URL_SUF}`,
	training: `${BASE_URL_PREF}.training${BASE_URL_SUF}`,
	prod: `${BASE_URL_PREF}${BASE_URL_SUF}`
}

const getFinalUrl = (env: ApiEnvironment) => {
	const v: string = !USE_LOCAL_API ? ENV_URLS[`${env}`] : LOCAL_API_URL;
	if (!v) return '';
	if (!v.endsWith('/')) return `${v}/`;
	return v;
}

const getUiUrl = (apiUrl: string) => { 
	const asp = API_HOST.split('.');
	const wsp = WEB_HOST.split('.');
	return apiUrl.replace(asp[0], wsp[0]);
}

const INITIAL_STATE: IApiConfiguration = { env: DEFAULT_ENVIRONMENT, apiUrl: getFinalUrl(DEFAULT_ENVIRONMENT), uiUrl: getUiUrl(getFinalUrl(DEFAULT_ENVIRONMENT)), isLocalhost: getIsLocalhost() };

const apiReducer = (state: IApiConfiguration = INITIAL_STATE, action: PayloadAction<ApiEnvironment>): IApiConfiguration => {
	switch (action.type) {
		case API_UPDATE_ENVIRONMENT_REQUESTED: {
			try {
				const finalUrl = getFinalUrl(action.payload);
				const apiUrl = isNullOrEmpty(finalUrl) ? ENV_URLS.prod : finalUrl;
				const uiUrl = getUiUrl(apiUrl);
				const newState = { env: action.payload, apiUrl, uiUrl, isLocalhost: getIsLocalhost() };
				return { ...state, ...newState };
			} catch (ex) {
				const newState = { env: action.payload, apiUrl: ENV_URLS.prod, uiUrl: getUiUrl(ENV_URLS.prod), isLocalhost: getIsLocalhost() };
				return { ...state, ...newState }
			}
		}
		default: {
			try {
				const finalUrl = getFinalUrl(state.env);
				const apiUrl = isNullOrEmpty(finalUrl) ? ENV_URLS.prod : finalUrl;
				const newState = { env: state.env, apiUrl, uiUrl: getUiUrl(apiUrl), isLocalhost: getIsLocalhost() };

				return { ...state, ...newState };
			} catch (ex) {
				return state;
			}
		}
	}
}

export default apiReducer;

export interface IApiConfiguration {
	env: ApiEnvironment;
	apiUrl: string;
	uiUrl: string;
	isLocalhost: boolean;
}
