import { Action } from 'redux';
import * as Actions from '~/actions'

export const logout = (): Action => {
	return { type: Actions.AUTH_LOGOUT_REQUESTED }
}

export const login = (): Action => {
	return { type: Actions.AUTH_REQUESTED }
}

export const refresh = (): Action => {
	return { type: Actions.AUTH_REFRESH_REQUESTED }
}
