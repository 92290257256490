import React, { ComponentProps, ErrorInfo } from 'react';
import './index.scss';

// this doesn't work for reasons unclear

export class ErrorBoundary extends React.Component<IErrorBoundaryProps> {
	public state: IErrorBoundaryState;
	
	constructor(props: ComponentProps<any>) {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
		super(props);
		this.state = { hasError: false };
	}

	public static getDerivedStateFromError(error: Error) {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
		// You can also log the error to an error reporting service
		// logErrorToMyService(error, errorInfo);
	}

	public render() {
		if (this.state.hasError) {
			return <div className="error-boundary">Something Went Wrong</div>
		}
		return this.props.children;
	}
}

export interface IErrorBoundaryProps {
	children: JSX.Element;
}

export interface IErrorBoundaryState {
	hasError: boolean;
}
