import { useIsAuthenticated } from '@azure/msal-react';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { isolateApiConfiguration } from '~/selectors/api';
import { ApiEnvironment } from '~/@types';
import { setEnvironment } from '~/actions/api';
import { enumToArray, randomCharacters } from '~/utilities';
import InputButton from '~/components/input-button';
import { RootState } from '~/stores'
import './index.scss';
import { isolateOperationModes, isolateDeviceTypes, isolateLayouts as isolateLayouts, isolateManufacturers as isolateManufacturers, isolateModels as isolateModels, isolateTemplates as isolateTemplates, isolateWidgetCategories as isolateWidgetCategories, isolateWidgets as isolateWidgets, isolateSites, isolateTags, isolateTokens } from '~/selectors/reference';
import { getReferenceData } from '~/actions/reference';
import { isolateUserAccounts } from '~/selectors/user';
import AzureStorageService from '~/services/azure-storage';
import { isolateAccessToken } from '~/selectors/auth';

interface IDebugIndexSection<T> {
	name: string;
	value: T;
	render?: (v: T) => React.ReactNode;
}

const renderValue = <T,>(v: T, index: number) => {
	return <span key={`p-${index}`}>{typeof v === 'string' ? v : JSON.stringify(v)}</span>
}

const renderListValue = <T,>(v: T, index: number, listName: string) => {
	return <li key={`list-value-${listName}-${index}`}>{typeof v === 'string' ? v : JSON.stringify(v)}</li>
}

const DebugIndex = () => {
	const isAuthenticated = useIsAuthenticated();
	const { permissions, user } = useSelector((s: RootState) => s.auth);
	const tags = useSelector(isolateTags);
	const accessToken = useSelector(isolateAccessToken);
	const theme = useTheme();
	const referenceState = useSelector((s: RootState) => s.reference.state);
	const sites = useSelector(isolateSites);
	const operationModes = useSelector(isolateOperationModes);
	const layouts = useSelector(isolateLayouts);
	const manufacturers = useSelector(isolateManufacturers);
	const models = useSelector(isolateModels);
	const templates = useSelector(isolateTemplates);
	const deviceTypes = useSelector(isolateDeviceTypes);
	const tokens = useSelector(isolateTokens);
	const widgetCategories = useSelector(isolateWidgetCategories);
	const widgets = useSelector(isolateWidgets);
	const apiConfig = useSelector(isolateApiConfiguration);
	const userAccounts = useSelector(isolateUserAccounts);
	const authState = useSelector((s: RootState) => s.auth.state);
	const dispatch = useDispatch();
	const apiEnvs = enumToArray<string>(ApiEnvironment);
	const sections: IDebugIndexSection<any>[] = [
		{ name: 'Auth State', value: authState },
		{ name: 'Is Authenticated', value: isAuthenticated },
		{ name: 'Access Token', value: accessToken },
		{ name: 'API', value: apiConfig },
		{
			name: 'API Switch', value: [...apiEnvs.map(v => v.value)],
			render: (v: ApiEnvironment[]) => {
				return v.map((iv, i) => <InputButton key={`api-switch-${i.toString()}`} onClick={() => pressEnvironment(iv)}>{iv}</InputButton>);
			}
		},
		{ name: 'Reference State', value: referenceState },
		{
			name: 'Reference', value: true,
			render: (v: boolean) => {
				return v && <InputButton key={'ib-get-ref'} onClick={() => dispatch(getReferenceData())}>Get Reference Data</InputButton>
			}
		},
		{ name: 'Reference Operation Modes', value: operationModes },
		{ name: 'Reference Sites', value: sites },
		{ name: 'Reference Layouts', value: layouts },
		{ name: 'Reference Manufacturers', value: manufacturers },
		{ name: 'Reference Models', value: models },
		{ name: 'Reference Templates', value: templates },
		{ name: 'Reference Widget Categories', value: widgetCategories },
		{ name: 'Reference Widgets', value: widgets },
		{ name: 'Reference Tags', value: tags },
		{ name: 'Reference Tokens', value: tokens },
		{ name: 'Reference Device Types', value: deviceTypes },
		{ name: 'Permissions', value: permissions },
		{ name: 'User', value: user },
		{ name: 'User Accounts', value: userAccounts },
		// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
		{ name: 'Upload Queue', value: (AzureStorageService as any).uploadQueue },
	].sort((a, b) => a.name.localeCompare(b.name));

	const pressEnvironment = (env: ApiEnvironment) => {
		dispatch(setEnvironment(env));
	}

	return <div className="debug-index" style={{ color: theme.palette.text.primary }}>
		{
			sections.sort((a, b) => a.name.localeCompare(b.name)).map((v, i) => {
				const { name, render, } = v;
				const isList = v.value instanceof Array;
				const title = <strong key={`title-${name}`}>{name}</strong>
				const listName: string = randomCharacters(6);
				if (render) return <div className="section" key={listName}>{render(v.value)}</div>;
				if (isList) {
					const arr = v.value as any[];
					return <div className="section" key={listName}>
						{title}
						<ul>
							{arr.length > 0 && arr.map((iv, ii) => renderListValue(iv, ii, listName))}
							{arr.length === 0 && <li className='no-data' key={`${listName}-no-data`}>No data</li>}
						</ul>
					</div>
				} else {
					return <div className="section" key={listName}>
						{title}
						{renderValue(v.value, i)}
					</div>
				}
			})
		}
	</div>
}

export default DebugIndex;