import React from 'react';
import './index.scss';
import { useSelector } from 'react-redux';
import { VENDOR_NAME } from '~/constants';
import { isolateVersion } from '~/selectors/ui';

const Copyright = () => {
	const v = useSelector(isolateVersion);
	const startYear = 2023;
	const showStartYear = new Date().getFullYear() > startYear;
	return <p className='copyright'>
		&copy;{showStartYear ? `${startYear} - ${new Date().getFullYear()}` : startYear } {VENDOR_NAME}. <br />
		<span>v{v}</span>
	</p>;
}

export default Copyright;