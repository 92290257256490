import React, { useEffect } from 'react';
import LoaderIcon from '~/components/loader-icon';
import authService from '~/services/auth-service';

const LogoutScreen = () => {
	useEffect(() => {
		void authService.logout();
	}, [])
	return <LoaderIcon />
}

export default LogoutScreen;