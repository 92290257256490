import axiosClient from './client';

const API_ROOT = 'reference';

class ReferenceApiBase {
	public async getReferenceData(): Promise<IGetReferenceDataModel> {
		const data: IGetReferenceDataModel = await axiosClient.get(`${API_ROOT}`);
		const mappedData = this.mapAllReferenceData(data);
		return mappedData;
	}

	private mapAllReferenceData(data: IGetReferenceDataModel) {
		const { widgets, ...rest } = data;
		return { ...rest, widgets: this.mapWidgetData(widgets) };
	}

	private mapWidgetData(data: IWidgetModel[]) {
		return data.map(widget => {
			const { widgetSchema, ...irest } = widget;
			const widgetSchemaMapped = JSON.parse(widgetSchema.toString());
			
			return { ...irest, widgetSchema: widgetSchemaMapped }
		});
	}
}

export interface IGetReferenceDataModel {
	tags: ITagModel[];
	deviceTypes: IDeviceTypeModel[];
	templates: ITemplateModel[];
	layouts: ILayoutModel[];
	widgets: IWidgetModel[];
	widgetCategories: IWidgetCategoryModel[];
	manufacturers: IManufacturerModel[];
	models: IModelModel[];
	sites: ISiteModel[];
	operatingModes: IOperatingModeModel[];
}

export interface IDeviceTypeModel {
	deviceTypeId: number;
	deviceTypeName: string;
}

export interface ISiteModel {
	siteId: number;
	siteName: string;
	latitude?: number;
	longitude?: number;
	configData: ISiteConfigDataModel;
}

export interface ISiteConfigDataModel {
	[x: string]: any;
}

export interface ITagModel {
	isSystem: boolean;
	name: string;
	section: 'screens';
}

export interface ITokenModel {
	container: string;
	token: string;
}

export interface ITemplateModel {
	templateId: number;
	templateName: string;
	regions: IScreenTemplateRegionModel[];
	pixelWidth: number;
	pixelHeight: number;
}

export interface ILayoutModel {
	layoutId: number;
	layoutName: string;
	templateId: number;
	regions: IScreenLayoutRegionModel[]
}

export interface IScreenLayoutRegionModel {
	regionId: number,
	widgetId: number,
	widgetSchema?: IScreenWidgetSchemaDataModel;
}

export interface IWidgetModel {
	widgetId: number;
	widgetName: string;
	widgetCategoryId: number;
	widgetSchema: IScreenWidgetSchemaDataModel;
}

export interface IScreenWidgetSchemaDataModel {
	[x: string]: any;
}

export interface IWidgetCategoryModel {
	widgetCategoryId: number;
	widgetCategoryName: string;
}

export interface IOperatingModeModel {
	operatingModeId: number;
	operatingModeName: string;
}

export interface IManufacturerModel {
	manufacturerId: number;
	manufacturerName: string;
}

export interface IModelModel {
	modelId: number;
	manufacturerId: number;
	modelName: string;
	deviceTypeId: number;
	modelTags: string[];
}

export interface IScreenTemplateRegionModel {
	regionId: number;
	regionName: string;
	pixelWidth: number;
	pixelHeight: number;
	pixelLeft: number;
	pixelTop: number;
}

const ReferenceApi = new ReferenceApiBase();
export default ReferenceApi;