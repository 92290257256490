import { Box, Grid } from '@mui/material';
import React from 'react';
import './screenshots.scss';
import { useOutletContext } from 'react-router-dom';
import { IDisplayDetailsOutletContext } from '../../details';
import { IDisplayScreenScreenshotModel } from '~/api/displays';
import RelativeTimestamp from '~/components/relative-timestamp';

const DisplayScreenshotsScreen = () => {
	const {
		displayScreenshots
	} = useOutletContext<IDisplayDetailsOutletContext>();

	const renderScreenshot = (screenshot: IDisplayScreenScreenshotModel, index: number) => {
		const url = screenshot.storagePath
		return <li key={`screen-li-${index}`}><a href={url} target='_blank' rel='noreferrer'><img src={url} /></a><RelativeTimestamp placement='right' date={screenshot.timestamp} /></li>;
	}

	return <Box>
		<Grid className='screenshots' container spacing={2}>
			<Grid item xs={12}>
				{displayScreenshots.length === 0 ? <p>There are no recent screenshots for this display.</p> :

					<div className='wrapper'>
						<ul>
							{displayScreenshots.map((v, i) => renderScreenshot(v, i))}
						</ul>
					</div>
				}
			</Grid>
		</Grid>
	</Box>
}

export default DisplayScreenshotsScreen;