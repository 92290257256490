import React, { FormEvent } from 'react';
import { useSelector } from 'react-redux';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import { customizeValidator } from '@rjsf/validator-ajv8';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { FormControl, Grid, useTheme } from '@mui/material';
import merge from 'deepmerge';
import { Form as FormElement } from '@rjsf/mui';
import { useOutletContext } from 'react-router-dom';
import { IChangeEvent } from '@rjsf/core';
import { IDisplayDetailsOutletContext } from '../../../details';
import { DATA_URL_VALIDATOR_FORMATS, getWidgetConfigurationSchema, getWidgetConfigurationUiSchema, widgetsConfigurationWidgets } from '../../../helpers';
import { isolateLayouts, isolateTemplates, isolateWidgetCategories, isolateWidgets, selectWidgetCategoryName } from '~/selectors/reference';
import Title from '~/components/title';
import './widget-configuration.scss';
import InputText from '~/components/input-text';
import { IDisplayDetailLayoutModel, IDisplayDetailRegionModel } from '~/api/displays';
import * as ReferenceTypes from '~/api/reference';

interface IWidgetConfigurationProps {
	widgetId?: number;
	layoutId?: number;
	regionId?: number;
	templateId?: number;
	siteId?: number;
	open: boolean;
	onClose?: () => void;
	onDisplayLayoutsChanged?: (v: IDisplayDetailLayoutModel[]) => void;
	editable?: boolean;
	screenRegion: IDisplayDetailRegionModel | undefined;
	displayLayouts: IDisplayDetailLayoutModel[];
}

const DisplayLayoutWidgetConfiguration = (props: IWidgetConfigurationProps) => {
	let { editable } = props;
	if (editable === undefined) editable = true;
	const theme = useTheme();
	const { layoutId, onClose, onDisplayLayoutsChanged, open, regionId, screenRegion, siteId, templateId, widgetId, } = props;
	const referenceWidgets = useSelector(isolateWidgets);
	const referenceLayouts = useSelector(isolateLayouts);
	const referenceTemplates = useSelector(isolateTemplates);
	const {
		displayId,
		displayLayouts, setDisplayLayouts,
		readLayout, writeLayout
	} = useOutletContext<IDisplayDetailsOutletContext>();

	const widgetCategories = useSelector(isolateWidgetCategories);
	const [rLayout, setRLayout] = React.useState<ReferenceTypes.ILayoutModel | undefined>();
	const [rTemplate, setRTemplate] = React.useState<ReferenceTypes.ITemplateModel | undefined>();
	const [rWidget, setRWidget] = React.useState<ReferenceTypes.IWidgetModel | undefined>();
	const [lRegion, setLRegion] = React.useState<ReferenceTypes.IScreenLayoutRegionModel | undefined>();
	const [tRegion, setTRegion] = React.useState<ReferenceTypes.IScreenTemplateRegionModel | undefined>();

	const [schema, setSchema] = React.useState<RJSFSchema | undefined>()
	const [uiSchema, setUiSchema] = React.useState<UiSchema | undefined>()
	const [widgetSchema, setWidgetSchema] = React.useState<ReferenceTypes.IScreenWidgetSchemaDataModel | undefined>()

	const formRef: React.Ref<any> = React.useRef();

	const widgetCategory = rWidget ? selectWidgetCategoryName(widgetCategories, rWidget?.widgetCategoryId) : undefined;

	const getWidgetSchema = () => {
		const a = referenceLayouts.find(v => v.layoutId === layoutId);
		const d = a?.regions.find(v => v.regionId === regionId);
		const y = merge(d?.widgetSchema || {}, screenRegion?.widgetData || {});
		console.log(`getWidgetSchema ${JSON.stringify(y)}`);
		return y;
	}

	React.useEffect(() => {
		const a = referenceLayouts.find(v => v.layoutId === layoutId);
		const b = referenceTemplates.find(v => v.templateId === rLayout?.templateId);
		const c = referenceWidgets.find(v => v.widgetId === widgetId);
		const d = a?.regions.find(v => v.regionId === regionId);
		const e = b?.regions.find(v => v.regionId === regionId);

		setRLayout(a);
		setRTemplate(b);
		setRWidget(c);
		setLRegion(d);
		setTRegion(e);

		const z = c ? getWidgetConfigurationSchema(c?.widgetSchema) : undefined;

		if (regionId === undefined || layoutId === undefined || widgetId === undefined) {
			return;
		}
		setSchema(z);

		setWidgetSchema(getWidgetSchema());
		validateForm();

	}, [layoutId, regionId, widgetId])

	React.useEffect(() => {
		const rWidget = referenceWidgets.find(v => v.widgetId === widgetId);
		setUiSchema(getWidgetConfigurationUiSchema({ layoutId, templateId, regionId, displayId, siteId }, rWidget?.widgetSchema, getWidgetSchema()));
		validateForm();
	}, [siteId, widgetId]);

	const pressClose = () => {
		if (onClose) onClose();
	}

	const formChanged = (data: IChangeEvent<any, RJSFSchema, any>, id?: string | undefined) => {
		const { formData, schema } = data;
		console.log(`qp01oie  formChanged ${screenRegion !== undefined} ${id} ${JSON.stringify(formData)}

		widgetSchema ${JSON.stringify(widgetSchema)}
		`);
		const rWidget = referenceWidgets.find(v => v.widgetId === widgetId);
		const lIndex = displayLayouts.findIndex(v => v.layoutId === layoutId);
		const dl = displayLayouts.slice();
		const dli = dl[lIndex];
		const rIndex = dli.regions.findIndex(v => v.regionId === regionId);
		const rin = dli.regions[rIndex];
		const newWidgetData = rin ? { ...rin.widgetData, ...formData } : { ...formData };

		validateForm();

		rin.widgetData = newWidgetData;
		const newUiSchema = getWidgetConfigurationUiSchema({ layoutId, templateId, regionId, displayId, siteId }, rWidget?.widgetSchema, widgetSchema);

		dl.splice(lIndex, 1, dli);
		setDisplayLayouts(dl);
		setWidgetSchema(newWidgetData as ReferenceTypes.IScreenWidgetSchemaDataModel);
		setUiSchema(newUiSchema);
		if (onDisplayLayoutsChanged) onDisplayLayoutsChanged(dl)

	}

	const validateForm = () => {
		try {
			const obj = formRef.current as { validateForm: () => boolean };
			if (!obj) return true;
			const formValid = obj.validateForm();

			// This is super hacky - but could not find another way to trigger validation but not submit the form. Calling submit on the form would submit the form even with validation issues. Attempts to renderErrors, and calls to validate also did not result in the desired functionality.
			const els = document.getElementsByClassName('btn-submit');
			const btn = els.length > 0 ? els[0] : undefined;
			if (btn) {
				const x = () => btn.dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: false, clientX: btn.clientLeft, clientY: btn.clientTop }));
				setTimeout(x, 0);
			}
			return formValid;
		} catch (ex) {
			console.error(ex);
		}
	}

	const formSubmit = (data: IChangeEvent<any, RJSFSchema, any>, event: FormEvent<any>) => {
		return false;
	}

	const { customFormats } = DATA_URL_VALIDATOR_FORMATS;
	const validator = customizeValidator({ customFormats })

	return open ? <div className="widget-configuration" style={{ color: theme.palette.text.primary }}>
		<div>
			<Title>{rWidget?.widgetName}</Title>
			<IconButton onClick={pressClose}><CloseIcon /></IconButton>
		</div>
		{
			schema && JSON.stringify(schema.properties) !== JSON.stringify({}) && <FormElement schema={schema} disabled={!editable} ref={formRef} onSubmit={formSubmit} onChange={formChanged} validator={validator} uiSchema={uiSchema} widgets={widgetsConfigurationWidgets} formData={widgetSchema} noHtml5Validate={true}>
			</FormElement>
		}
		<form className="rjsf">
			<Grid container spacing={2}>
				{tRegion && <Grid item xs={6}>
					<FormControl>
						<InputText disabled={true} label='Region Dimensions' value={`${tRegion?.pixelWidth} x ${tRegion?.pixelHeight}`}></InputText>
					</FormControl>

				</Grid>}
				{rTemplate && <Grid item xs={6}>
					<FormControl>
						<InputText disabled={true} label='Template Dimensions' value={`${rTemplate?.pixelWidth} x ${rTemplate?.pixelHeight}`}></InputText>
					</FormControl>
				</Grid>}
				{widgetCategory && <Grid item xs={6}>
					<FormControl>
						<InputText disabled={true} label='Widget Category' value={widgetCategory}></InputText>
					</FormControl>
				</Grid>}
			</Grid>
		</form>
		{/* {JSON.stringify(schema)} */}
		{/* {JSON.stringify(widgetSchema)} */}
	</div >
		: <></>;
}

export default DisplayLayoutWidgetConfiguration;