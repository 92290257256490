import { PayloadAction } from '@reduxjs/toolkit';
import * as actions from '~/actions';


const INITIAL_STATE: IReferenceDataState = {
	deviceTypes: false,
	isPopulated: false,
	isRefreshing: false,
	layouts: false,
	manufacturers: false,
	models: false,
	operationModes: false,
	sites: false,
	tags: false,
	templates: false,
	widgetCategories: false,
	widgets: false,
};

const stateReducer = (state: IReferenceDataState = INITIAL_STATE, action: PayloadAction<IReferenceDataState>): IReferenceDataState => {
	switch (action.type) {
		case actions.AUTH_SUCCESS:
		// fall through
		case actions.REFERENCE_DATA_GET_REQUESTED: {
			const retVal = { ...state, isRefreshing: true };
			console.log(`${actions.REFERENCE_DATA_GET_REQUESTED} referenceState ${JSON.stringify(retVal)}`);
			return retVal;
		}
		case actions.REFERENCE_DATA_GET_SUCCESS: {
			const newState = populateStateForAll(state);
			const retVal = { ...newState, isPopulated: isPopulated(newState, true), isRefreshing: false };
			console.log(`${actions.REFERENCE_DATA_GET_SUCCESS} referenceState ${JSON.stringify(retVal)}`);

			return retVal;
		}
		case actions.APP_START:
			return { ...state, isRefreshing: false };
		case actions.REFERENCE_DATA_GET_FAILURE:
		case actions.AUTH_FAILURE:
		case actions.AUTH_REFRESH_FAILURE:
		// fall through
		case actions.AUTH_LOGOUT_REQUESTED: {
			console.log(`${actions.REFERENCE_DATA_GET_FAILURE} referenceState ${JSON.stringify(INITIAL_STATE)}`);
			return INITIAL_STATE;
		}
		default: return state;
	}
}

const isPopulated = (state: IReferenceDataState, all: boolean = false) => {
	let s = { ...state };
	if (all) {
		s = populateStateForAll(s);
	}
	const allRetVal = s.deviceTypes &&
		s.layouts &&
		s.manufacturers &&
		s.models &&
		s.operationModes &&
		s.sites &&
		s.tags &&
		s.templates &&
		s.widgetCategories &&
		s.widgets;
	const retVal = allRetVal;

	return retVal;
}

const populateStateForAll = (a: IReferenceDataState) => {
	const s = { ...a };
	s.deviceTypes =
		s.layouts =
		s.manufacturers =
		s.models =
		s.operationModes =
		s.sites =
		s.tags =
		s.templates =
		s.widgetCategories =
		s.widgets = true;
	return s;
}

export default stateReducer;

export interface IReferenceDataState {
	deviceTypes: boolean;
	isPopulated: boolean;
	isRefreshing: boolean;
	layouts: boolean;
	manufacturers: boolean;
	models: boolean;
	operationModes: boolean;
	sites: boolean;
	tags: boolean;
	templates: boolean;
	widgetCategories: boolean;
	widgets: boolean;
}