import * as AzureStorageTypes from '@azure/storage-blob';
import mime from 'mime';
import LibraryApi, { IRequestLibraryUploadModel, IRequestLibraryUploadResponseModel } from '~/api/library';
import { isNullOrEmpty } from '~/utilities';

interface IQueuedUpload {
	path: string;
	content: AzureStorageTypes.HttpRequestBody;
	uploadModel: IRequestLibraryUploadModel;
	id: number;
	context: IQueuedUploadContextModel;
}

export interface IQueuedUploadContextModel {
	model: IQueuedUploadContextModelModel;
	matcher: (a: any, b: any) => boolean;
}

interface IQueuedUploadContextModelModel {
	[x: string]: any;
}

interface IDequeueUploadModel {
	ids?: number[];
	context?: IQueuedUploadContextModel;
}

export interface IQueuedUploadResponse {
	model: IRequestLibraryUploadResponseModel;
	uploadComplete: boolean;
	path: string;
	context: IQueuedUploadContextModel;
	id: number;
}

export class AzureStorageServiceBase {
	private debug = true;
	private uploadQueue: IQueuedUpload[] = [];

	constructor() {
		this.initialize();
	}

	public dequeueUpload(props: IDequeueUploadModel) {
		const { ids, context } = props;
		const act = (id: number) => {
			this.debugLog(`attempt dequeing item with id ${id}`)
			const ind = this.uploadQueue.findIndex(v => v.id === id);
			if (ind !== -1) {
				this.debugLog(`dequeing item at index ${ind} with id ${id}`)
				this.uploadQueue.splice(ind, 1);
			}
		}
		if (!isNullOrEmpty(ids)) {
			this.debugLog(`dequeue by ids ${ids?.join(', ')}`)
			ids?.forEach(id => {
				act(id);
			});
		} else if (context !== undefined) {
			this.debugLog(`dequeue by context ${JSON.stringify(context)}`)
			const { model, matcher } = context;

			this.uploadQueue.filter(v => matcher(v.context.model, model)).forEach(v => act(v.id));
		}
	}

	public queueUpload(path: string, content: AzureStorageTypes.HttpRequestBody, uploadModel: IRequestLibraryUploadModel, context: IQueuedUploadContextModel) {
		const id = this.getNewQueueId();
		const queue = { path, content, id, uploadModel, context }
		if (this.uploadQueue.findIndex(v => v.id === id) === -1) {
			this.debugLog(`queueing ${path} | returning ${id}`)
			this.uploadQueue.push(queue);
		}
		return id;
	}

	// public async processAllQueuedUploads() {
	// 	const ids = this.uploadQueue.map(v => v.id);
	// 	return await this.processQueuedUploads(ids);
	// }

	public async processQueuedUploads(ids: number[], clearAll: boolean = false) {
		const promises: Promise<any>[] = [];
		const responses: IQueuedUploadResponse[] = [];
		this.debugLog(`processing ${ids.length} uploads [${ids.join(', ')}]`)
		try {
			ids.forEach(v => {
				const queueItem = this.uploadQueue.find(iv => iv.id === v);
				if (queueItem) {
					const { path, content, context, uploadModel } = queueItem;
					const requestModel = LibraryApi.getUploadRequestModelForDisplay(uploadModel);
					promises.push(requestModel.then(async (m) => {
						let uploadComplete = false;
						const { storagePath, libraryPath } = m;
						this.uploadQueue.splice(this.uploadQueue.findIndex(iv => iv.id === v), 1);
						// uploadComplete = true;
						// await sleep(100);
						// responses.push({ id: v, model: m, path, uploadComplete });

						await this.upload(content, m.sharedAccessSignature, storagePath, libraryPath).then(worked => {
							if (worked) {
								this.uploadQueue.splice(this.uploadQueue.findIndex(iv => iv.id === v), 1);
								uploadComplete = true;
							}
						}).catch(() => {
							responses.push({ id: v, context, model: m, path, uploadComplete: false });
						}).finally(() => {
							responses.push({ id: v, context, model: m, path, uploadComplete });
						});
					}));
				}
			});


			await Promise.all(promises);

			if (clearAll) {
				this.uploadQueue.splice(0, this.uploadQueue.length);
			}

			return responses;
		} catch (ex) {
			return responses;
		}
	}

	public async upload(content: AzureStorageTypes.HttpRequestBody, sharedAccessSignature: string, storagePath: string, libraryPath: string) {
		const onUploadComplete = (worked: boolean) => {
			if (worked) {
				void LibraryApi.getUploadConfirmationForPath(libraryPath);
			}
		};

		try {
			let length = 0;
			if ((content as ArrayBuffer)?.byteLength !== undefined) length = (content as ArrayBuffer).byteLength;
			if ((content as Blob)?.size !== undefined) length = (content as Blob).size;
			if ((content as string)?.length !== undefined) length = (content as string).length;

			this.debugLog(`upload ${storagePath} start
		content ${JSON.stringify((content as ArrayBuffer).byteLength)}
		
		sharedAccessSignature ${sharedAccessSignature}
		storagePath ${storagePath}
		libraryPath ${libraryPath}
		length ${length}
		`);

			const fClient = this.getContainerClientForContainerName(sharedAccessSignature);

			for await (const blob of fClient.listBlobsFlat()) {
				this.debugLog(`- ${blob.name}`);
			}
			// return false;
			const blobName = storagePath.startsWith('/') ? storagePath.slice(1) : storagePath;
			const blockClient = fClient?.getBlockBlobClient(blobName);
			const spBlobName = blobName.split('.');
			const fileExt = spBlobName[spBlobName.length - 1];
			const mimeType = mime.getType(fileExt);
			const uploadOptions = mimeType ? { blobHTTPHeaders: { 'blobContentType': mimeType } } : undefined;
			const response = await blockClient?.upload(content, length, uploadOptions);

			this.debugLog(`upload ${storagePath}
		fClient available ${fClient !== undefined}
		blockClient available ${blockClient !== undefined}
		blobName ${blobName}
		mimeType ${mimeType}
		content length ${length}
		response ${JSON.stringify(response)}`);

			if (response) {
				const { errorCode } = response
				const retVal = !errorCode;
				this.debugLog(`upload returning ${retVal} for storagePath ${storagePath} errorCode ${errorCode}`);

				onUploadComplete(retVal);
				return retVal;
			} else {
				this.debugLog(`upload returning false for storagePath ${storagePath}`);
				onUploadComplete(false);
				return false;
			}
		} catch (ex) {
			alert(ex)
			onUploadComplete(false);
			return false;
		}
	}

	private getNewQueueId() {
		
		let newId = new Date().getTime();
		while (this.uploadQueue.findIndex(q => q.id === newId) !== -1)
			newId += new Date().getTime();
		return newId;
	}

	private initialize() {
		this.debugLog('initialize');
	}

	private debugLog(s: string) {
		if (!this.debug) return;
		console.debug(`[${this.constructor.name}] | ${s}`);
	}

	private getContainerClientForContainerName(sharedAccessSignature: string) {
		const client = new AzureStorageTypes.ContainerClient(sharedAccessSignature, new AzureStorageTypes.AnonymousCredential());
		return client;
	}
}

const AzureStorageService = new AzureStorageServiceBase();
export default AzureStorageService;