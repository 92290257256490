import * as React from 'react';
import Typography from '@mui/material/Typography';
import './index.scss';

interface TitleProps {
	className?:string;
	children?: React.ReactNode;
}

const Title = (props: TitleProps) => {
	const { children, className } = props;
	return (
		<Typography className={className} component="h2" variant="h6" color="primary" gutterBottom>
			{children}
		</Typography>
	);
}

export default Title;