import axiosClient from './client';

const API_ROOT = 'hive/bus'
const DEFAULT_DISTANCE = 250;

class BusApiBase {
	public async getBusStopsBySite(model: IGetBusStopsBySiteModel): Promise<IBusDataModel[]> {
		const { distance, includeStops, siteId } = model;
		const finDist = distance || DEFAULT_DISTANCE;
		const strIncludeStops = getIncludeStopsString(includeStops);
		return await axiosClient.get(`${API_ROOT}/site/${siteId}?distance=${finDist}${strIncludeStops}`);
	}

	public async getBusStopsByGeo(model: IGetBusStopsByGeoModel): Promise<IBusDataModel[]> {
		const { distance, includeStops, latitude, longitude } = model;
		const finDist = distance || DEFAULT_DISTANCE;
		const strIncludeStops = getIncludeStopsString(includeStops);
		return await axiosClient.get(`${API_ROOT}/geocode?distance=${finDist}&latitude=${latitude}&longitude=${longitude}${strIncludeStops}`);
	}
}

const getIncludeStopsString = (stops?: string[]) => {
	return stops ? stops.map(v => `&includeStops=${v}`).join('') : '';
}

export interface IGetBusStopsModel {
	distance?: number;
	includeStops?: string[];
}

export interface IGetBusStopsBySiteModel extends IGetBusStopsModel {
	siteId: number;
}

export interface IGetBusStopsByGeoModel extends IGetBusStopsModel {
	latitude: number;
	longitude: number;
}


export interface IBusDataModel {
	atco: string;
	naptan: string;
	name: string;
	indicator: string;
	bearing: string;
	stopCode: string;
	distance: number;
	latitude?: number;
	longitude?: number;
}

const BusApi = new BusApiBase();
export default BusApi;