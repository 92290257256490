import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import * as actions from '~/actions';
import ReferenceApi from '~/api/reference';

function* getReferenceData(): Generator {
	try {
		console.log('try get reference data');
		const payload = yield call<typeof ReferenceApi.getReferenceData>(() => ReferenceApi.getReferenceData());

		yield put({ type: actions.REFERENCE_DATA_GET_SUCCESS, payload });


	} catch (e) {
		console.log('try catch get reference data');
		console.error(e);
		yield put({ type: actions.REFERENCE_DATA_GET_FAILURE });
	}
}

function* watchRequest(): Generator {
	yield takeLatest([actions.AUTH_SUCCESS, actions.REFERENCE_DATA_GET_REQUESTED], getReferenceData);
}

function* referenceSaga(): Generator {
	yield all([
		fork(watchRequest),
	])
}

export default referenceSaga;