import { Container, Grid, Paper } from '@mui/material';
import React from 'react';
import Title from '~/components/title';
import './index.scss';
import Copyright from '~/components/copyright';

const UnauthenticatedLayout = (props: IUnauthenticatedLayoutProps) => {
	return (<div className="unauthenticated-layout">
		<Container maxWidth='xl'>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
						<div className="unauthenticated-layout-child-wrapper">
							<Title>{props.title}</Title>
							{props.children}
						</div>
					</Paper>
					<Copyright />
				</Grid>
			</Grid>
		</Container>
	</div>)
}

interface IUnauthenticatedLayoutProps {
	children: JSX.Element;
	title: string;
}

export default UnauthenticatedLayout;