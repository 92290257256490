import { Router } from '@remix-run/router';
import { all, call, fork, getContext, put, select, takeLatest } from 'redux-saga/effects'
import * as actions from '~/actions';
import { isolateAccessToken, isolateAuthExpiry, isolateAuthIsExpiringSoon, isolateIsRefreshing } from '~/selectors/auth';
import { AuthService } from '~/services/auth-service';
import { RootState } from '~/stores';


function* logoutRequested(): Generator {
	try {
		const router = yield getContext('router');
		void (router as Router).navigate('/logout');
	} catch (e) {
		yield put({ type: actions.AUTH_LOGOUT_FAILURE });
	}
}

function* refreshRequested(): Generator {
	try {
		const state = yield select();
		const authService = yield getContext('authService');
		const accessToken = isolateAccessToken(state as RootState);
		if (accessToken) {
			yield call(() => (authService as AuthService).refresh());

			const newAccessToken = isolateAccessToken(state as RootState);
			if (newAccessToken === accessToken) yield put({ type: actions.AUTH_LOGOUT_REQUESTED });
			else yield put({ type: actions.AUTH_REFRESH_SUCCESS });
		} else {
			yield put({ type: actions.AUTH_LOGOUT_REQUESTED });
		}
	} catch (e) {
		yield put({ type: actions.AUTH_REFRESH_FAILURE });
		console.error(e);
	}
}

function* refreshAuthIfExpired(): Generator {
	try {
		const state = yield select();
		const isRefreshing = isolateIsRefreshing(state as RootState);

		if (isRefreshing) return;

		const authExpiry = isolateAuthExpiry(state as RootState);
		const authExpiringSoon = isolateAuthIsExpiringSoon(state as RootState);
		const accessToken = isolateAccessToken(state as RootState);

		const debug = false;
		const log = (v: string) => debug && console.log(v);

		log(`auth will expire at ${authExpiry?.toLocaleString()} now ${new Date().toLocaleString()} soon? ${authExpiringSoon}
		 access Token is ${accessToken}`);



		if (authExpiry !== undefined) {
			if (!accessToken) {
				yield put({ type: actions.AUTH_LOGOUT_REQUESTED });
				return;
			}

			// Refresh if expiring within the next 5 minutes
			if (authExpiringSoon) {
				console.log(`dispatch refresh because auth will expire within 5 minutes at ${authExpiry?.toLocaleString()} - now ${new Date()?.toLocaleString()}`);
				yield put({ type: actions.AUTH_REFRESH_REQUESTED });
			}
		}

	} catch (e) {
		console.error(e);
	}
}

function* watchLogoutRequested(): Generator {
	yield takeLatest([actions.AUTH_LOGOUT_REQUESTED], logoutRequested);
}

function* watchRenewRequested(): Generator {
	yield takeLatest([actions.USER_ACCOUNT_SET_SUCCESS, actions.AUTH_REFRESH_REQUESTED], refreshRequested);
}

function* watchRefreshIfExpired(): Generator {
	yield takeLatest([actions.APP_ACTIVE], refreshAuthIfExpired);
}


function* authSaga(): Generator {
	yield all([
		fork(watchLogoutRequested),
		fork(watchRenewRequested),
		fork(watchRefreshIfExpired),
	])
}

export default authSaga;