import { PayloadAction } from '@reduxjs/toolkit';
import * as actions from '~/actions';
import * as ReferenceTypes from '~/api/reference';


const INITIAL_STATE: ReferenceTypes.IModelModel[] = [];

const screenModelsReducer = (state: ReferenceTypes.IModelModel[] = INITIAL_STATE, action: PayloadAction<ReferenceTypes.IGetReferenceDataModel>): ReferenceTypes.IModelModel[] => {
	switch (action.type) {
		case actions.REFERENCE_DATA_GET_SUCCESS: {
			const { models: screenModels } = action.payload;
			return screenModels;
		}
		case actions.AUTH_FAILURE:
		case actions.AUTH_REFRESH_FAILURE:
			// fall through
		case actions.AUTH_LOGOUT_REQUESTED: {
			return INITIAL_STATE;
		}
		default: return state;
	}
}

export default screenModelsReducer;
