import { AnyAction, } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
import { BBXAuthenticationResult } from '~/@types';
import * as actions from '~/actions';
import PermissionsService from '~/permissions';

const INITIAL_STATE: string[] = [];

const permissionsReducer = (state: string[] = INITIAL_STATE, action: AnyAction | PayloadAction<string[] | BBXAuthenticationResult>): string[] => {

	switch (action.type) {
		case actions.AUTH_SUCCESS: {
			// Get role name from credentials
			const typedAction = action as PayloadAction<BBXAuthenticationResult>;
			const roleName = typedAction?.payload?.idTokenClaims?.role || '';
			return PermissionsService.getModelForRole(roleName);
		}
		case actions.AUTH_REFRESH_FAILURE:
		case actions.AUTH_FAILURE:
		// fall through
		case actions.AUTH_LOGOUT_SUCCESS:
			return INITIAL_STATE;
		default:
			return state;
	}
}

export default permissionsReducer;
