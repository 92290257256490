import React, { useEffect, useState } from 'react';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import { useSelector } from 'react-redux';
import { useTheme } from '@mui/material';
import { getWidgetIcon } from '../../../helpers';
import * as ReferenceTypes from '~/api/reference';
import './display-layout.scss';
import { isolateWidgets } from '~/selectors/reference';
import { IDisplayDetailLayoutModel } from '~/api/displays';
import InputButton from '~/components/input-button';
import { isolateUserAccountCode } from '~/selectors/auth';
import LoaderIcon from '~/components/loader-icon';
import { isolateApiConfiguration } from '~/selectors/api';
import { ApiEnvironment } from '~/@types';

interface IScreenLayoutProps {
	buttons?: ScreenLayoutButtons[];
	activeRegionId: number | undefined;
	height: number;
	regions: ReferenceTypes.IScreenTemplateRegionModel[];
	layoutRegions: ReferenceTypes.IScreenLayoutRegionModel[];
	width: number;
	onActiveRegionChanged?: (n: string | undefined) => void;
	onAnchorElChanged?: (el: HTMLElement) => void;
	onWidgetsChanged?: (w: ReferenceTypes.IWidgetModel[]) => void;
	onPressWidgetAdd?: (region: ReferenceTypes.IScreenTemplateRegionModel) => void;
	onPressWidgetSettings?: (region: ReferenceTypes.IScreenTemplateRegionModel, widget: ReferenceTypes.IWidgetModel) => void;
	debug?: boolean;
	displayId: number;
	layoutId: number | undefined;
	displayLayouts: IDisplayDetailLayoutModel[];
}

export enum ScreenLayoutButtons {
	Add,
	Settings,
	Remove,
}


const DisplayLayout = (props: IScreenLayoutProps) => {
	let { buttons } = props;
	const { activeRegionId, debug, displayId, displayLayouts, height, layoutId, layoutRegions, onActiveRegionChanged, onAnchorElChanged, onPressWidgetAdd, onPressWidgetSettings, onWidgetsChanged, regions, width } = props;
	const theme = useTheme();
	const { palette } = theme;
	const [anchorEl, setAnchorEl] = useState<HTMLElement>();
	const [iframeShowingPreview, setIframeShowingPreview] = useState<boolean>(false);
	const [iframeRef, setIframeRef] = useState<HTMLIFrameElement>();
	const [respondIframeLoaded, setRespondIframeLoaded] = useState<boolean>(false);
	const [lastIframePayload, setLastIframePayload] = useState<string | undefined>();
	const [iframeLoading, setIframeLoading] = useState<boolean>(true);
	const [editorVisible, setEditorVisible] = useState<boolean>(true);
	const api = useSelector(isolateApiConfiguration);
	const widgets = useSelector(isolateWidgets);
	const accountCode = useSelector(isolateUserAccountCode);
	if (!buttons) buttons = [ScreenLayoutButtons.Settings, ScreenLayoutButtons.Add, ScreenLayoutButtons.Remove];

	const narrowThreshold = 144; // Will be multiplied by scale factor, this is just the max-width of the buttons container
	const maxTemplateDimension = window.innerHeight / 2;
	const templateWidth = width || 0;
	const templateHeight = height || 0;
	const scaleFactor = Math.max(templateWidth, templateHeight) / maxTemplateDimension;

	const borderColor = palette.divider;
	const templateBackgroundColor = palette.mode === 'dark' ? 'rgba(0,0,0,0.75)' : 'transparent';
	const regionBackgroundColor = palette.divider;
	const regionActiveBackgroundColor = (palette.background as { paper: string }).paper;
	const activeBorderColor = (palette.primary as { main: string }).main;

	useEffect(() => {
		if (onActiveRegionChanged) onActiveRegionChanged(undefined);

	}, [])

	useEffect(() => {
		console.log(`displayLayouts change detected ${JSON.stringify(displayLayouts)}`);

		const timer = setTimeout(() => notifyIframeOfLayout(displayLayouts), 1000);
		return () => clearTimeout(timer);
	}, [displayLayouts])

	const pressWidgetAdd = (region: ReferenceTypes.IScreenTemplateRegionModel, event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		setAnchorEl(event.currentTarget);
		if (onAnchorElChanged) onAnchorElChanged(event.currentTarget);
		if (onActiveRegionChanged) onActiveRegionChanged(region.regionName);
		if (onPressWidgetAdd) onPressWidgetAdd(region);
		// changeAddWidgetOptions(screenTemplate, region.name);
		// setAddWidgetOpen(true);
	}

	const pressWidgetClear = (region: ReferenceTypes.IScreenTemplateRegionModel, widget: ReferenceTypes.IWidgetModel | undefined) => {
		if (confirm('Are you sure you want to clear this region?')) {

			// const layout = { ...screenLayout };
			// const ind = widgets.findIndex(v => v.regionName === region.regionName && widget?.name === v.name);
			// widgets.splice(ind, 1);
			// setScreenLayout(layout);
			// if (onWidgetsChanged) onWidgetsChanged(widgets);
		}
	}

	const pressWidgetSettings = (region: ReferenceTypes.IScreenTemplateRegionModel, widget: ReferenceTypes.IWidgetModel | undefined) => {
		if (onActiveRegionChanged) onActiveRegionChanged(region.regionName);
		if (onPressWidgetSettings && widget) onPressWidgetSettings(region, widget);
	}

	const renderWidget = (widget: ReferenceTypes.IWidgetModel | undefined) => {
		return <div className="widget">
			<span>{getWidgetIcon(widget?.widgetName)}{widget?.widgetName}</span>
		</div>
	}

	const renderButtons = (region: ReferenceTypes.IScreenTemplateRegionModel, widget: ReferenceTypes.IWidgetModel | undefined) => {
		const hasWidget = widget !== undefined;
		const gv = (v: ScreenLayoutButtons) => (buttons || []).indexOf(v) !== -1;
		if (hasWidget) {
			return <>
				{
					gv(ScreenLayoutButtons.Settings) && <Tooltip placement={'top'} arrow title="Widget Settings">
						<IconButton onClick={(e) => pressWidgetSettings(region, widget)}><SettingsIcon /></IconButton>
					</Tooltip>
				}
				{
					gv(ScreenLayoutButtons.Remove) && <Tooltip placement={'top'} arrow title="Remove Widget">
						<IconButton onClick={(e) => pressWidgetClear(region, widget)}><CloseIcon /></IconButton>
					</Tooltip>
				}
			</>
		} else {
			return <>
				{
					gv(ScreenLayoutButtons.Add) && <Tooltip placement={'top'} arrow title="Add Widget">
						<IconButton onClick={(e) => pressWidgetAdd(region, e)}><AddIcon /></IconButton>
					</Tooltip>
				}
			</>
		}
	}

	const renderRegion = (region: ReferenceTypes.IScreenTemplateRegionModel, index: number, col: ReferenceTypes.IScreenTemplateRegionModel[]) => {
		const layoutRegion = layoutRegions.find(v => v.regionId === region.regionId);
		const log = false;
		const relevant = col.slice(0, index);
		const { pixelTop: y, pixelLeft: x, pixelWidth: width, pixelHeight: height } = region;
		// const hasWidget = false; // !isNullOrEmpty(layoutRegion?.widgetId); // widgets.map(v => v.regionName).indexOf(region.regionName) !== -1;
		const widget = widgets.find(v => layoutRegion && v.widgetId === layoutRegion.widgetId); // hasWidget ? widgets.find(v => v.regionName === region.regionName) : undefined;

		let topOffset = 0;

		if (log) console.log(`Relevant to ${region.regionName} ${relevant.map(v => v.regionName).join(',')}`)

		for (const r of relevant) {

			if (log) console.group(r.regionName);

			topOffset += (r.pixelHeight);

			if (log) console.groupEnd();
		}


		const left = x;
		const top = y - topOffset;

		if (log) console.log(`
			region.name ${region.regionName}
			topOffset ${topOffset}
			
			region.x ${region.pixelLeft}
			region.y ${region.pixelTop}
			
			x ${x}
			y ${y}
			
			left ${left}
			top ${top}

			widget ${widget?.widgetName}
			widgets ${JSON.stringify(widgets.map(v => v.widgetName))}
			layoutRegion ${JSON.stringify(layoutRegion)}
			layoutRegions ${JSON.stringify(layoutRegions)}
			`);

		const active = region.regionId === activeRegionId;

		const style = {
			backgroundColor: active ? regionActiveBackgroundColor : regionBackgroundColor,
			borderColor: active ? activeBorderColor : borderColor,
			top: (top / scaleFactor),
			left: (left / scaleFactor),
			height: (height / scaleFactor),
			width: (width / scaleFactor),
			color: theme.palette.text.primary,
		}

		return <div data-region-name={region.regionName} className={`region ${width < (narrowThreshold * scaleFactor) ? 'narrow' : ''} ${widget ? 'has-widget' : ''} ${active ? 'active' : ''}`} key={`div-${region.regionName}`} style={style}>
			<span className='title'>{region.regionName}</span>
			<div className="widget">
				{renderWidget(widget)}
			</div>
			<div className="buttons">
				{renderButtons(region, widget)}
			</div>
		</div>

	}

	const loadIframe = () => {
		console.log(`${new Date().toLocaleString()} iframe loaded`);
		postLoadIframe();
	}

	const postLoadIframe = () => {
		const nv = !respondIframeLoaded;
		setRespondIframeLoaded(nv);
		if (nv) {
			notifyIframeOfLayout(displayLayouts);
		} else {
			setIframeShowingPreview(true);
			setIframeLoading(false);
		}
	}

	const notifyIframeOfLayout = (v: Partial<IDisplayDetailLayoutModel>[], force: boolean = false) => {
		const sanitized: Partial<IDisplayDetailLayoutModel> | undefined = (v || []).find(iv => iv.layoutId === layoutId);
		const sRegions = sanitized?.regions?.map(v => {
			const wdv = JSON.stringify(v.widgetData);
			const { regionId } = v;
			return { regionId, widgetData: wdv };
		});
		const { templateId } = sanitized ? sanitized : { templateId: -1 };
		const passed = { layoutId, templateId, regions: sRegions };
		const obj = passed;
		const isValid = obj.templateId !== -1 && (obj?.regions || []).length > 0;
		const payload = JSON.stringify({ message: obj });
		const different = payload !== lastIframePayload;
		if (isValid && (different || force)) {
			console.log(`notifyIframeOfLayout a1515w 
				force ${force}
				isValid ${isValid}
				different ${different}
				${JSON.stringify(obj)}`);
			setLastIframePayload(payload);
			iframeRef?.contentWindow?.postMessage(payload, '*');
			setIframeLoading(true);
		} else {
			console.warn(`notifyIframeOfLayout ${different ? 'not sending duplicate iframe data' : ''}${isValid ? 'not sending invalid iframe data' : ''}`)
			setIframeLoading(false);
		}
	}

	const pressFullScreenPreview = () => {
		notifyIframeOfLayout(displayLayouts);
		window.open(iframeSrc);
	}

	const useLocalIframe = window.location.host.indexOf('localhost') !== -1;
	const srcEnv = api.env === ApiEnvironment.prod ? '' : `.${api.env}`;
	const srcSuffix = `frontline?displayId=${displayId}&accountCode=${accountCode}`;
	const iframeSrc = useLocalIframe ? `https://localhost:5101/${srcSuffix}` : `https://boards${srcEnv}.dcis.io/${srcSuffix}`;

	return debug ? <div style={{ maxWidth: maxTemplateDimension, }}>
		<svg viewBox={`0 0 ${templateWidth} ${templateHeight}`} style={{ backgroundColor: 'red', }}>
			{regions.map((region) => {

				const { pixelTop: y, pixelLeft: x, pixelWidth: width, pixelHeight: height } = region;
				const left = x;
				const top = y;

				return <rect width={width} height={height} x={x} y={y} key={region.regionName} style={{ top, left, height, width, fill: 'rgba(0,0,0,0.25)', }}>

				</rect>
			})}
		</svg>
	</div> : <>
		<div className='preview' style={{ borderColor, width: (templateWidth && scaleFactor ? templateWidth / scaleFactor : 0), height: (templateHeight / scaleFactor), marginBottom: -1 * (templateHeight / scaleFactor) }}>
			{iframeLoading ? <div className='loading'><LoaderIcon /></div> : <></>}
			<iframe scrolling='no' onLoad={loadIframe} ref={v => setIframeRef(v || undefined)} style={{ transformOrigin: 'left top', height: templateHeight, width: templateWidth, transform: `scale(${1 / scaleFactor})`, backgroundColor: 'white' }} src={iframeSrc}></iframe>
		</div>
		<div className='template' style={{ visibility: editorVisible ? 'visible' : 'hidden', borderColor, backgroundColor: templateBackgroundColor, width: (templateWidth && scaleFactor ? templateWidth / scaleFactor : 0), height: (templateHeight / scaleFactor) }}>
			{regions.map(renderRegion)}
		</div>
		<ul className="action-links" style={{ width: (templateWidth && scaleFactor ? templateWidth / scaleFactor : 0) }}>
			<li>
				<InputButton onClick={() => setEditorVisible(!editorVisible)}>{editorVisible ? 'Hide' : 'Show'} Editor</InputButton>
			</li>
			<li>
				<InputButton onClick={pressFullScreenPreview}>Full-screen</InputButton>
			</li>
		</ul>
	</>
}

export default DisplayLayout;