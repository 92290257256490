import React, { useEffect } from 'react';
import '~/App.scss';
import { createTheme, CssBaseline, ThemeProvider, useMediaQuery } from '@mui/material';
import { PublicClientApplication } from '@azure/msal-browser';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { MsalProvider } from '@azure/msal-react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { RouterProvider } from 'react-router-dom';
import { datadogRum } from '@datadog/browser-rum';
import { appActive, appInactive, appStart } from './actions/ui';
import AzureStorageService from './services/azure-storage';
import { isolateApiConfiguration } from './selectors/api';
import { setDataDogUserProperties } from './utilities';
import { isolateUserAccountCode, isolateUserId, isolateUserRole } from './selectors/auth';
import store, { persistedStore, sagaMiddleware } from '~/stores';
import AppTheme from '~/theme';
import createdRouter from '~/routes';
import { ErrorBoundary } from '~/components/error-boundary';
import authService from '~/services/auth-service';

type AppProps = {
	pca: PublicClientApplication;
};

sagaMiddleware.setContext({ router: createdRouter, authService, azureService: AzureStorageService });

const App = ({ pca }: AppProps) => {
	const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
	const dispatch = store.dispatch;
	const state = store.getState();
	const { env } = isolateApiConfiguration(state);
	const userAccountCode = isolateUserAccountCode(state);
	const userRole = isolateUserRole(state);
	const userId = isolateUserId(state);

	useEffect(() => {
		const h1 = () => dispatch(appActive());
		const h2 = () => dispatch(appInactive());

		window.addEventListener('focus', h1);
		window.addEventListener('blur', h2);

		const initDataDog = () => {
			datadogRum.init({
				applicationId: '425d15b6-56c0-48cb-87f4-e9c3688bcbea',
				clientToken: 'pub57b3ef3af3eac875c30ab49b80f9c14f',
				site: 'datadoghq.com',
				service: 'e-ink--ui',
				env: env.toString(),
				sessionSampleRate: 100,
				sessionReplaySampleRate: 0,
				trackUserInteractions: true,
				trackResources: true,
				trackLongTasks: true,
				defaultPrivacyLevel: 'mask-user-input'
			});

			setDataDogUserProperties({ userId, role: userRole, accountCode: userAccountCode }, datadogRum);
		};

		initDataDog();

		dispatch(appStart())

		return () => {
			window.removeEventListener('focus', h1);
			window.removeEventListener('blur', h2);
		}
	}, []);

	const theme = React.useMemo(
		() =>
			createTheme({
				...AppTheme,
				palette: {
					mode: prefersDarkMode ? 'dark' : 'light',
				},
			}),
		[prefersDarkMode],
	);

	// return <div>app</div>

	return (
		<ErrorBoundary>
			<MsalProvider instance={pca}>
				<Provider store={store}>
					<PersistGate loading={undefined} persistor={persistedStore}>
						<ThemeProvider theme={theme}>
							<CssBaseline />
							<div className="app" style={{ color: theme.palette.text.primary}}>
								<RouterProvider router={createdRouter} />
							</div>
						</ThemeProvider>
					</PersistGate>
				</Provider>
			</MsalProvider >
		</ErrorBoundary>
	);
}

export default App;
