import React from 'react';
import { Fade, Popper as MuiPopper, PopperProps as MuiPopperProps } from '@mui/material';

interface IPopperProps extends Omit<MuiPopperProps, 'children' | 'open' | 'anchorEl'> {
	children: React.ReactNode;
	open: boolean;
	anchorEl: HTMLElement | undefined;
}

const Popper = (props: IPopperProps) => {
	const { children, open, anchorEl, ...rest } = props;

	return <MuiPopper open={open} anchorEl={anchorEl} transition {...rest}>
		{({ TransitionProps }) => (
			<Fade {...TransitionProps} timeout={350}>
				<div>
					{children}
				</div>
			</Fade>
		)}
	</MuiPopper>
}

export default Popper;