import { combineReducers } from 'redux';
import accessTokenReducer from './access-token';
import permissionsReducer from './permissions';
import userReducer from './user';
import stateReducer from './state';


const authReducer = combineReducers({
	accessToken: accessTokenReducer,
	permissions: permissionsReducer,
	state: stateReducer,
	user: userReducer,
});

export default authReducer;