import React from 'react';
import ReactDOM from 'react-dom/client';
import App from '~/App';
import '~/index.scss';
import reportWebVitals from '~/reportWebVitals';
import authService from '~/services/auth-service';

const root = ReactDOM.createRoot(
	document.getElementById('root') as HTMLElement
);

const strictMode = true;
root.render(
	strictMode ?
		<React.StrictMode>
			<App pca={authService.pca} />
		</React.StrictMode> : <App pca={authService.pca} />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
