import { combineReducers } from 'redux';
import sitesReducer from './sites';
import templatesReducer from './screen-templates';
import stateReducer from './state';
import tagsReducer from './tags';
import modelsReducer from './screen-models';
import manufacturersReducer from './screen-manufacturers';
import widgetsReducer from './screen-widgets';
import widgetCategoriesReducer from './screen-widget-categories';
import operatingModesReducer from './operating-modes';
import layoutsReducer from './screen-layouts';
import tokensReducer from './tokens';
import deviceTypesReducer from './device-types';

const referenceReducer = combineReducers({
	deviceTypes: deviceTypesReducer,
	layouts: layoutsReducer,
	manufacturers: manufacturersReducer,
	models: modelsReducer,
	operatingModes: operatingModesReducer,
	sites: sitesReducer,
	state: stateReducer,
	tags: tagsReducer,
	templates: templatesReducer,
	tokens: tokensReducer,
	widgetCategories: widgetCategoriesReducer,
	widgets: widgetsReducer,
});

export default referenceReducer;