import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects'
import { isolateApiConfiguration } from '~/selectors/api';
import * as actions from '~/actions';
import { ApiEnvironment } from '~/@types/index';
import { RootState } from '~/stores';
import { logout } from '~/actions/auth';
import { sleep } from '~/utilities';

function* updateApiEnvironment(action: PayloadAction<ApiEnvironment>): Generator {
	const state = yield select();
	try {
		const current = isolateApiConfiguration(state as RootState).env;
		let iter = 0;
		const max = 10;
		while (current !== action.payload) {
			yield call<typeof sleep>(sleep, 50);
			iter++;
			if (iter > max) {
				yield put({ type: actions.API_UPDATE_ENVIRONMENT_FAILURE });
				break;
			}
		}
		if (current === action.payload) {
			yield put({ type: actions.API_UPDATE_ENVIRONMENT_SUCCESS, payload: action.payload });
			yield put(logout());
		} else throw new Error('Did not see local environment change in time.')
	} catch (e) {
		yield put({ type: actions.API_UPDATE_ENVIRONMENT_FAILURE });
	}
}

function* watchRefresh(): Generator {
	yield takeLatest([actions.API_UPDATE_ENVIRONMENT_REQUESTED], updateApiEnvironment);
}

function* apiSaga(): Generator {
	yield all([
		fork(watchRefresh),
	])
}

export default apiSaga;