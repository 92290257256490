import React, { useRef } from 'react';
import { WidgetProps } from '@rjsf/utils';
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, Tooltip } from '@mui/material';
import './index.scss';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import BackupIcon from '@mui/icons-material/Backup';
import CloseIcon from '@mui/icons-material/Close';
import { MuiFileInput } from 'mui-file-input';
import { IDecodeWidgetFileUploadResponse, LIBRARY_PROTOCOL, decodeWidgetFileUploadValue, encodeWidgetFileUploadValue, isNullOrEmpty } from '~/utilities';
import AzureStorageService, { IQueuedUploadContextModel } from '~/services/azure-storage';
import { IRequestLibraryUploadModel } from '~/api/library';
import usePrevious from '~/hooks/use-previous';

enum FileComponentMode {
	RelativeFile, RemoteFile
}


const getError = (re: any[] | undefined) => (re && re?.length > 0) || false;

export interface IFileComponentOptionsModel {
	getUploadModel: (x: { [x: string]: any }) => IRequestLibraryUploadModel;
	getDataProps: () => { [x: string]: any };
	getQueueContextModel: (x: { [x: string]: any }) => IQueuedUploadContextModel;
	watch: number;
}

const getModeFromDecodedValue = (decoded: IDecodeWidgetFileUploadResponse | undefined) => (!isNullOrEmpty(decoded) && (!decoded?.libraryPath || decoded?.libraryPath?.indexOf(LIBRARY_PROTOCOL) === -1)) ? FileComponentMode.RelativeFile : FileComponentMode.RemoteFile;
const getModeTitle = (mode: FileComponentMode, title: string = '') => mode === FileComponentMode.RelativeFile ? `${title} (remote file)` : mode === FileComponentMode.RemoteFile ? `${title} (uploaded file)` : title;
const getRequiredTitle = (required: boolean = true, modeTitle: string = '') => required ? `${modeTitle} *` : modeTitle;

const FileComponent = (props: WidgetProps) => {
	const { id, onBlur, onChange, onFocus, options, rawErrors, required, schema, value } = props;
	const { title, watch } = schema;
	const { getDataProps, getQueueContextModel, getUploadModel } = options as unknown as IFileComponentOptionsModel;
	const [selectedValue, setSelectedValue] = React.useState<string | undefined>(value ? encodeWidgetFileUploadValue(value as string, undefined, undefined) : '');
	const decodedValue = decodeWidgetFileUploadValue(value as string);
	const [selectedVisibleValue, setSelectedVisibleValue] = React.useState<string | undefined>(value && decodedValue ? decodedValue?.name : '');
	const [mode, setMode] = React.useState<FileComponentMode>(getModeFromDecodedValue(decodedValue));
	const [fileUploadId, setFileUploadId] = React.useState<number>();
	// const [error, setError] = React.useState<boolean>(getError(rawErrors));
	const error = getError(rawErrors)
	const errorText = error ? rawErrors?.join(', ') : undefined;
	const key = `fc-${new Date().getTime()}-${id}`;
	const [modeTitle, setModeTitle] = React.useState<string>(getModeTitle(mode, title));
	const [requiredTitle, setRequiredTitle] = React.useState<string>(getRequiredTitle(required, modeTitle));
	const fileInputRef = useRef();
	const textInputRef = useRef();
	const previousValue = usePrevious<string | undefined>(value as string);


	// Fix this component not updating when switching widgets/regions
	React.useEffect(() => {
		let isEncoded = false;
		const sv = value as string;
		const decoded = decodeWidgetFileUploadValue(sv);
		if (decoded?.name !== value) {
			isEncoded = true;
		}
		console.log(`decoded ${JSON.stringify(decoded)}`)
		const selVal = isEncoded ? decoded?.name : sv;
		const selFid = decoded?.fileUploadId || undefined;
		updateSelectedValue(selVal, selFid);
		setFileUploadId(selFid);
		const newMode = getModeFromDecodedValue(decoded);
		updateMode(newMode);
	}, [watch]);

	React.useEffect(() => {
		if (previousValue?.indexOf(LIBRARY_PROTOCOL) === -1 && (value as string)?.indexOf(LIBRARY_PROTOCOL) !== -1) {
			try {
				console.log(`value changed from ${previousValue} to ${value as string}`)
				const decoded = decodeWidgetFileUploadValue(value as string);
				if (decoded) {
					const { name } = decoded;
					updateSelectedValue(name || undefined, undefined);
					// setRefreshWatch(new Date().getTime());
				}
			} catch (ex) {
				console.error(ex);
			}
		}
	}, [value]);

	const fireOnChange = (v: string | undefined) => {
		if (onChange) onChange(v);
	}

	const fileInputChange = (newFile: File | null) => {
		// setFile(newFile || undefined);
		const newValue = newFile?.name;
		if (newFile) {
			const x = async () => {
				if (fileUploadId) AzureStorageService.dequeueUpload({ ids: [fileUploadId] });
				const ab = await newFile.arrayBuffer();
				const uploadModel = getUploadModel({ ...getDataProps(), filename: newFile.name });
				console.log(`uploadModel ${JSON.stringify(uploadModel)}`)
				const context = getQueueContextModel(getDataProps());
				const id = AzureStorageService.queueUpload(newFile.name, ab, uploadModel, context);
				setFileUploadId(id);
				const finVal = updateSelectedValue(newValue, id);
				fireOnChange(finVal);
			}
			void x();
		} else {
			// console.log(`%cqp01oie fileInputChange newValue undefined? ${newValue === undefined} => "${newValue}" ${JSON.stringify(newFile)}`, 'color: deeppink');
			const finVal = updateSelectedValue(newValue, fileUploadId);
			fireOnChange(finVal);
		}
	}

	const updateSelectedValue = (newValue: string | undefined, newFileUploadId: number | undefined) => {
		if (mode === FileComponentMode.RemoteFile) {
			const finVal = encodeWidgetFileUploadValue(newValue, newFileUploadId, undefined);
			console.log(`update selected value ${newValue} => ${JSON.stringify(finVal)}`)
			setSelectedValue(finVal);
			setSelectedVisibleValue(newValue);
			return finVal;
		} else {
			setSelectedValue(newValue);
			setSelectedVisibleValue(newValue);
			return newValue;
		}
	}

	const updateMode = (newMode: FileComponentMode) => {
		setMode(newMode);
		const newModeTitle = getModeTitle(newMode, title);
		setModeTitle(newModeTitle);
		setRequiredTitle(getRequiredTitle(required, newModeTitle));
	}

	const changeMode = () => {
		const nextMode = mode === FileComponentMode.RelativeFile ? FileComponentMode.RemoteFile : FileComponentMode.RelativeFile;
		updateMode(nextMode);
		updateSelectedValue(undefined, fileUploadId);
		fireOnChange(undefined);
	}

	const clickTextInput = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
		const { nativeEvent } = e;
		const { target } = nativeEvent;

		const ignoreClick = target !== textInputRef.current;
		if (mode === FileComponentMode.RemoteFile && fileInputRef?.current && !ignoreClick) {
			const { current } = fileInputRef;
			const typed = current as HTMLInputElement;
			if (typed) {
				const x = () => typed.dispatchEvent(new MouseEvent('click', { bubbles: false, cancelable: false, clientX: typed.clientLeft, clientY: typed.clientTop }));
				setTimeout(x, 0);
			}
		}

	}

	const clearFile = () => {
		updateSelectedValue(undefined, fileUploadId);
		fireOnChange(undefined);
		if (fileUploadId) AzureStorageService.dequeueUpload({ ids: [fileUploadId] });
	}

	const changeTextInput = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { nativeEvent } = e;
		const { target } = nativeEvent;
		const { value } = target as HTMLInputElement;
		const retVal = updateSelectedValue(value, fileUploadId);
		fireOnChange(retVal);
	}

	const keyDownTextInput = (e: React.KeyboardEvent<HTMLInputElement>) => {
		if (mode === FileComponentMode.RemoteFile) {
			e.preventDefault();
			e.stopPropagation();
		}
	}

	const focus = (e: React.FocusEvent<HTMLElement>) => {
		if (onFocus) onFocus(e.target.id, selectedValue);
	}

	const blur = (e: React.FocusEvent<HTMLElement>) => {
		if (onBlur) onBlur(e.target.id, selectedValue);
	}

	const endAdornment = <InputAdornment position="end" >
		{selectedValue && <Tooltip placement={'top'} arrow title={'Clear'}>
			<IconButton
				onClick={clearFile}
				edge="end"
			>
				<CloseIcon />
			</IconButton>
		</Tooltip>
		}
		<Tooltip placement={'top'} arrow title={mode === FileComponentMode.RelativeFile ? 'Switch to file upload' : 'Switch to plain text'}>
			<IconButton
				onClick={changeMode}
				edge="end"
			>
				{mode === FileComponentMode.RelativeFile ? <BackupIcon /> : <DriveFileRenameOutlineIcon />}
			</IconButton>
		</Tooltip>
	</InputAdornment>;

	return <>
		{/* <div style={{ fontSize: '10px' }}>
			{value}<br />
			{selectedValue}<br />
			{selectedVisibleValue}<br />
			{JSON.stringify(decodedValue)}
		</div> */}


		<FormControl variant="outlined">
			{mode === FileComponentMode.RemoteFile && <MuiFileInput className='hidden' inputRef={fileInputRef} fullWidth onChange={fileInputChange} />}
			<InputLabel variant="outlined" error={error} htmlFor={key}>{requiredTitle}</InputLabel>

			<OutlinedInput
				onFocus={focus}
				onBlur={blur}
				error={error}
				value={selectedVisibleValue || ''}
				id={key}
				inputRef={textInputRef}
				onClick={clickTextInput}
				onKeyDown={keyDownTextInput}
				onChange={changeTextInput}
				endAdornment={endAdornment}
				label={requiredTitle}
			/>


		</FormControl>

	</>


}

export default FileComponent;
