import dayjs from 'dayjs';
import { createSelector } from 'reselect';
import { RootState } from '~/stores';

export const isolatePermissions: (s: RootState) => string[] = s => s.auth.permissions;
export const isolateAccessToken: (s: RootState) => string = s => s.auth.accessToken.code;
export const isolateUserAccountCode: (s: RootState) => string = s => s.auth.user?.currentAccountCode;
export const isolateUserId: (s: RootState) => number = s => s.auth.user?.userId;
export const isolateUserRole: (s: RootState) => string = s => s.auth.user?.role;
export const isolateIsRefreshing: (s: RootState) => boolean = s => s.auth.state.isRefreshing;
export const isolateAuthExpiry: (s: RootState) => Date | undefined = s => {
	const exp = s.auth.state?.expiry;
	return exp ? new Date(exp) : undefined;
}

export const isolateAuthIsExpired: (s: RootState) => boolean = s => {
	const exp = s.auth.state?.expiry;
	return exp ? dayjs(new Date(exp)).isBefore(new Date()) : true;
}

export const isolateAuthIsExpiringSoon: (s: RootState) => boolean = s => {
	const exp = s.auth.state?.expiry;
	return exp ? dayjs(new Date(exp)).subtract(5,'minutes').isBefore(new Date()) : true;
}

const selectPerms = (s: string[]) => s;
const selectKeys = (z: string[], s: string[]) => s;
const selectAllMatch = (x: string[], z: string[], s: boolean) => s;

export const selectHasPermissions: (state: string[], neededPerms: string[], all: boolean) => boolean = createSelector([selectPerms, selectKeys, selectAllMatch], (permissions: string[], keys: string[], all: boolean) => {
	const mapped = keys.filter(v => permissions.indexOf(v) !== -1);
	return all ? mapped.length === keys.length : mapped.length > 0;
});

export const selectHasPermissionsPartial: (state: string[], neededPerms: string[], all: boolean) => boolean = createSelector([selectPerms, selectKeys, selectAllMatch], (permissions: string[], keys: string[], all: boolean) => {
	const mapped = keys.filter(v => permissions.findIndex(iv => iv.indexOf(v) !== -1) !== -1);
	return all ? mapped.length === keys.length : mapped.length > 0;
});