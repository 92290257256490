import { useIsAuthenticated } from '@azure/msal-react';
import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { logout } from '~/actions/auth';
import InputButton from '~/components/input-button';
import './index.scss';

const ErrorIndex = (props?: IErrorProps) => {
	const location = useLocation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const isAuthenticated = useIsAuthenticated();
	const { message, status } = props ? props : { message: undefined, status: undefined };
	const statusMessage = status === 404 ? `${location.pathname} not found` : status === 403 ? 'You do not have permission to access that resource' : undefined;
	const finalMessage = message ? message : statusMessage ? statusMessage : 'An unexpected error has occurred.';
	return <div className='error-index'>
		{finalMessage}
		<div>
			{isAuthenticated && <InputButton onClick={() => dispatch(logout())}>Logout</InputButton>}
			{<InputButton onClick={() => navigate('/')}>Home</InputButton>}
		</div>
	</div>
}

interface IErrorProps {
	message?: string;
	status?: 404 | 403 | 401;
}

export default ErrorIndex;