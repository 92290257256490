import { Box, Grid, Palette, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import React from 'react';
import './status.scss';
import Tooltip from '@mui/material/Tooltip';
import { useOutletContext } from 'react-router-dom';
import { IDisplayDetailsOutletContext } from '../../details';
import { DisplayScreenUptimePeriodStatus, IDisplayScreenUptimeModel } from '~/api/displays';
import { DATE_FORMAT } from '~/utilities';

const getDurationText = (d: number) => {
	const hours = Math.floor((d / (60)));
	const minutes = Math.floor((d) % 60);
	return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`
}

const getStatusColor = (d: DisplayScreenUptimePeriodStatus, p: Palette) => {
	switch (d) {
		case DisplayScreenUptimePeriodStatus.Offline: return p.error.main;
		case DisplayScreenUptimePeriodStatus.Online: return p.success.main;
		case DisplayScreenUptimePeriodStatus.Unknown: return p.divider;
	}
}

const DisplayStatusScreen = () => {
	const {
		displayUptime
	} = useOutletContext<IDisplayDetailsOutletContext>();

	const { palette } = useTheme();
	const hours = Array.from(Array(24).keys());

	const renderUptime = (uptime: IDisplayScreenUptimeModel, index: number) => {
		const { date, periods } = uptime;
		return <li key={`screen-uptime-${index}`}>
			<span className='date'>{dayjs(date).format(DATE_FORMAT)}</span>

			<ul className='periods'>
				{periods.map((period, i) => {
					const { duration, status } = period;
					const durationText = getDurationText(duration);
					const title = `${status} for ${durationText}`;
					const color = getStatusColor(status, palette);
					const width = `${(duration / (60 * 24)) * 100}%`;
					return <Tooltip arrow key={`screen-uptime-period-${i}`} title={title} placement='top'>
						<li className={status} style={{ width, backgroundColor: color }}>&nbsp;</li>
					</Tooltip>
				})}
			</ul>

		</li>
	}

	return <Box>
		<Grid className='status' container spacing={2}>
			<Grid item xs={12}>
				{displayUptime.length === 0 ? <p>There are no recent uptime records for this display.</p> :
					<ul>
						<li>
							<ul className='periods-legend'>
								{hours.map((num: number, ni: number) => {
									return <li key={`period-legend-${ni}`}>{ni}</li>
								})}
							</ul>
						</li>
						{displayUptime.map((v, i) => renderUptime(v, i))}
					</ul>
				}
			</Grid>
		</Grid>
	</Box>
}

export default DisplayStatusScreen;