import { AnyAction, } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
import * as actions from '~/actions';
import { BBXAuthenticationResult } from '~/@types';

const INITIAL_STATE: IAccessTokenState = { code: '', expiry: undefined };

const accessTokenReducer = (state: IAccessTokenState = INITIAL_STATE, action: AnyAction | PayloadAction<BBXAuthenticationResult>): IAccessTokenState => {

	switch (action.type) {
		case actions.AUTH_SUCCESS: {
			const typedAction: PayloadAction<BBXAuthenticationResult> = action as PayloadAction<BBXAuthenticationResult>;
			const code = typedAction?.payload?.accessToken;
			const exp = typedAction?.payload?.expiresOn;
			
			if (code) return { code, expiry: exp?.getTime() };
			return INITIAL_STATE;
		}
		case actions.AUTH_FAILURE:
		case actions.AUTH_REFRESH_FAILURE:
		// fall through
		case actions.AUTH_LOGOUT_SUCCESS:
			return INITIAL_STATE;
		default: {
			const isExpired = state.expiry === undefined || new Date().getTime() > state.expiry;
			return isExpired ? INITIAL_STATE : state;
		}
	}
}

export interface IAccessTokenState {
	code: string;
	expiry: number | undefined;
}

export default accessTokenReducer;