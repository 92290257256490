import { Action } from '@reduxjs/toolkit';
import * as actions from '~/actions';
import { getVersion } from '~/utilities';


const INITIAL_STATE: IUIState = { isFullScreen: false, isMenuOpen: false, isActive: false, version: getVersion() };

/*
APP_OPEN_MENU
APP_CLOSE_MENU
APP_FULL_SCREEN_TEMPLATE_ON
APP_FULL_SCREEN_TEMPLATE_OFF
*/

const uiReducer = (state: IUIState = INITIAL_STATE, action: Action): IUIState => {
	switch (action.type) {
		case actions.APP_ACTIVE: {
			return { ...state, isActive: true };
		}
		case actions.APP_INACTIVE: {
			return { ...state, isActive: false };
		}
		case actions.APP_OPEN_MENU: {
			return { ...state, isMenuOpen: true };
		}
		case actions.APP_CLOSE_MENU: {
			return { ...state, isMenuOpen: false };
		}
		case actions.APP_FULL_SCREEN_TEMPLATE_ON: {
			return { ...state, isFullScreen: true };
		}
		case actions.APP_FULL_SCREEN_TEMPLATE_OFF: {
			return { ...state, isFullScreen: false };
		}
		case actions.AUTH_FAILURE:
		case actions.AUTH_REFRESH_FAILURE:
		// fall through
		case actions.AUTH_LOGOUT_REQUESTED: {
			return INITIAL_STATE;
		}
		// case actions.REFERENCE_TOKEN_GET_SUCCESS:
		// {
		// 	return { ...state, lastTokenRetrieval: new Date() }
		// }
		default: return { ...state, version: getVersion(state.version) };
	}
}

interface IUIState {
	isActive: boolean;
	isFullScreen: boolean;
	isMenuOpen: boolean;
	version?: string;
}

export default uiReducer;