import React from 'react';
import { CustomValidatorOptionsType, customizeValidator } from '@rjsf/validator-ajv8';
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus';
import ImageIcon from '@mui/icons-material/Image';
import ExtensionIcon from '@mui/icons-material/Extension';
import { RJSFSchema, UiSchema } from '@rjsf/utils';
import merge from 'deepmerge';
import clone from 'clone';
import * as DisplayTypes from '~/api/displays';
import * as ScreenTypes from '~/api/screens';
import * as utilities from '~/utilities';
import DataSelectComponent, { IDataSelectComponentGetDataProps } from '~/components/json-schema-forms/data-select';
import FileComponent from '~/components/json-schema-forms/file';
import { IBusDataModel } from '~/api/bus';
import BusApi from '~/api/bus';
import * as ReferenceTypes from '~/api/reference';

export interface IValidationResponseModel<T> {
	collection: boolean[];
	definition: T;
}

export interface IValidateRequestModel<T, V> {
	entity: Partial<T>;
	data?: V;
}

export interface IValidateScreenRequestDataModel {
	referenceWidgets: ReferenceTypes.IWidgetModel[];
	referenceLayouts: ReferenceTypes.ILayoutModel[];
	referenceTemplates: ReferenceTypes.ITemplateModel[];
}

export const validateScreen = (input: IValidateRequestModel<DisplayTypes.IDisplayDetailModel, IValidateScreenRequestDataModel>): IValidationResponseModel<{ displayName: boolean, siteId: boolean, latitude: boolean, longitude: boolean, layouts: boolean }> => {
	const collection: boolean[] = [];
	const { entity, data } = input;
	const { displayName, siteId, latitude, layouts, longitude } = entity;

	const validateName = (v: string | undefined) => !utilities.isNullOrEmpty(v);
	collection.push(validateName(displayName));

	const validateSiteId = (v: number | undefined) => !utilities.isNullOrEmpty(v);
	collection.push(validateSiteId(siteId));

	const validateLatitudeLongitude = (v: number | undefined) => {
		const nv = v || 0;
		return !isNaN(nv) && !utilities.isNullOrEmpty(v) && nv > -180 && nv < 180;
	}

	collection.push(validateLatitudeLongitude(latitude));
	collection.push(validateLatitudeLongitude(longitude));

	const validateLayouts = (v: DisplayTypes.IDisplayDetailLayoutModel[] | undefined) => {
		const { customFormats } = DATA_URL_VALIDATOR_FORMATS;
		const validator = customizeValidator({ customFormats });
		const bv = (v && v.length > 0 && v.filter(iv => iv.operatingModeId === 0).length > 0) || false;
		if (data && bv) {
			const configsValid: boolean[] = [];
			const { referenceLayouts, referenceTemplates, referenceWidgets } = data;

			for (const layout of v as DisplayTypes.IDisplayDetailLayoutModel[]) {
				const { layoutId, operatingModeId, } = layout;
				if (operatingModeId === -1) continue;
				for (const region of layout.regions) {
					const { regionId, widgetData } = region;
					const referenceLayout = referenceLayouts.find(v => v.layoutId === layoutId);
					const layoutRegion = referenceLayout?.regions.find(v => v.regionId === regionId);
					const { widgetId } = layoutRegion || { widgetId: -1 };
					const referenceWidget = referenceWidgets.find(v => v.widgetId === widgetId);
					const widgetSchema = referenceWidget ? getWidgetConfigurationSchema(referenceWidget?.widgetSchema) : undefined;
					const regionSchema = merge(layoutRegion?.widgetSchema || {}, widgetData || {});
					let formValid = false;
					// console.log(`validating 
					// widgetData 
					// ${JSON.stringify(widgetData)}
					
					// widgetSchema 
					// ${JSON.stringify(widgetSchema)}`);

					if (widgetSchema) {
						const errs = validator.validateFormData(widgetData, widgetSchema, undefined, undefined, regionSchema).errors;
						formValid = errs.length === 0;
					}

					configsValid.push(formValid);
				}
			}
			return configsValid.indexOf(false) === -1;
		} else
			return bv;
	}

	collection.push(validateLayouts(layouts));

	const definition = {
		siteId: validateSiteId(siteId),
		displayName: validateName(displayName),
		latitude: validateLatitudeLongitude(latitude),
		longitude: validateLatitudeLongitude(longitude),
		layouts: validateLayouts(layouts),
	}
	// console.log(`definition ${JSON.stringify(definition)}`)

	return { collection, definition };
}

export const getWidgetIcon = (name: string | undefined): React.ReactNode => {
	if (name?.toLowerCase().indexOf('bus') !== -1) return <DirectionsBusIcon />;
	if (name?.toLowerCase().indexOf('image') !== -1) return <ImageIcon />;
	switch (name) {
		case 'bus': return <DirectionsBusIcon />;
		case 'image': return <ImageIcon />;
		default: return <ExtensionIcon />;
	}
}

export const displayMatcher = (a: DisplayTypes.IDisplayKeyModel, b: DisplayTypes.IDisplayKeyModel) => a && b && a.displayId === b.displayId;
export const screenMatcher = (a: ScreenTypes.IScreenKeyModel, b: ScreenTypes.IScreenKeyModel) => a && b && a?.manufacturerId === b?.manufacturerId && a?.serialNumber.toLocaleLowerCase() === b?.serialNumber.toLocaleLowerCase();


const mapSchema = {
	name: {
		title: 'Name',
	},
	stops: {
		title: 'Stop(s)',
		uniqueItems: true,
	},
}

export const DATA_URL_FORMAT_NAME = 'string-url';
export const DATA_URL_VALIDATOR_FORMATS: CustomValidatorOptionsType = { customFormats: {} };
if (DATA_URL_VALIDATOR_FORMATS.customFormats) DATA_URL_VALIDATOR_FORMATS.customFormats[DATA_URL_FORMAT_NAME] = v => /(^(?:(?:(?:(?:(?:https?:\/\/)(?:[-a-zA-Z0-9:%._]{1,256}\.[a-zA-Z0-9()]{1,6})\/(?:[-a-zA-Z0-9:%._]*\/)*))?(?:library:\/[a-zA-Z0-9/]*)?(?:(?:[-a-zA-Z0-9()@:%_+]*)\.[a-zA-Z0-9()]{3,4}){1})).*$)/gm.test(v);

export const getWidgetConfigurationSchema = (schema: RJSFSchema | undefined): RJSFSchema => {
	try {
		const { required, properties, ...schemaRest } = schema ? schema : { required: [], properties: { }};
		const revisedSchema: RJSFSchema = { ...schemaRest };
		
		for (const prop in properties) {
			revisedSchema[prop] = properties[prop];
		}
		
		const props = schema ? merge(revisedSchema, mapSchema) : clone(mapSchema);
		let i = 0;
		
		for (const prop in props) {
			try {
				const tp = (props as { [x: string]: any });
				const v: { type: string, title: string, required: boolean, minLength?: number } = tp[prop];
				if (v.type === undefined) {
					delete tp[prop];
					// console.log(`deleting ${prop}`);
					continue;
				}

				if (v.title === undefined) {
					v.title = utilities.camelCaseToTitleCase(prop);
				}

				const typed = tp as { [x: string]: { format: string, isFile: boolean, watch: number } }

				if (typed[prop]?.format === 'data-url') {
					typed[prop].format = DATA_URL_FORMAT_NAME;
					typed[prop].isFile = true;
					typed[prop].watch = getWatch(i++)
				}

				if ((required || []).indexOf(prop) !== -1 && v.type === 'array' && v.minLength === undefined) {
					v.minLength = 1;
				}
			} catch (ex) {
				continue;
			}
		}
		const t: RJSFSchema = {
			type: 'object',
			properties: {
				...props,
			},
			required
		}
		// console.group('getSchema');
		// console.log(`
		// mapSchema ${JSON.stringify(mapSchema)}
		// schema ${JSON.stringify(schema)}
		// t ${JSON.stringify(t)}
		// props ${JSON.stringify(props)}
		// `)
		// console.groupEnd();
		return t;
	} catch (ex) {
		console.error(ex);
		return {};
	}
}

const getWatch = (x: number) => {
	const retVal = new Date().getTime() + Math.ceil(Math.random() * 100000) + x;
	return retVal;
}

const getBusDataLabel = (busStop: IBusDataModel) => `${busStop.name} ${busStop.indicator}`;

export interface IComponentDataProps {
	[x: string]: any;
}

export const getWidgetConfigurationUiSchema = (dataProps: IComponentDataProps, widgetSchema: { [x: string]: any } | undefined, widgetData: { [x: string]: any } | undefined): UiSchema => {
	const finWidgSchema = getWidgetConfigurationSchema(widgetSchema);

	const fileProps = [];

	if (finWidgSchema) {
		const { properties } = finWidgSchema;
		for (const prop in properties) {
			const item: { isFile: boolean | undefined } = properties[prop] as any;
			if (item.isFile === true) {
				const obj = JSON.parse(`{"${prop}": {"ui:widget": "File", "ui:options": {}}}`);
				const getOptions = (p: string) => {
					return {
						getUploadModel: (dp: IComponentDataProps) => {
							const { displayId, fieldName, layoutId, regionId, templateId, filename } = dp;
							return { displayId, layoutId, regionId, templateId, filename, fieldName };
						},
						getDataProps: () => {
							const { displayId, layoutId, regionId, templateId, } = dataProps;
							return { displayId, layoutId, regionId, templateId, fieldName: p };
						},
						getQueueContextModel: (dp: IComponentDataProps) => {
							const { displayId } = dp;
							return { model: { displayId }, matcher: (a: { displayId: number }, b: { displayId: number }) => a.displayId === b.displayId };
						},
					}
				};

				const typed = obj as { [x: string]: { 'ui:options': any } }

				typed[prop]['ui:options'] = getOptions(prop);
				fileProps.push(obj);
			}
		}

	}

	let baseRet: { [x: string]: any } = {

	};

	fileProps.forEach(v => {
		baseRet = { ...baseRet, ...v }
	});

	const retVal = {
		...baseRet,
		'ui:submitButtonOptions': {
			props: {
				className: 'btn-submit'
			}
		},
		name: {
			'ui:disabled': 'true',
		},
		stops: {
			'ui:widget': 'DataSelect',
			'ui:options': {
				getData: async (dataProps: IDataSelectComponentGetDataProps): Promise<{ queried: boolean, results: IBusDataModel[] }> => {
					const { siteId, includeStops } = dataProps;
					const queried = siteId !== undefined;
					const results = queried ? (await BusApi.getBusStopsBySite({ siteId: siteId as number, includeStops })).sort((a, b) => getBusDataLabel(a).localeCompare(getBusDataLabel(b))) : [];
					const retVal = { queried, results };
					return retVal;
				},
				valueExtractor: (busStop: IBusDataModel) => busStop.stopCode,
				labelExtractor: (busStop: IBusDataModel) => getBusDataLabel(busStop),
				getDataProps: () => {
					const { siteId } = dataProps;
					const { stops } = widgetData || { stops: [] };
					return { siteId, includeStops: stops || [] };
				},
				dataPropsChanged: (ov: IDataSelectComponentGetDataProps, nv: IDataSelectComponentGetDataProps) => {
					const s = ov.siteId === nv.siteId;
					return !s;
				},
				watch: new Date().getTime(),
			},
		},
	};
	return retVal;
}

export const widgetsConfigurationWidgets = {
	DataSelect: DataSelectComponent,
	File: FileComponent,
};
