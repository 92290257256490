import axios, { AxiosError, AxiosInstance, AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import { isolateAccessToken } from '~/selectors/auth';
import store from '~/stores';
import { isolateApiConfiguration } from '~/selectors/api';
import { refresh } from '~/actions/auth';

const getApiUrl = () => isolateApiConfiguration(store.getState()).apiUrl;

const config = {
	headers: {
		'Accept': 'application/json',
		'Cache-Control': 'no-cache',
		'Content-type': 'application/json',
		'Expires': '0',
		'Pragma': 'no-cache',
	},
	timeout: 15000,
	params: {
		t: new Date().getTime()
	}
}

const axiosClient: AxiosInstance = axios.create(config);
const logRequestUrls = false;

axiosClient.interceptors.request.use(
	config => {
		config.baseURL = `${getApiUrl()}`;
		config.headers.Authorization = `Bearer ${isolateAccessToken(store.getState())}`;
		if (logRequestUrls) console.log(`success ${config.url}`)
		return config;
	},
	error => {
		if (logRequestUrls) console.error(`error ${JSON.stringify(error)}`)
		return Promise.reject(error);
	}
);

axiosClient.interceptors.response.use(<T, D>(response: AxiosResponse<T, D>) => {
	// Remove requirement to call .data on the response of API calls
	if (response?.data) {
		return response.data;
	}
	return undefined;
}, (e: AxiosError) => {
	const { status, message, config } = e;

	const handleReauth = (s: number | undefined, m: string) => {
		const dispatchRenew = s === 401 || m.indexOf('401') !== -1;

		if (dispatchRenew) {
			store.dispatch(refresh());
		}
	}

	const handleErrorLog = (m: string, c: InternalAxiosRequestConfig | undefined) => {
		const statusCode = m.match(/\d{3}/ig) || [];
		const configHeaders: { Authorization: string } = (c?.headers as { Authorization: string }) || { Authorization: '' };
		const headers: { Authorization: string } = configHeaders;
		const authHeader = headers.Authorization || undefined;

		console.error(`${statusCode.length > 0 ? `StatusCode: ${statusCode.toString()}` : ''}
			Message: ${m}
			Url: ${c?.baseURL}${config?.url}
			Method: ${c?.method?.toUpperCase()}
			Payload:
			${JSON.stringify(c?.data)}
			${authHeader ? `Authorization: ${JSON.stringify(authHeader)}` : 'No Authorization header'}
			Stack: 
			${e.stack}`);
	}

	handleReauth(status, message);
	handleErrorLog(message, config);

});

export default axiosClient;