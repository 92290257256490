import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useSelector } from 'react-redux';
import LoaderIcon from '~/components/loader-icon';
import { isolateApiConfiguration } from '~/selectors/api';
import { getLoginRequest } from '~/auth-config';
import InputButton from '~/components/input-button';
import { isAuthenticationInProgress } from '~/utilities';
import { isolateIsPopulated, isolateIsRefreshing } from '~/selectors/reference';

const LoginScreen = () => {
	const { instance, inProgress } = useMsal();
	const isInProgress = isAuthenticationInProgress(inProgress);
	const apiConfig = useSelector(isolateApiConfiguration);
	const isReferencePopulated = useSelector(isolateIsPopulated);
	const isReferenceRefreshing = useSelector(isolateIsRefreshing);

	const handleLoginClick = () => {
		void instance.loginRedirect(getLoginRequest(apiConfig.env));
	}
	
	return isInProgress || (!isReferencePopulated && isReferenceRefreshing) ? <LoaderIcon /> : <div>
		{<InputButton onClick={handleLoginClick}>Login</InputButton>}
	</div>
}

export default LoginScreen;