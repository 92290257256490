import { PayloadAction } from '@reduxjs/toolkit';
import * as actions from '~/actions';
import * as UserTypes from '~/api/users';


const INITIAL_STATE: UserTypes.IUserAccountModel[] = [];

const userAccountsReducer = (state: UserTypes.IUserAccountModel[] = INITIAL_STATE, action: PayloadAction<UserTypes.IUserAccountModel[]>): UserTypes.IUserAccountModel[] => {
	switch (action.type) {
		case actions.USER_ACCOUNTS_GET_SUCCESS: {
			return [...action.payload];
		}
		case actions.AUTH_FAILURE:
		case actions.AUTH_REFRESH_FAILURE:
		// fall through
		case actions.AUTH_LOGOUT_REQUESTED: {
			return INITIAL_STATE;
		}
		default: return state;
	}
}

export default userAccountsReducer;
