import { Router } from '@remix-run/router';
import { PayloadAction } from '@reduxjs/toolkit';
import { all, call, fork, getContext, put, takeLatest } from 'redux-saga/effects'
import * as actions from '~/actions';
import UsersApi from '~/api/users';

function* getUserAccounts(): Generator {
	try {
		const payload = yield call<typeof UsersApi.getAvailableAccounts>(() => UsersApi.getAvailableAccounts());

		yield put({ type: actions.USER_ACCOUNTS_GET_SUCCESS, payload });


	} catch (e) {
		console.error(e);
		yield put({ type: actions.USER_ACCOUNTS_GET_FAILURE });
	}
}

function* setUserAccountCode(action: PayloadAction<string>): Generator {
	try {
		yield call<typeof UsersApi.setCurrentAccount>(p => UsersApi.setCurrentAccount(p), action.payload);

		yield put({ type: actions.USER_ACCOUNT_SET_SUCCESS });
		
		const router = yield getContext('router');
		void (router as Router).navigate('/');

	} catch (e) {
		console.error(e);
		yield put({ type: actions.USER_ACCOUNT_SET_FAILURE });
	}
}

function* watchAccountRequest(): Generator {
	yield takeLatest([actions.AUTH_SUCCESS, actions.USER_ACCOUNTS_GET_REQUESTED], getUserAccounts);
}


function* watchSetAccountRequest(): Generator {
	yield takeLatest([actions.USER_ACCOUNT_SET_REQUESTED], setUserAccountCode);
}

function* userSaga(): Generator {
	yield all([
		fork(watchAccountRequest),
		fork(watchSetAccountRequest),
	])
}

export default userSaga;