import { InteractionStatus } from '@azure/msal-browser';
import React from 'react';
import { createBrowserRouter, NavigateFunction, NonIndexRouteObject } from 'react-router-dom';
import HomeIndex from './screens/home';
import DebugIndex from './screens/debug';
import LoginScreen from './screens/login';
import LogoutScreen from './screens/logout';
import EditDisplayDetails from './screens/displays/components/details/edit';
import EditDisplayLayoutDetails from './screens/displays/components/details/configuration';
import DisplayDetails from './screens/displays/details';
import DisplaysListing from './screens/displays/listing';
import ErrorIndex from './screens/error';
import { isAuthenticationInProgress } from './utilities';
import { selectHasPermissions } from './selectors/auth';
import DisplayScreenshotsScreen from './screens/displays/components/details/screenshots';
import DisplayStatusScreen from './screens/displays/components/details/status';

const ROUTE_LOGIN = '/login';
const ROUTE_FORBID = '/forbidden';
const ROUTE_REDIRECT_ROOT = '/displays';

export interface TitledRouteObject extends NonIndexRouteObject {
	title?: string;
	children?: TitledRouteObject[];
	secure?: boolean;
	permissions?: string[];
}

const getRoutes: () => TitledRouteObject[] = () => [
	{
		path: '/', secure: false, element: <HomeIndex />, children: [
			{
				title: 'Display Listing', path: '/displays', element: <DisplaysListing />,
			},
			{
				title: 'Display Details', path: '/displays/:displayId', element: <DisplayDetails />,
				children: [
					{ title: 'Display Details', path: '/displays/:displayId/details', element: <EditDisplayDetails /> },
					{ title: 'Display Details', path: '/displays/:displayId/configuration', element: <EditDisplayLayoutDetails /> },
					{ title: 'Display Details', path: '/displays/:displayId/screenshots', element: <DisplayScreenshotsScreen /> },
					{ title: 'Display Details', path: '/displays/:displayId/status', element: <DisplayStatusScreen /> },
				],
			},
			{
				title: 'Login', path: ROUTE_LOGIN, secure: false, element: <LoginScreen />,
			},
			{
				title: 'Logout', path: '/logout', secure: false, element: <LogoutScreen />,
			},
			{
				title: 'Debug', path: '/debug', secure: false, element: <DebugIndex />,
			},
			// { path: '/permed', secure: true, element: <DebugIndex />, permissions: [PermissionsService.getName('screen', 'bacon')] },

		],
	},
	{ path: '*', secure: false, element: <ErrorIndex status={404} />, },
	{ path: ROUTE_FORBID, secure: false, element: <ErrorIndex status={403} />, },
]

export const handleRouteChange = (props: { accessToken: string, initializing: boolean, isAuthenticated: boolean, inProgress: InteractionStatus, isReferencePopulated: boolean, isReferenceRefreshing: boolean, navigate: NavigateFunction, pathname: string, permissions: string[], routePermissions: string[], routeSecure: boolean }) => {
	const { accessToken, isAuthenticated, inProgress, initializing, isReferencePopulated, isReferenceRefreshing, navigate, pathname, permissions, routePermissions, routeSecure, } = props;
	const authInProgress = isAuthenticationInProgress(inProgress);
	const isAuthInitializing = InteractionStatus.Startup === inProgress || initializing;
	const key = 'aoqwpaslk';
	const log = true;
	const debug = (s: string, c: string = 'lime') => log && console.debug(`%c${key} ${s}`, `color: ${c}`);
	const n = (x: string) => navigate(x, { replace: true });
	const rc = 'deeppink';

	debug(`handleRouteChange
		initializing ${initializing}
		isAuthInitializing ${isAuthInitializing}
		pathname ${pathname} 
		routeSecure ${routeSecure}
		isAuthenticated ${isAuthenticated}
		authInProgress ${authInProgress}
		inProgress ${inProgress}
		isReferencePopulated ${isReferencePopulated}
		isReferenceRefreshing ${isReferenceRefreshing}
		routePermissions ${routePermissions.join(',')}
		permissions ${permissions.join(',')}
		createdRouter.state ${JSON.stringify(createdRouter.state)}
		accessToken ${accessToken ? `${accessToken.slice(0,50)}...` : 'undefined'}`);

	if (isAuthInitializing || authInProgress || (!isReferencePopulated && isReferenceRefreshing)) {
		debug(`Skipping Redirect because ${authInProgress ? 'Auth is in progress' : isAuthInitializing ? 'Auth is initializing' : 'Reference is refreshing and unpopulated'}`, rc);
		return;
	}

	// Redirect from / as there is no child view for that route
	if (pathname === '/') {
		if (isAuthenticated) {
			debug(`Redirecting to ${ROUTE_REDIRECT_ROOT} pathname and isAuthenticated`, rc);
			n(ROUTE_REDIRECT_ROOT);
			return;
		} else {
			debug(`Redirecting to ${ROUTE_LOGIN} pathname and isAuthenticated`, rc);
			n(ROUTE_LOGIN);
			return;
		}
	}

	// If the route we're trying to access is secure, and we're not authenticated, 
	if (routeSecure && !isAuthenticated && !authInProgress) {
		debug(`Redirecting to ${ROUTE_FORBID} due to authentication state`, rc);
		n(ROUTE_FORBID);
		return;
	}

	// If there's a permission mismatch
	if (routeSecure && isAuthenticated && routePermissions.length > 0) {
		const hasPerms = selectHasPermissions(permissions, routePermissions, true);
		if (!hasPerms) {
			debug(`Redirecting to ${ROUTE_FORBID} due to permissions`, rc);
			n(ROUTE_FORBID);
			return;
		}
	}

	// If we're going to login but we are already logged in
	if (pathname === ROUTE_LOGIN && isAuthenticated && !authInProgress) {
		debug(`Redirecting to ${ROUTE_REDIRECT_ROOT} due to login and being authenticated without it in progress`, rc);
		n(ROUTE_REDIRECT_ROOT);
		return;
	}

	// If we're logged out and not even attempting to login, 
	if (pathname !== ROUTE_LOGIN && !authInProgress && !isAuthenticated && !isReferencePopulated) {
		debug(`Redirecting to ${ROUTE_LOGIN} !authInProgress && !isAuthenticated && !isReferencePopulated ${pathname}`, rc);
		n(ROUTE_LOGIN);
		return;
	}

	debug('Not gonna do anything');
}

export const routes: TitledRouteObject[] = getRoutes();

const createdRouter = createBrowserRouter([
	...routes
]);

export default createdRouter