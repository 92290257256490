import React, { useEffect, useState } from 'react';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import './index.scss';
import { useLocation, useNavigate } from 'react-router-dom';

const getActiveTab = (routes: string[], path: string) => {
	const ind = routes.indexOf(path);
	// console.debug(`looking for ${path} got ${ind} in 
	// ${JSON.stringify(routes)}`)
	return ind === -1 ? 0 : ind;
}

const RouterTabs = (props: IRouterTabsProps) => {
	const { children, onChange, routes, ...rest } = props;
	const { pathname } = useLocation();
	const [tabIndex, setTabIndex] = useState(getActiveTab(routes, pathname));
	const navigate = useNavigate();

	const handleChange = (e: React.SyntheticEvent | undefined, newValue: number) => {
		if (routes[newValue] !== pathname) { 
			// console.debug(`navigating because ${routes[newValue]} != ${pathname}`)
			navigate(routes[newValue], { replace: e === undefined });
		}
		if (onChange) {
			// console.debug(`fire on change with ${newValue.toString()}`);
			onChange(e, newValue);
		}
	};

	useEffect(() => {
		const newTabIndex = getActiveTab(routes, pathname);
		
		if (tabIndex !== newTabIndex) {
			setTabIndex(newTabIndex);
		}
		handleChange(undefined, newTabIndex);
	}, [pathname])

	return <Tabs onChange={(e, v: number) => handleChange(e, v)} {...rest}>
		{children}
	</Tabs>
}

interface IRouterTabsProps extends TabsProps {
	routes: string[];
	onChange?: (event: React.SyntheticEvent | undefined, value: any) => void;
}

export default RouterTabs;