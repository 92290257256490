import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useOutletContext } from 'react-router-dom';
import Autocomplete, { AutocompleteRenderInputParams } from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { IDisplayDetailsOutletContext } from '../../../details';
import DisplayLayout, { ScreenLayoutButtons } from './display-layout';
import DisplayLayoutWidgetConfiguration from './widget-configuration';
import { IDisplayDetailLayoutModel, IDisplayDetailRegionModel } from '~/api/displays';
import { isolateLayouts, isolateOperationModes, isolateTemplates } from '~/selectors/reference';
import './layout-configuration.scss';
import * as ReferenceTypes from '~/api/reference';
import InputText from '~/components/input-text';
import { isNullOrEmpty } from '~/utilities';

export interface IEditDisplayLayoutConfigurationProps {
	operatingModeId: number;
}

const EditDisplayLayoutConfiguration = (props: IEditDisplayLayoutConfigurationProps) => {
	const { operatingModeId } = props;
	const {
		displayId,
		displayLayouts, setDisplayLayouts,
		displaySiteId,
		readLayout, writeLayout
	} = useOutletContext<IDisplayDetailsOutletContext>();
	const screenTemplates = useSelector(isolateTemplates);
	const screenLayouts = useSelector(isolateLayouts);
	const operatingModes = useSelector(isolateOperationModes);
	const options = screenLayouts.filter(v => displayLayouts.findIndex(iv => iv.layoutId === v.layoutId) !== -1).map(v => v.layoutName);
	const [displayLayout, setDisplayLayout] = useState<IDisplayDetailLayoutModel>();
	const [displayRegions, setDisplayRegions] = useState(displayLayout?.regions || []);
	const screenLayout = screenLayouts.find(v => v.layoutId === displayLayout?.layoutId);
	const screenTemplate = screenTemplates.find(v => v.templateId === screenLayout?.templateId);
	const operatingModeOptions = operatingModes.filter(v => displayLayouts.map(iv => iv.operatingModeId).indexOf(v.operatingModeId) !== -1).map(v => v.operatingModeName);
	const [displayLayoutName, setDisplayLayoutName] = useState<string | undefined>();
	const operatingModeName: string | undefined = operatingModes.find(iv=> iv.operatingModeId === operatingModeId)?.operatingModeName;
	const [selectedOperatorMode, setSelectedOperatorMode] = useState<string | undefined>(operatingModeName);
	const [layoutError, setLayoutError] = useState(false);
	const [activeRegionId, setActiveRegionId] = useState<number>();
	const [activeWidgetId, setActiveWidgetId] = useState<number>();
	const [activeLayoutId, setActiveLayoutId] = useState(displayLayout?.layoutId);
	const [activeDisplayRegion, setActiveDisplayRegion] = useState<IDisplayDetailRegionModel>();
	const [activeScreenTemplateRegions, setActiveScreenTemplateRegions] = useState<ReferenceTypes.IScreenTemplateRegionModel[]>([]);
	const [activeScreenLayoutRegions, setActiveScreenLayoutRegions] = useState<ReferenceTypes.IScreenLayoutRegionModel[]>([]);
	const showLayoutSelection = false;

	useEffect(() => {
		const dl = displayLayouts.find(v => v.operatingModeId === operatingModeId);
		const sl = screenLayouts.find(v => v.layoutId === dl?.layoutId);
		const t = screenTemplates.find(v => v.templateId === sl?.templateId);
		// console.log(`useEffect operatingMode dl ${JSON.stringify(dl)} ${sl?.layoutName} ${displayLayoutName} ${JSON.stringify(options)} ${options.indexOf(sl?.layoutName || '')}`);
		setDisplayLayout(dl);
		setActiveLayoutId(dl?.layoutId);
		setDisplayRegions(dl?.regions || []);
		setDisplayLayoutName(sl?.layoutName);
		setActiveScreenLayoutRegions(sl?.regions || []);
		setActiveRegionId(undefined);
		setActiveDisplayRegion(undefined);
		changeActiveWidgetId(undefined);
		setActiveScreenTemplateRegions(t?.regions || []);
	}, []);

	const getEmptyLayout = (layoutId: number): IDisplayDetailLayoutModel => {
		const sl = screenLayouts.find(v => v.layoutId === layoutId);
		const { templateId, regions } = sl || { templateId: -1, regions: [] };
		/*  
		templateId: number;
	layoutId: number;
	operatingModeId: number;
	regions: IDisplayDetailRegionModel[];
		*/
		const retVal = { layoutId, templateId, operatingModeId, regions: regions.map(v => { return { regionId: v.regionId, widgetData: {} } }) };
		// console.log(`Get empty layout for layoutId ${layoutId} retVal ${JSON.stringify(retVal)}`)
		return retVal;
	}

	const changeLayout = (v: string | null | undefined) => {
		const layout = screenLayouts.find(iv => v && iv.layoutName === v);
		const t = screenTemplates.find(v => v.templateId === layout?.templateId);
		const dl = displayLayouts.find(v => v.layoutId === layout?.layoutId);

		const dls = displayLayouts.slice();

		setDisplayLayouts(dls);
		setDisplayLayout(dl);
		setActiveLayoutId(dl?.layoutId)
		setDisplayLayoutName(layout?.layoutName);
		setActiveScreenLayoutRegions(layout?.regions || []);
		setActiveRegionId(undefined);
		changeActiveWidgetId(undefined);
		setActiveDisplayRegion(undefined);
		setActiveScreenTemplateRegions(t?.regions || []);

	}

	const pressWidgetSettings = (region: ReferenceTypes.IScreenTemplateRegionModel, widget: ReferenceTypes.IWidgetModel) => {
		const layout = screenLayouts.find(iv => displayLayoutName && iv.layoutName === displayLayoutName);
		const dl = displayLayouts.find(v => v.layoutId === activeLayoutId);

		const dr = dl?.regions.find(v => v.regionId === region.regionId);
		setDisplayRegions(dl?.regions || []);
		changeActiveWidgetId(widget.widgetId);
		setActiveDisplayRegion(dr);
		setActiveRegionId(dr?.regionId);
		// console.log(`set active widget to ${widget.widgetId}`)
		if (!dr) console.warn(`no display region found for ${region.regionId} 
		
		dl ${JSON.stringify(dl)}
		
		`)
	}

	const layoutActiveRegionChanged = (r: string | undefined) => {
		const templateRegion = screenTemplate?.regions.find(v => v.regionName === r);

		setActiveRegionId(templateRegion?.regionId);
		setActiveDisplayRegion(displayRegions.find(v => v.regionId === templateRegion?.regionId));
	}

	const widgetConfigurationClose = () => {
		changeActiveWidgetId(undefined);
		setActiveRegionId(undefined);
		setActiveDisplayRegion(undefined);
	}

	const changeActiveWidgetId = (newId: number | undefined, key: string = '') => {
		// console.log(`changeActiveWidgetId (${key}) ${activeWidgetId} => ${newId}`)
		setActiveWidgetId(newId);
	}

	const changeOperatingMode = (v: string) => {
		setSelectedOperatorMode(v);
		const newOpMode = operatingModes.find(iv => iv.operatingModeName === v);
		const newLayoutId = displayLayouts.find(iv => iv.operatingModeId === newOpMode?.operatingModeId)?.layoutId;
		const newLayoutName = screenLayouts.find(iv => iv.layoutId === newLayoutId)?.layoutName;
		console.log(`
		v ${v}
		newOpMode ${JSON.stringify(newOpMode)}
		newLayoutId ${newLayoutId}
		newLayoutName ${newLayoutName}`)
		changeLayout(newLayoutName);
	}

	return !readLayout ? <></> : <Box className='layout-configuration'>
		<Grid container spacing={2}>
			{
				showLayoutSelection && <Grid item xs={12}>
					{displayLayoutName && <Autocomplete
						disabled={!writeLayout}
						disableClearable={true}
						options={options}
						value={displayLayoutName}
						renderInput={(params: AutocompleteRenderInputParams) => {
							return <InputText {...params} label="Layout" placeholder="Layout" required error={layoutError} />
						}}
						onChange={(e, v) => changeLayout(v)}
					/>}
				</Grid>
			}
			{
				!showLayoutSelection && <Grid item xs={12}>
					{selectedOperatorMode && <Autocomplete
						disabled={!writeLayout || operatingModeOptions.length === 1}
						disableClearable={true}
						options={operatingModeOptions}
						value={selectedOperatorMode}
						renderInput={(params: AutocompleteRenderInputParams) => {
							return <InputText {...params} label="Operating Mode" placeholder="Operating Mode" required error={layoutError} />
						}}
						onChange={(e, v) => changeOperatingMode(v)}
					/>}
				</Grid>
			}
			<Grid item xs={8}>
				{<DisplayLayout displayLayouts={displayLayouts} layoutId={activeLayoutId} displayId={displayId} buttons={[ScreenLayoutButtons.Settings]} regions={activeScreenTemplateRegions} layoutRegions={activeScreenLayoutRegions} height={screenTemplate?.pixelHeight || 0} width={screenTemplate?.pixelWidth || 0} activeRegionId={activeRegionId} onActiveRegionChanged={layoutActiveRegionChanged} onPressWidgetSettings={pressWidgetSettings}></DisplayLayout>}
			</Grid>
			<Grid item xs={4}>
				<DisplayLayoutWidgetConfiguration templateId={screenTemplate?.templateId} editable={writeLayout} displayLayouts={displayLayouts} siteId={displaySiteId} layoutId={activeLayoutId} regionId={activeRegionId} widgetId={activeWidgetId} open={!isNullOrEmpty(activeWidgetId)} screenRegion={activeDisplayRegion} onClose={widgetConfigurationClose} />
			</Grid>
		</Grid>
	</Box>
}

export default EditDisplayLayoutConfiguration;