import { PayloadAction } from '@reduxjs/toolkit';
import * as actions from '~/actions';
import * as ReferenceTypes from '~/api/reference';


const INITIAL_STATE: ReferenceTypes.ITemplateModel[] = [];

const screenTemplatesReducer = (state: ReferenceTypes.ITemplateModel[] = INITIAL_STATE, action: PayloadAction<ReferenceTypes.IGetReferenceDataModel>): ReferenceTypes.ITemplateModel[] => {
	switch (action.type) {
		case actions.REFERENCE_DATA_GET_SUCCESS: {
			const { templates: screenTemplates } = action.payload;
			const sortRegions = (a: ReferenceTypes.IScreenTemplateRegionModel, b: ReferenceTypes.IScreenTemplateRegionModel) => {
				if (!a || !b) { 
					return !a && !b ? 0 : !a ? -1 : 1;
				}
				const aX = a.pixelLeft;
				const aY = a.pixelTop;
				const bX = b.pixelLeft;
				const bY = b.pixelTop;
				const xVal = aX < bX ? -1 : aX === bX ? 0 : 1;
				const yVal = aY < bY ? -1 : aY === bY ? 0 : 1;
				if (yVal === 0) return xVal;
				return yVal;
			}
			screenTemplates.forEach(v=>v.regions.sort(sortRegions));
			return screenTemplates;
		}
		case actions.AUTH_FAILURE:
		case actions.AUTH_REFRESH_FAILURE:
			// fall through
		case actions.AUTH_LOGOUT_REQUESTED: {
			return INITIAL_STATE;
		}
		default: return state;
	}
}

export default screenTemplatesReducer;
