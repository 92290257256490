export enum ActionResultType {
	Request = 'REQUESTED',
	Success = 'SUCCESS',
	Failure = 'FAILURE'
}

const REQUESTED: string = `${ActionResultType.Request}`;
const SUCCESS: string = `${ActionResultType.Success}`;
const FAILURE: string = `${ActionResultType.Failure}`;

// App
export const APP_ACTIVE: string = 'APP_ACTIVE';
export const APP_INACTIVE: string = 'APP_INACTIVE';
export const APP_OPEN_MENU: string = 'APP_OPEN_MENU';
export const APP_CLOSE_MENU: string = 'APP_CLOSE_MENU';
export const APP_FULL_SCREEN_TEMPLATE_ON: string = 'APP_FULL_SCREEN_TEMPLATE_ON';
export const APP_FULL_SCREEN_TEMPLATE_OFF: string = 'APP_FULL_SCREEN_TEMPLATE_OFF';
export const APP_START: string = 'APP_START';

// API
export const API_UPDATE_ENVIRONMENT_REQUESTED: string = `API_UPDATE_ENVIRONMENT_${REQUESTED}`;
export const API_UPDATE_ENVIRONMENT_SUCCESS: string = `API_UPDATE_ENVIRONMENT_${SUCCESS}`;
export const API_UPDATE_ENVIRONMENT_FAILURE: string = `API_UPDATE_ENVIRONMENT_${FAILURE}`;

// Auth
export const AUTH_REFRESH_REQUESTED: string = `AUTH_REFRESH_${REQUESTED}`;
export const AUTH_REFRESH_SUCCESS: string = `AUTH_REFRESH_${SUCCESS}`;
export const AUTH_REFRESH_FAILURE: string = `AUTH_REFRESH_${FAILURE}`;

export const AUTH_REQUESTED: string = `AUTH_${REQUESTED}`;
export const AUTH_SUCCESS: string = `AUTH_${SUCCESS}`;
export const AUTH_FAILURE: string = `AUTH_${FAILURE}`;

export const AUTH_LOGOUT_REQUESTED: string = `AUTH_LOGOUT_${REQUESTED}`;
export const AUTH_LOGOUT_SUCCESS: string = `AUTH_LOGOUT_${SUCCESS}`;
export const AUTH_LOGOUT_FAILURE: string = `AUTH_LOGOUT_${FAILURE}`;

// Reference
export const REFERENCE_DATA_GET_REQUESTED: string = `REFERENCE_DATA_GET_${REQUESTED}`;
export const REFERENCE_DATA_GET_SUCCESS: string = `REFERENCE_DATA_GET_${SUCCESS}`;
export const REFERENCE_DATA_GET_FAILURE: string = `REFERENCE_DATA_GET_${FAILURE}`;

export const REFERENCE_GET_STORAGE_ACCESS_SIGNATURE_REQUESTED: string = `REFERENCE_GET_STORAGE_ACCESS_SIGNATURE_${REQUESTED}`;
export const REFERENCE_GET_STORAGE_ACCESS_SIGNATURE_SUCCESS: string = `REFERENCE_GET_STORAGE_ACCESS_SIGNATURE_${SUCCESS}`;
export const REFERENCE_GET_STORAGE_ACCESS_SIGNATURE_FAILURE: string = `REFERENCE_GET_STORAGE_ACCESS_SIGNATURE_${FAILURE}`;


// User
export const USER_ACCOUNTS_GET_REQUESTED: string = `USER_ACCOUNTS_GET_${REQUESTED}`;
export const USER_ACCOUNTS_GET_SUCCESS: string = `USER_ACCOUNTS_GET_${SUCCESS}`;
export const USER_ACCOUNTS_GET_FAILURE: string = `USER_ACCOUNTS_GET_${FAILURE}`;

export const USER_ACCOUNT_SET_REQUESTED: string = `USER_ACCOUNT_SET_${REQUESTED}`;
export const USER_ACCOUNT_SET_SUCCESS: string = `USER_ACCOUNT_SET_${SUCCESS}`;
export const USER_ACCOUNT_SET_FAILURE: string = `USER_ACCOUNT_SET_${FAILURE}`;