import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import { RJSFValidationError, WidgetProps } from '@rjsf/utils';
import { FormControl } from '@mui/material';
import LoaderIcon from '~/components/loader-icon';
import InputText from '~/components/input-text';
import './index.scss';

export interface IDataSelectComponentGetDataProps {
	[x: string]: any;
}

export interface IUiDataSelectOptionsModel<T, VT,> {
	getData: (props: { [x: string]: any; }) => Promise<{ queried: boolean, results: T[] }>;
	valueExtractor: (v: T) => VT;
	labelExtractor: (v: T) => string;
	getDataProps: () => IDataSelectComponentGetDataProps;
	dataPropsChanged: (oldValue: IDataSelectComponentGetDataProps, newValue: IDataSelectComponentGetDataProps) => boolean;
	watch: number;
	rawErrors: RJSFValidationError[];
}

const DataSelectComponent = <T, VT,>(props: WidgetProps<T>) => {
	const { multiple, onBlur, onChange, onFocus, options, rawErrors, required, schema, value } = props;
	const { title } = schema;
	const { dataPropsChanged, getData, getDataProps, labelExtractor, valueExtractor, watch } = (options as unknown) as IUiDataSelectOptionsModel<T, VT>;
	const [loading, setLoading] = React.useState(false);
	const [data, setData] = React.useState<T[]>([]);
	const [rows, setRows] = React.useState<string[]>([]);
	const [selectedValues, setSelectedValues] = React.useState<VT[]>([]);
	const [selectedAutoCompleteValues, setSelectedAutoCompleteValues] = React.useState<string[]>([]);
	const [queriedForResults, setQueriedForResults] = React.useState<boolean>(false);
	const [lastDataProps, setLastDataProps] = React.useState<{ [x: string]: any; } | undefined>();
	const error = rawErrors && rawErrors?.length > 0;
	const errorText = error ? rawErrors?.join(', '): undefined;

	// for (const prop in props) console.log(`prop asd ${prop}` )


	React.useEffect(() => {
		const dataProps = getDataProps();
		// console.log(`pqowke  useEffectAll dataSelectComponent watch ${watch ? watch.toString() : ''} 
		// dataProps ${JSON.stringify(dataProps)}
		// lastDataProps ${JSON.stringify(lastDataProps)}
		// !lastDataProps ${!lastDataProps}
		// (lastDataProps && dataPropsChanged(lastDataProps, dataProps)) ${(lastDataProps && dataPropsChanged(lastDataProps, dataProps))}`);
		if ((lastDataProps && dataPropsChanged(lastDataProps, dataProps)) || !lastDataProps) {
			setLastDataProps(dataProps);
			loadDataFromMethod();
		}
	}, [watch])

	const getSomeData = () => {
		setLoading(true);
		if (loading) return;
		// console.log('pqowke getSomeData');
		const dataProps = getDataProps();
		setLastDataProps(dataProps);
		void getData(dataProps).then((v) => {
			setQueriedForResults(v.queried);
			setData(v.results);
			setRows(v.results.map(v => labelExtractor(v)));
			const a = v.results.filter(v => value instanceof Array && value.indexOf(valueExtractor(v)) !== -1).map(v=>labelExtractor(v));
			const b = v.results.filter(v => value instanceof Array && value.indexOf(valueExtractor(v)) !== -1).map(v=>valueExtractor(v));
			setSelectedAutoCompleteValues(a);
			setSelectedValues(b);
		}).finally(() => setLoading(false));
	}

	const loadDataFromMethod = () => {
		getSomeData();
	}

	const focusSelect = (e: React.FocusEvent<HTMLDivElement>) => {
		if (onFocus) onFocus(e.target.id, selectedValues);
	}

	const blurSelect = (e: React.FocusEvent<HTMLDivElement>) => {
		if (onBlur) onBlur(e.target.id, selectedValues);
	}

	const changeSelected = (e: React.SyntheticEvent<Element, Event>, value: string | string[] | null) => {
		const selRows = data.filter(v => value?.indexOf(labelExtractor(v)) !== -1).map(v => valueExtractor(v));
		if (value instanceof Array) {
			setSelectedAutoCompleteValues(value);
			setSelectedValues(selRows);
		}
		else if (value) {
			setSelectedAutoCompleteValues([value]);
			setSelectedValues(selRows);
		}
		else {
			setSelectedAutoCompleteValues([]);
			setSelectedValues([]);
		}
		if (onChange) onChange(selRows);
	}

	return <FormControl>
		{!loading &&
			<Autocomplete
				disabled={!queriedForResults}
				multiple={multiple}
				options={rows}
				value={selectedAutoCompleteValues}
				limitTags={3}
				onFocus={focusSelect}
				onBlur={blurSelect}
				renderInput={(params) => (
					<InputText error={error} helperText={errorText} required={required} {...params} label={title} placeholder={required ? `${title} *` : title} />
				)}
				onChange={(e: React.SyntheticEvent<Element, Event>, v: string | string[] | null) => changeSelected(e, v)}
			/>
		}
		{loading && <LoaderIcon />}

	</FormControl>
}

export default DataSelectComponent;