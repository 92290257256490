import { datadogRum } from '@datadog/browser-rum';
import { Configuration as MSALPublicClientAppConfiguration, EventMessage, EventType, PublicClientApplication } from '@azure/msal-browser';
import { AnyAction } from '@reduxjs/toolkit';
import { Dispatch } from 'react';
import { BBXAuthenticationResult } from '~/@types';
import * as Actions from '~/actions';
import { MSAL_CONFIG, getLoginRequest } from '~/auth-config';
import store from '~/stores';
import { isolateApiConfiguration } from '~/selectors/api';
import { setDataDogUserProperties } from '~/utilities';
import { IApiConfiguration } from '~/stores/api';

export interface B2CPolicies {
	signInSignUp: string;
	passwordReset?: string;
}

export type B2CConfiguration = Omit<MSALPublicClientAppConfiguration, 'auth'> & {
	auth: {
		clientId: string;
		authorityBase: string;
		policies: B2CPolicies | undefined;
		redirectUri?: string;
		navigateToLoginRequestUrl?: boolean;
	};
};

export class AuthService {
	public pca: PublicClientApplication;
	public initializing: boolean = true;
	private readonly policyUrls: B2CPolicies;
	private dispatch: Dispatch<AnyAction>;

	constructor() {
		this.dispatch = store.dispatch;

		const apiConfig = isolateApiConfiguration(store.getState());
		const { authorityBase, policies, } = MSAL_CONFIG.auth;
		this.policyUrls = makePolicyUrls(authorityBase, policies);

		const config: B2CConfiguration = this.getMergedConfig(MSAL_CONFIG, apiConfig);
		console.log(JSON.stringify(config));
		this.pca = new PublicClientApplication(config);
		this.initialize();
	}

	public async login() {
		await this.pca.loginRedirect(getLoginRequest(isolateApiConfiguration(store.getState()).env));
	}

	public async logout() {
		await this.pca.logoutRedirect();
	}

	public async refresh() {
		const account = this.pca.getActiveAccount();
		if (account) {
			await this.pca.acquireTokenSilent({ account, ...getLoginRequest(isolateApiConfiguration(store.getState()).env) });
		}
	}

	public addEventCallback(handler: (e: EventMessage) => void) {
		this.pca.addEventCallback(handler);
	}

	private initialize() {
		this.pca.addEventCallback(e => this.handleEventCallback(e));
	}

	private postInitialize() {
		const accounts = this.pca.getAllAccounts();
		console.debug(`accounts found ${accounts.length}`);
		if (accounts.length > 0) {
			this.pca.setActiveAccount(accounts[0]);
		}
		this.initializing = false;
	}

	private getMergedConfig(config: B2CConfiguration, apiConfig: IApiConfiguration) {
		const { postLogoutRedirectUri } = config.auth as any as { postLogoutRedirectUri: string };
		const { redirectUri, ...restOfAuthConfig } = config.auth;
		const authority = this.policyUrls.signInSignUp;
		const values = [];
		for (const prop in this.policyUrls) values.push((this.policyUrls as { [index: string]: any })[prop]);
		const knownAuthorities = values;
		const fplru = !apiConfig.isLocalhost ? `${apiConfig.uiUrl}${postLogoutRedirectUri?.startsWith('/') ? postLogoutRedirectUri?.slice(1) : postLogoutRedirectUri}` : postLogoutRedirectUri;
		const fru = !apiConfig.isLocalhost ? `${apiConfig.uiUrl}${redirectUri?.startsWith('/') ? redirectUri?.slice(1) : redirectUri}` : redirectUri;
		const finConfig = {
			...MSAL_CONFIG,
			auth: {
				authority,
				knownAuthorities,
				postLogoutRedirectUri: fplru,
				redirectUri: fru,
				...restOfAuthConfig
			},
		};
		console.log(`postLogoutRedirectUri ${fplru}
		redirectUri ${fru}
		apiConfig ${JSON.stringify(apiConfig)}`)
		return finConfig;
	}

	private handleEventCallback(event: EventMessage) {
		console.debug(`handle auth event ${JSON.stringify(event)}`);
		if ([EventType.LOGIN_SUCCESS, EventType.ACQUIRE_TOKEN_SUCCESS].indexOf(event.eventType) !== -1 && event.payload) {
			const payload = event.payload as BBXAuthenticationResult;
			const account = payload.account;
			const { idTokenClaims } = payload;
			const { userId, role, currentAccountCode } = idTokenClaims;
			setDataDogUserProperties({ userId, role, accountCode: currentAccountCode }, datadogRum);

			if (account) {
				this.pca.setActiveAccount(account);
				this.dispatch({ type: Actions.AUTH_SUCCESS, payload });
			}
		} else if (event.eventType === EventType.LOGIN_FAILURE) {
			this.dispatch({ type: Actions.AUTH_FAILURE });
		} else if (event.eventType === EventType.LOGOUT_SUCCESS) {
			this.dispatch({ type: Actions.AUTH_LOGOUT_SUCCESS });
		} else if (event.eventType === EventType.INITIALIZE_END) {
			this.postInitialize();
		}
	}
}

const makeAuthority = (authorityBase: string, policyName: string) => {
	return `${authorityBase}/${policyName}`;
}

const makePolicyUrls = (authorityBase: string, policyNames: B2CPolicies | undefined): B2CPolicies => {
	const props = [];
	for (const prop in policyNames) props.push([prop, (policyNames as { [index: string]: any })[prop]]);
	if (policyNames) return props.reduce(
		(prev, [key, policyName]) => ({ ...prev, [key]: makeAuthority(authorityBase, policyName as string) }),
		{} as B2CPolicies
	);
	return {} as B2CPolicies;
}

const authService = new AuthService();

export default authService;