import axiosClient from './client';

const API_ROOT = 'library'

class LibraryApiBase {
	public async getUploadRequestModelForDisplay(model: IRequestLibraryUploadModel): Promise<IRequestLibraryUploadResponseModel> {
		return await axiosClient.post(`${API_ROOT}/upload`, model);
	}

	public async getUploadConfirmationForPath(path: string): Promise<boolean> {
		return await axiosClient.post(`${API_ROOT}/upload/confirm?path=${encodeURI(path)}`);

	}
}

export interface IRequestLibraryUploadModel {
	displayId: number;
	templateId: number;
	layoutId: number;
	regionId: number;
	filename: string;
	fieldName: string;
}

export interface IRequestLibraryUploadResponseModel {
	libraryPath: string;
	storagePath: string;
	sharedAccessSignature: string;
}

const LibraryApi = new LibraryApiBase();
export default LibraryApi;