import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { DATE_TIME_FORMAT } from '~/utilities';

type placementType = 'bottom-end' | 'bottom-start' | 'bottom' | 'left-end' | 'left-start' | 'left' | 'right-end' | 'right-start' | 'right' | 'top-end' | 'top-start' | 'top';
const RelativeTimestamp = (props: { date: Date, format?: string, placement?: placementType }) => {
	dayjs.extend(relativeTime)
	const { date, format, placement } = props;
	const day = dayjs(date);

	return <Tooltip placement={placement || 'top'} arrow title={day.format(format || DATE_TIME_FORMAT)}>
		<span>{day.fromNow()}</span>
	</Tooltip>
}

export default RelativeTimestamp;