/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Outlet, Params, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { InteractionStatus } from '@azure/msal-browser';
import { isolateAccessToken, isolateAuthIsExpired, isolateAuthIsExpiringSoon, isolatePermissions, selectHasPermissions } from '~/selectors/auth';
import LoaderIcon from '~/components/loader-icon';
import { APP_NAME } from '~/constants';
import CustomNavigationClient from '~/services/auth-navigation';
import AuthenticatedLayout from '~/layouts/authenticated';
import UnauthenticatedLayout from '~/layouts/unauthenticated';
import './index.scss';
import { flattenArray, isAuthenticationInProgress } from '~/utilities';
import { handleRouteChange, routes, TitledRouteObject } from '~/routes';
import { isolateIsPopulated, isolateIsRefreshing } from '~/selectors/reference';
import { fullScreenOff } from '~/actions/ui';
import authService from '~/services/auth-service';


const transformPath = (s: string, routeParams: Readonly<Params<string>>): string => {
	const reg = /:[a-z]*/ig;
	if (reg.test(s)) {
		const matches = s.match(reg);
		matches?.forEach(v => {
			for (const prop in routeParams) {
				s = s.replace(v, routeParams[prop] || 'x');
			}

		})
		return s;
	} else {
		return s;
	}
}

const mapRoute = (v: TitledRouteObject, routeParams: Readonly<Params<string>>) => {
	const items = [];

	if (v.children) {
		const mappedChildrenItems = v.children.map(iv => mapRoute(iv, routeParams));
		mappedChildrenItems.forEach(iv => iv.forEach(iiv => items.push(iiv)))
	}
	const newPath = transformPath(v.path || '', routeParams)
	items.push({
		path: newPath, title: v.title, secure: v.secure === false ? false : true, permissions: v.permissions || [],
	});

	return items;
}

const getTitleMap = (routeParams: Readonly<Params<string>>): { title: string, path: string, secure: boolean, permissions: string[], }[] => flattenArray(routes.map(v => mapRoute(v, routeParams))) as { title: string, path: string, secure: boolean, permissions: string[], }[];

const HomeIndex = () => {
	const { instance, inProgress } = useMsal();
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const navigationClient = new CustomNavigationClient(navigate);
	instance.setNavigationClient(navigationClient);
	const isAuthenticated = useIsAuthenticated();
	const accessToken = useSelector(isolateAccessToken);
	const routeParams = useParams();
	const permissions = useSelector(isolatePermissions);
	const [pageTitle, setPageTitle] = useState('');
	const [mappedTitle, setMappedTitle] = useState<{ title: string, path: string, secure: boolean, permissions: string[], }>();
	const [routeSecure, setRouteSecure] = useState(true);
	const [routePermissions, setRoutePermissions] = useState<string[]>([]);
	const [progress, setProgress] = useState<boolean>(isAuthenticationInProgress(inProgress));
	const hasPerms = selectHasPermissions(permissions, routePermissions, true);
	const isReferencePopulated = useSelector(isolateIsPopulated);
	const isAuthRefreshing = useSelector(isolateIsRefreshing);
	const authExpiringSoon = useSelector(isolateAuthIsExpiringSoon);
	const authIsExpired = useSelector(isolateAuthIsExpired);
	const isReferenceRefreshing = useSelector(isolateIsRefreshing);
	const dispatch = useDispatch();
	const titleMap = getTitleMap(routeParams);
	const { initializing } = authService;

	useEffect(() => {
		dispatch(fullScreenOff());
	}, []);

	useEffect(() => {


		const handleRoute = () => {


			const obj = getAndSetRouteFromMap();
			const fin = { accessToken, initializing, isAuthenticated, inProgress, isReferencePopulated, isReferenceRefreshing, pathname, navigate, routePermissions: obj.permissions, routeSecure: obj.secure, permissions };
			console.log(`aoqwpaslk fin ${JSON.stringify(fin)}
		
		initializing			${initializing}
		pathname			${pathname}
		isAuthenticated			${isAuthenticated}
		inProgress			${inProgress}
		none prog			${InteractionStatus.None}
		in = none			${[InteractionStatus.None].indexOf(inProgress)}
		isReferencePopulated		${isReferencePopulated}
		funcResult			${isAuthenticationInProgress(inProgress)}
		showLoader			${isAuthenticated && isAuthenticationInProgress(inProgress) && isReferencePopulated}
		progress			${progress}
		`)
			handleRouteChange(fin);
		};

		handleRoute();

	}, [pathname, isAuthenticated, inProgress, progress, isReferencePopulated, isAuthRefreshing, initializing]);

	useEffect(() => {
		const handleAuth = () => {
			if (authExpiringSoon && isAuthenticated) {
				console.log(`WOULD NORMALLY DISPATCH REFRESH DUE TO AUTH EXP SOON 
				authExpiringSoon ${authExpiringSoon} 
				isAuthenticated ${isAuthenticated}`);
				//				dispatch(refresh());
			}
		}

		handleAuth();

	}, [pathname]);

	const getAndSetRouteFromMap = () => {
		const curTitle = titleMap.find(item => item.path === pathname && pathname !== mappedTitle?.path);
		const retVal = { secure: routeSecure, permissions: routePermissions };
		if (curTitle) {
			setMappedTitle(curTitle);
			if (curTitle.title) {
				setPageTitle(curTitle.title);
				document.title = `${APP_NAME} | ${curTitle.title}`;
			}
			setRouteSecure(curTitle.secure);
			setRoutePermissions(curTitle.permissions);
			retVal.secure = curTitle.secure;
			retVal.permissions = curTitle.permissions;
		}
		return retVal;
	}

	return <div className="home-index">
		{
			isAuthenticated && isReferencePopulated && isAuthRefreshing && authIsExpired ? <LoaderIcon /> :
				isAuthenticated && hasPerms && isReferencePopulated ? <AuthenticatedLayout title={pageTitle}>
					<Outlet />
				</AuthenticatedLayout> :
					<UnauthenticatedLayout title={pageTitle}>
						{isAuthenticationInProgress(inProgress) ? <LoaderIcon /> : !routeSecure && hasPerms ? <Outlet /> : <LoaderIcon />}
					</UnauthenticatedLayout>
		}
	</div>
}

export default HomeIndex;