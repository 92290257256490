import { createSelector } from 'reselect';
import * as ReferenceTypes from './../api/reference';
import { RootState } from '~/stores';

const isolateReference = (s: RootState) => s.reference;
const isolateState = (s: RootState) => isolateReference(s).state;
export const isolateIsRefreshing = (s: RootState) => isolateState(s).isRefreshing;
export const isolateIsPopulated = (s: RootState) => isolateState(s).isPopulated;
export const isolateTags = (s: RootState) => isolateReference(s).tags;
export const isolateTemplates = (s: RootState) => isolateReference(s).templates;
export const isolateSites = (s: RootState) => isolateReference(s).sites;
export const isolateLayouts = (s: RootState) => isolateReference(s).layouts;
export const isolateManufacturers = (s: RootState) => isolateReference(s).manufacturers;
export const isolateModels = (s: RootState) => isolateReference(s).models;
export const isolateWidgets = (s: RootState) => isolateReference(s).widgets;
export const isolateWidgetCategories = (s: RootState) => isolateReference(s).widgetCategories;
export const isolateOperationModes = (s: RootState) => isolateReference(s).operatingModes;
export const isolateDeviceTypes = (s: RootState) => isolateReference(s).deviceTypes;
export const isolateTokens = (s: RootState) => isolateReference(s).tokens;

const selectSites = (s: ReferenceTypes.ISiteModel[]) => s;
const selectSiteId = (z: ReferenceTypes.ISiteModel[], s: number) => s;

export const selectSiteName: (state: ReferenceTypes.ISiteModel[], siteId: number) => string = createSelector([selectSites, selectSiteId], (sites: ReferenceTypes.ISiteModel[], siteId: number) => sites.find(v => v.siteId === siteId)?.siteName || '');

const selectModels = (s: ReferenceTypes.IModelModel[]) => s;
const selectModelId = (z: ReferenceTypes.IModelModel[], s: number) => s;
const selectDeviceTypesModels = (s: ReferenceTypes.IDeviceTypeModel[], z: ReferenceTypes.IModelModel[], y: number) => s;
const selectDeviceTypeModels = (z: ReferenceTypes.IDeviceTypeModel[], s: ReferenceTypes.IModelModel[], y: number) => s;
const selectDeviceTypeModelId = (z: ReferenceTypes.IDeviceTypeModel[], y: ReferenceTypes.IModelModel[], s: number) => s;

export const selectModelName: (state: ReferenceTypes.IModelModel[], modelId: number) => string = createSelector([selectModels, selectModelId], (models: ReferenceTypes.IModelModel[], modelId: number) => models.find(v => v.modelId === modelId)?.modelName || '');
export const selectDeviceTypeNameForModel: (deviceTypes: ReferenceTypes.IDeviceTypeModel[], models: ReferenceTypes.IModelModel[], modelId: number) => string = createSelector([selectDeviceTypesModels, selectDeviceTypeModels, selectDeviceTypeModelId], (deviceTypes: ReferenceTypes.IDeviceTypeModel[], models: ReferenceTypes.IModelModel[], modelId: number) => {
	const model = models.find(v => v.modelId === modelId);
	return deviceTypes.find(v => v.deviceTypeId === model?.deviceTypeId)?.deviceTypeName || '';
});

const selectManufacturers = (s: ReferenceTypes.IManufacturerModel[]) => s;
const selectManufacturerId = (z: ReferenceTypes.IManufacturerModel[], s: number) => s;

export const selectManufacturerName: (state: ReferenceTypes.IManufacturerModel[], manufacturerId: number) => string = createSelector([selectManufacturers, selectManufacturerId], (manufacturers: ReferenceTypes.IManufacturerModel[], manufacturerId: number) => manufacturers.find(v => v.manufacturerId === manufacturerId)?.manufacturerName || '');

const selectDeviceTypes = (s: ReferenceTypes.IDeviceTypeModel[]) => s;
const selectDeviceTypeId = (z: ReferenceTypes.IDeviceTypeModel[], s: number) => s;

export const selectDeviceTypeName: (state: ReferenceTypes.IDeviceTypeModel[], deviceTypeId: number) => string = createSelector([selectDeviceTypes, selectDeviceTypeId], (deviceTypes: ReferenceTypes.IDeviceTypeModel[], deviceTypeId: number) => deviceTypes.find(v => v.deviceTypeId === deviceTypeId)?.deviceTypeName || '');

const selectWidgetCategoryies = (s: ReferenceTypes.IWidgetCategoryModel[]) => s;
const selectWidgetCategoryId = (z: ReferenceTypes.IWidgetCategoryModel[], s: number) => s;

export const selectWidgetCategoryName: (state: ReferenceTypes.IWidgetCategoryModel[], widgetCategoryId: number) => string = createSelector([selectWidgetCategoryies, selectWidgetCategoryId], (widgetCategories: ReferenceTypes.IWidgetCategoryModel[], widgetCategoryId: number) => widgetCategories.find(v => v.widgetCategoryId === widgetCategoryId)?.widgetCategoryName || '');

const selectTokens = (s: ReferenceTypes.ITokenModel[]) => s;
const selectContainer = (z: ReferenceTypes.ITokenModel[], s: string) => s;

export const selectStorageToken: (state: ReferenceTypes.ITokenModel[], container: string) => string = createSelector([selectTokens, selectContainer], (tokens: ReferenceTypes.ITokenModel[], container: string) => tokens.find(v => v.container === container)?.token || '');

const selectTags = (s: ReferenceTypes.ITagModel[]) => { return s };
const selectSection = (z: ReferenceTypes.ITagModel[], s: 'screens') => s;
const selectIsSystem = (z: ReferenceTypes.ITagModel[], x: 'screens', s: boolean = false) => s;

export const selectTagsForSection: (state: ReferenceTypes.ITagModel[], section: 'screens', isSystem: boolean) => ReferenceTypes.ITagModel[] = createSelector([selectTags, selectSection, selectIsSystem], (f: ReferenceTypes.ITagModel[], s: 'screens', isSystem: boolean) => f.filter(v => v.section === s && v.isSystem === isSystem));