import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { Draggable, Map, Marker, Point } from 'pigeon-maps'
import { osm } from 'pigeon-maps/providers'
import { Autocomplete } from '@mui/material';
import { useSelector } from 'react-redux';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/system';
import { IDisplayDetailsOutletContext } from '../../details';
import * as ScreensHelpers from '../../helpers';
import InputText from '~/components/input-text';
import { isolateDeviceTypes, isolateManufacturers, isolateModels, isolateOperationModes, isolateSites, isolateTags, selectTagsForSection } from '~/selectors/reference';
import './edit.scss';
import { isNullOrEmpty } from '~/utilities';

interface IAdditionalDetailsProps {
	siteId: number;
	modelId: number;
	manufacturerId: number;
	displayLatitude?: number;
	displayLongitude?: number;
	setDisplayLatitude?: React.Dispatch<React.SetStateAction<number>>;
	setDisplayLongitude?: React.Dispatch<React.SetStateAction<number>>;
}

const getHasSiteMarker = (a: number, b: number) => [a, b].indexOf(0) === -1;

const AdditionalDetails = (props: IAdditionalDetailsProps) => {
	const { displayLatitude, displayLongitude, manufacturerId, modelId, setDisplayLatitude, setDisplayLongitude, siteId } = props;
	const sites = useSelector(isolateSites);
	const manufacturers = useSelector(isolateManufacturers);
	const models = useSelector(isolateModels);
	const deviceTypes = useSelector(isolateDeviceTypes);
	const manufacturerName = manufacturers.find(v => v.manufacturerId === manufacturerId)?.manufacturerName;
	const model = models.find(v => v.modelId === modelId);
	const modelName = model?.modelName;
	const deviceTypeName = deviceTypes.find(v => v.deviceTypeId === model?.deviceTypeId)?.deviceTypeName;
	const site = sites.find(v => v.siteId === siteId);
	const latitude = site?.latitude || 0;
	const longitude = site?.longitude || 0;
	const showMap = (site !== undefined && site.latitude !== undefined && site.longitude !== undefined) || (displayLatitude !== undefined && displayLongitude !== undefined);
	const [mapHeight, setMapHeight] = useState(0);
	const { palette } = useTheme();
	const { primary } = palette;
	const markerSize = 50;
	const [hasSiteMarker, setHasSiteMarker] = useState(getHasSiteMarker(latitude, longitude));
	const [hasDisplayMarker, setHasDisplayMarker] = useState(true);
	const [displayAnchor, setDisplayAnchor] = useState<Point>([displayLatitude || 0, displayLongitude || 0]);
	
	useEffect(() => {
		setHasSiteMarker(getHasSiteMarker(latitude, longitude));
	});

	useEffect(() => {
		const gridItemReference = document.querySelector('.grid-item-reference');
		if (gridItemReference) {
			const { clientHeight } = gridItemReference;
			const cs = getComputedStyle(gridItemReference);
			const { paddingTop } = cs;
			setMapHeight((clientHeight) * 3 - parseFloat(paddingTop));
		}
	})

	useEffect(() => {
		setDisplayAnchor([displayLatitude || 0, displayLongitude || 0]);

	}, [displayLatitude, displayLongitude])

	const displayAnchorDragMove = (point: Point) => {
		const round = 9;
		const v = [parseFloat(point[0].toFixed(round)), parseFloat(point[1].toFixed(round))]
		if (setDisplayLatitude) setDisplayLatitude(v[0]);
		if (setDisplayLongitude) setDisplayLongitude(v[1]);
	}

	return <Box>
		<Grid container spacing={2}>
			{
				showMap ?
					<Grid item xs={12}>
						<div className="map-wrapper"><Map provider={osm} height={mapHeight} defaultCenter={[latitude, longitude]}>
							{hasSiteMarker && <Marker className='site-marker' hover={true} width={markerSize / 2} anchor={[latitude, longitude]} color={(primary as { light: string })?.light || undefined} />}
							{hasDisplayMarker && <Draggable className='draggable-marker' offset={[markerSize / 4, (markerSize / 2) + 4]} anchor={displayAnchor} onDragMove={displayAnchorDragMove}><Marker width={markerSize / 2} color={(primary as { main: string })?.main || undefined} anchor={[displayLatitude || 0, displayLongitude || 0]}></Marker></Draggable>}
						</Map>
						</div>
					</Grid> :
					<Grid item xs={12}>
						<div className="map-wrapper empty" style={{ backgroundColor: palette.divider, height: mapHeight }}>
							<div>No map available</div>
						</div>
					</Grid>
			}
			<Grid item className='grid-item-reference' xs={6}>
				<InputText disabled={true} value={manufacturerName} label={'Manufacturer'}></InputText>
			</Grid>
			<Grid item xs={6}>
				<InputText disabled={true} value={modelName} label={'Model'}></InputText>
			</Grid>
			<Grid item xs={12}>
				<InputText disabled={true} value={deviceTypeName} label={'Device Type'}></InputText>
			</Grid>
		</Grid>
	</Box>
}

const EditDisplayDetails = () => {
	const tags = useSelector(isolateTags);
	const allScreenTags = selectTagsForSection(tags, 'screens', false);
	const sites = useSelector(isolateSites);
	
	const {
		displayName, setDisplayName,
		displaySiteId, setDisplaySiteId,
		displayTags, setDisplayTags,
		displayLayouts,
		screenModelId, screenManufacturerId, screenSerialNumber,
		displayCurrentOperatingModeId, setDisplayCurrentOperatingModeId,
		displayDefaultOperatingModeId, setDisplayDefaultOperatingModeId,
		displayLatitude, setDisplayLatitude,
		displayLongitude, setDisplayLongitude,
		screenLatitude, screenLongitude,
	} = useOutletContext<IDisplayDetailsOutletContext>();
	const operatingModes = useSelector(isolateOperationModes).filter(v=> displayLayouts.map(iv=>iv.operatingModeId).indexOf(v.operatingModeId) !== -1);
	const [nameError, setNameError] = useState<boolean>(false);
	const [siteIdError, setSiteIdError] = useState<boolean>(false);
	const [siteName, setSiteName] = useState<string | undefined>(sites.find(v => v.siteId === displaySiteId)?.siteName);
	const [currentOperatingModeName, setCurrentOperatingModeName] = useState<string | undefined>(operatingModes.find(v => v.operatingModeId === displayCurrentOperatingModeId)?.operatingModeName);
	const [defaultOperatingModeName, setDefaultOperatingModeName] = useState<string | undefined>(operatingModes.find(v => v.operatingModeId === displayDefaultOperatingModeId)?.operatingModeName);
	const [latitudeError, setLatitudeError] = useState<boolean>(false);
	const [longitudeError, setLongitudeError] = useState<boolean>(false);

	useEffect(() => {
		const { definition } = ScreensHelpers.validateScreen({ entity: { displayName, siteId: displaySiteId }});
		setSiteIdError(!definition.siteId);
		setNameError(!definition.displayName);

		const site = sites.find(v => v.siteId === displaySiteId);
		setSiteName(site?.siteName);
	}, []);

	const changeName = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const v = e.target.value;
		const { definition } = ScreensHelpers.validateScreen({ entity: { displayName: v }});
		setNameError(!definition.displayName);
		setDisplayName(v);
	}

	const changeScreenTags = (e: React.SyntheticEvent<Element, Event>, value: string[]) => {
		setDisplayTags(value);
	}

	const changeSite = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
		const site = sites.find(v => v.siteName === value);
		const { definition } = ScreensHelpers.validateScreen({ entity: { siteId: site?.siteId }});
		setSiteIdError(!definition.siteId);
		if (site) {
			setDisplaySiteId(site?.siteId);
			const { latitude, longitude } = site;
			if (!isNullOrEmpty(latitude) && !isNullOrEmpty(longitude)) {
				setDisplayLatitude(latitude || 0);
				setDisplayLongitude(longitude || 0);
			}
		}

		setSiteName(site?.siteName);
	}

	const changeCurrentOperatingMode = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
		const mode = operatingModes.find(v => v.operatingModeName === value);
		if (mode) setDisplayCurrentOperatingModeId(mode.operatingModeId);
		setCurrentOperatingModeName(value || undefined);
	}

	const changeDefaultOperatingMode = (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
		const mode = operatingModes.find(v => v.operatingModeName === value);
		if (mode) setDisplayDefaultOperatingModeId(mode.operatingModeId);
		setDefaultOperatingModeName(value || undefined);
	}

	const changeLatitude = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const v = parseFloat(e.target.value);
		const { definition } = ScreensHelpers.validateScreen({ entity: { latitude: v }});
		setLatitudeError(!definition.latitude);
		if (!isNaN(v)) setDisplayLatitude(v);
	}

	const changeLongitude = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
		const v = parseFloat(e.target.value);
		const { definition } = ScreensHelpers.validateScreen({ entity: { longitude: v } });
		setLongitudeError(!definition.longitude);
		if (!isNaN(v)) setDisplayLongitude(v);
	}

	return <Box className='edit-screen-details'>
		<Grid container spacing={2}>
			<Grid item xs={8} >
				<Grid container spacing={2}>
					<Grid item xs={12} >
						<InputText label="Name" required error={nameError} value={displayName} onChange={changeName} />
					</Grid>
					<Grid item xs={12} >
						<Autocomplete
							disableClearable={true}
							options={sites.map(v => v.siteName)}
							value={siteName}
							renderInput={(params) => (
								<InputText {...params} label="Location" placeholder="Location" required error={siteIdError} />
							)}
							onChange={changeSite}
						/>
					</Grid>
					<Grid item xs={12} >
						<Autocomplete
							multiple
							limitTags={2}
							options={allScreenTags.map(v => v.name)}
							value={displayTags}
							renderInput={(params) => (
								<InputText {...params} label="Tags" placeholder="Tags" />
							)}
							onChange={changeScreenTags}
						/>
					</Grid>
					<Grid item xs={6} >
						<Autocomplete
							disableClearable={true}
							options={operatingModes.map(v => v.operatingModeName)}
							value={currentOperatingModeName}
							renderInput={(params) => (
								<InputText {...params} label="Current Operating Mode" placeholder="Current Operating Mode" required />
							)}
							onChange={changeCurrentOperatingMode}
						/>
					</Grid>
					<Grid item xs={6} >
						<Autocomplete
							disableClearable={true}
							options={operatingModes.map(v => v.operatingModeName)}
							value={defaultOperatingModeName}
							renderInput={(params) => (
								<InputText {...params} label="Default Operating Mode" placeholder="Default Operating Mode" required />
							)}
							onChange={changeDefaultOperatingMode}
						/>
					</Grid>
					<Grid item xs={6} >
						<InputText label="Latitude" required error={latitudeError} value={displayLatitude} onChange={changeLatitude} />
					</Grid>
					<Grid item xs={6} >
						<InputText label="Longitude" required error={longitudeError} value={displayLongitude} onChange={changeLongitude} />
					</Grid>
				</Grid>
			</Grid>
			<Grid item xs={4}>
				<AdditionalDetails siteId={displaySiteId} modelId={screenModelId} manufacturerId={screenManufacturerId} displayLatitude={displayLatitude} displayLongitude={displayLongitude} setDisplayLatitude={setDisplayLatitude} setDisplayLongitude={setDisplayLongitude} />
			</Grid>
		</Grid>
	</Box >
}

export default EditDisplayDetails;