import { AnyAction, } from 'redux';
import { PayloadAction } from '@reduxjs/toolkit';
import * as actions from '~/actions';
import { BBXAuthenticationResult, BBXIDDokenClaims } from '~/@types';

const INITIAL_STATE: IAuthUserState = { name: '', email: '', userId: -1, currentAccountCode: '', role: '', env: [] };

const userReducer = (state: IAuthUserState = INITIAL_STATE, action: AnyAction | PayloadAction<BBXAuthenticationResult>): IAuthUserState => {

	switch (action.type) {
		case actions.AUTH_SUCCESS: {
			const typedAction: PayloadAction<BBXAuthenticationResult> = action as PayloadAction<BBXAuthenticationResult>;
			const idTokenClaims = typedAction?.payload?.idTokenClaims;
			const { name, email, userId, currentAccountCode, role, env } = idTokenClaims;
			return { name, email, userId, currentAccountCode, role, env };
		}
		case actions.AUTH_FAILURE:
		case actions.AUTH_REFRESH_FAILURE:
		// fall through
		case actions.AUTH_LOGOUT_SUCCESS:
			return INITIAL_STATE;
		default:
			return state;
	}
}

type IAuthUserState = Pick<BBXIDDokenClaims, 'name' | 'email' | 'userId' | 'currentAccountCode' | 'role' | 'env'>

export default userReducer;