import { InteractionStatus } from '@azure/msal-browser';

export const preventDefault = (event: React.MouseEvent) => {
	event.preventDefault();
}

export const isAuthenticationInProgress = (inProgress: InteractionStatus) => [InteractionStatus.None].indexOf(inProgress) === -1;

export const enumToArray = <T>(e: any): IEnumArrayItem<T>[] => {
	const results: IEnumArrayItem<T>[] = [];
	for (const x in e) {
		if (typeof x !== 'number' && isNaN(parseInt(x, 10))) {
			try {
				const typed = e as { [x: string]: any }
				const v = typed[x] as T;
				results.push({ label: x, value: v });
			} catch (ex) {
				console.error(ex);
			}
		}
	}
	return results;
}

export interface IEnumArrayItem<T> {
	label: string;
	value: T;
}

export const camelCaseToTitleCase = (str: string) => {
	const result = str.replace(/([A-Z])/g, ' $1');
	return result.charAt(0).toUpperCase() + result.slice(1);
}

export const isNullOrEmpty = (str: string | number | boolean | any | any[]): boolean => {

	// Is the value defined
	const isDefined: boolean = str !== undefined;

	// Is the value not null
	// eslint-disable-next-line no-null/no-null
	const isNotNull: boolean = str !== null;

	// Is the value a string
	const isString: boolean = isNotNull ? typeof str === 'string' : false;

	// Is the value a number
	const isNumber: boolean = isNotNull ? typeof str === 'number' : false;

	// Is the value an object
	const isObject: boolean = isNotNull ? typeof str === 'object' : false;

	// Is the value an array of values
	const isArray: boolean = isNotNull ? (isObject && Object.prototype.hasOwnProperty.call(str, 'length')) : false;

	// If it's a string
	if (isString) {
		const typed: string = str as string;

		// Trim it
		str = typed.trim();

		// Make sure its defined and not empty
		return !(isDefined && isNotNull && str !== '');

		// Done

	}

	// If it's a number make sure its defined
	if (isNumber) return !(isDefined && isNotNull);

	// If it's an array, make sure its defined and not empty
	if (isArray) {
		const typed = str as Record<string, unknown>[];
		return !(isDefined && isNotNull && typed.length > 0);
	}

	// If it's anything else, just ensure it's defined and not null
	return !(isDefined && isNotNull);

	// Done

}

export const sleep = (duration: number): Promise<void> => {
	return new Promise((r) => setTimeout(r, duration));
}

export const randomCharacters = (length: number) => {
	let result = '';
	const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	const charactersLength = characters.length;
	let counter = 0;
	while (counter < length) {
		result += characters.charAt(Math.floor(Math.random() * charactersLength));
		counter += 1;
	}
	return result;
}

// eslint-disable-next-line prefer-spread
export const flattenArray = (array: any[]): any[] => [].concat.apply([], array as ConcatArray<never>[]);

export const blobToString = async (blob: Blob): Promise<string | ArrayBuffer | null | undefined> => {
	const fileReader = new FileReader();
	return new Promise((resolve, reject) => {
		fileReader.onloadend = (ev) => {
			resolve(ev?.target?.result);
		};
		fileReader.onerror = reject;
		fileReader.readAsText(blob);
	});
}

export const getCircularReplacer = <T>() => {
	const ancestors: T[] = [];
	return (key: string, value: T) => {
		if (typeof value !== 'object' || isNullOrEmpty(value)) {
			return value;
		}
		// `this` is the object that value is contained in,
		// i.e., its direct parent.
		while (ancestors.length > 0 && ancestors.at(-1) !== this) {
			ancestors.pop();
		}
		if (ancestors.includes(value)) {
			return '[Circular]';
		}
		ancestors.push(value);
		return value;
	};
}

export const setDataDogUserProperties = (props: { userId: number, role: string, accountCode: string }, ddr: { setUserProperty: (key: any, value: any) => void }) => {
	try {
		const { userId, role, accountCode } = props;
		ddr.setUserProperty('userId', userId || undefined);
		ddr.setUserProperty('role', role || undefined);
		ddr.setUserProperty('accountCode', accountCode || undefined);
	} catch (ex) {
		console.error(ex);
	}
}

export const getVersion = (v?: string) => {
	try {
		if (v) return v;
		const tags = document.head.getElementsByTagName('meta');
		const bn = tags.namedItem('build');
		const bv = tags.namedItem('version');
		const vn = bn?.content;
		const vv = bv?.content;
		const fn = vn !== '%REACT_APP_BUILD%' ? vn : undefined;
		const fv = vv !== '%REACT_APP_VERSION%' ? vv : undefined;
		const retVal = fv && fn ? `${fv}.${fn}` : fv ? fv : undefined;
		return retVal;
	} catch (ex) {
		return undefined;
	}
}


export const WIDGET_FILE_SEPARATOR = '­'


export interface IDecodeWidgetFileUploadResponse {
	name: string | undefined;
	fileUploadId: number | undefined;
	libraryPath: string | undefined;
}

const getLibraryAndName = (encodedName: string | undefined) => {

	if (encodedName) {
		if (encodedName.indexOf(LIBRARY_PROTOCOL) !== -1) {
			const sp = encodedName.split(LIBRARY_PROTOCOL);
			const spa = sp[1].split('/');
			const path = spa.slice(0, spa.length - 1).join('/');
			const filename = spa[spa.length - 1];


			return { libraryPath: `${LIBRARY_PROTOCOL}${path}/`, name: filename };
		}
	}
	return { name: encodedName, libraryPath: undefined }
}

export const encodeWidgetFileUploadValue = (name: string | undefined, fileUploadId: number | undefined, libraryPath: string | undefined, debug: boolean = false) => {
	try {
		let lp = libraryPath;
		let nv = name;
		let fid = fileUploadId;
		const l = (v: string) => debug && console.log(`encodeWidgetFileUploadValue ${v}`);
		if (nv?.indexOf(WIDGET_FILE_SEPARATOR) !== -1) return nv;
		if (libraryPath === undefined) {
			if (nv?.indexOf(LIBRARY_PROTOCOL) !== -1) {
				const obj = getLibraryAndName(nv);
				nv = obj.name;
				lp = obj.libraryPath;
				fid = -1;
			}
		}
		const vals = [];
		vals.push(nv);
		vals.push(fid);
		vals.push(lp);
		const retVal = vals.join(WIDGET_FILE_SEPARATOR);
		l(`return ${JSON.stringify(retVal)}`)
		return retVal;
	} catch (e) {
		console.error(e);
	}
}

export const decodeWidgetFileUploadValue = (encoded: string, debug: boolean = false): IDecodeWidgetFileUploadResponse | undefined => {
	try {
		const l = (v: string) => debug && console.log(`decodeWidgetFileUploadValue ${v}
		encoded ${encoded}`);
		if (isNullOrEmpty(encoded)) {
			l('Return undefined');
			return;
		}
		const split = encoded.split(WIDGET_FILE_SEPARATOR);
		if (split.length < 2 && split.length !== 1) {
			const retVal = { name: encoded, fileUploadId: undefined, libraryPath: undefined };
			l(`Return ${JSON.stringify(retVal)}`);
			return retVal;
		} else if (split.length === 1) {
			// encoded string was just given library
			const obj = getLibraryAndName(encoded);
			const nv = obj.name;
			const lp = obj.libraryPath;
			const retVal = { name: nv, fileUploadId: -1, libraryPath: lp };
			l(`Return ${JSON.stringify(retVal)}`);

			return retVal;
		} else {

			const name = split.length > 0 ? split[0] : undefined;
			const fileUploadId = split.length > 1 ? split[1] : undefined;
			const libraryPath = split.length > 2 ? split[2] : undefined;
			const retVal = { name, fileUploadId: fileUploadId ? parseInt(fileUploadId, 10) : undefined, libraryPath };
			l(`Return ${JSON.stringify(retVal)}`);

			return retVal;
		}
	} catch (ex) {
		console.error(ex);
	}
}

export const uniqueArray = <T,>(value: T, index: number, self: T[]) => {
	return self.indexOf(value) === index;
}

export const LIBRARY_PROTOCOL = 'library:';
export const DATE_FORMAT = 'DD MMM YYYY';
export const TIME_FORMAT = 'HH:mm:ss';
export const DATE_TIME_FORMAT = `${DATE_FORMAT} @ ${TIME_FORMAT}`;