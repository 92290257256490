import { all } from 'redux-saga/effects';
import apiSaga from './api';
import authSaga from './auth';
import referenceSaga from './reference';
import userSaga from './user';

export default function* rootSaga(): Generator {
	yield all([
		apiSaga(),
		authSaga(),
		referenceSaga(),
		userSaga(),
	]);
}