import { combineReducers, } from 'redux';
import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import createSagaMiddleware from 'redux-saga'
import storage from 'redux-persist/lib/storage'
import authReducer from './auth';
import apiReducer from './api';
import uiReducer from './ui';
import referenceReducer from './reference';
import userReducer from './user';
import rootSaga from '~/sagas';

export const sagaMiddleware = createSagaMiddleware();

const getPersistConfig = (key: string) => {
	return { key, storage }
}

const rootReducer = combineReducers({
	api: apiReducer,
	auth: authReducer,
	reference: referenceReducer,
	ui: uiReducer,
	user: userReducer,
});

const persistedReducer = persistReducer(getPersistConfig('root'), rootReducer);

const store = configureStore({
	reducer: persistedReducer,
	middleware: (def) => [...def({ serializableCheck: false }), sagaMiddleware]
});

sagaMiddleware.run(rootSaga);

export const persistedStore = persistStore(store);

export type RootState = ReturnType<typeof store.getState>

export default store;