import React, { useEffect } from 'react';
import { DataGrid, GridActionsCellItem, GridColumns, GridEventListener, GridRenderCellParams, GridRowId, GridRowParams, GridValueGetterParams, MuiEvent } from '@mui/x-data-grid';
import './listing.scss';
import { useNavigate } from 'react-router-dom';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';
import EditIcon from '@mui/icons-material/Edit';
import AssessmentIcon from '@mui/icons-material/Assessment';
import ScreenshotIcon from '@mui/icons-material/Screenshot';
import CircleIcon from '@mui/icons-material/Circle';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { IDisplaySearchModel } from '~/api/displays';
import { isolatePermissions, selectHasPermissions } from '~/selectors/auth';
import PermissionsService from '~/permissions';
import DisplaysApi from '~/api/displays';
import { isolateSites, selectSiteName } from '~/selectors/reference';
import RelativeTimestamp from '~/components/relative-timestamp';

const DisplaysListing = () => {
	const navigate = useNavigate();
	const [rows, setRows] = React.useState<IDisplaySearchModel[]>([]);
	const [loading, setLoading] = React.useState(false);
	const permissions: string[] = useSelector(isolatePermissions);
	const sites = useSelector(isolateSites);
	const hasTemplateRPermission = selectHasPermissions(permissions, [PermissionsService.getName('display', 'configuration')], true);
	const gotoDetails = (id: GridRowId, path: 'details' | 'configuration' | 'screenshots' | 'status') => {
		console.log(`navigating to ${path}`)
		navigate(`/displays/${id}/${path}`);
	};

	const columns: GridColumns<IDisplaySearchModel> = [
		{ field: 'displayId', headerName: 'ID', width: 90 },
		{ field: 'displayName', headerName: 'Name', width: 300, editable: false, },
		{ field: 'siteId', headerName: 'Location', width: 150, editable: false, valueGetter: (params: GridValueGetterParams<number, IDisplaySearchModel>) => selectSiteName(sites, params.row.siteId) },
		{ field: 'lastCommunication', type: 'date', headerName: 'Last Communication', editable: false, width: 200, renderCell: (params: GridRenderCellParams<Date>) => { 
			const d = dayjs(params.value);
			const offlineThreshold = dayjs().subtract(30, 'm').toDate();
			const color = d.isBefore(offlineThreshold) ? 'error' : 'success';
			const circle = <CircleIcon className='indicator' color={!params.value ? 'disabled' : color} />;
			return params.value ? <>{circle}<RelativeTimestamp date={params.value} /></> : <>{circle}<span>N/A</span></>}
		},
		{
			field: 'actions',
			type: 'actions',
			width: 80,
			getActions: (params) => {
				const items = [];
				items.push(<GridActionsCellItem
					key={`gaci-screen-${params.id}`}
					icon={<EditIcon />}
					label="Details"
					onClick={() => gotoDetails(params.id, 'details')}
					showInMenu
				/>);
				if (hasTemplateRPermission) items.push(<GridActionsCellItem
					key={`gaci-screen-config-${params.id}`}
					icon={<VideoSettingsIcon />}
					label="Configuration"
					onClick={() => gotoDetails(params.id, 'configuration')}
					showInMenu
				/>);
				items.push(<GridActionsCellItem
					key={`gaci-screen-screenshots-${params.id}`}
					icon={<ScreenshotIcon />}
					label="Screenshots"
					onClick={() => gotoDetails(params.id, 'screenshots')}
					showInMenu
				/>);
				items.push(<GridActionsCellItem
					key={`gaci-screen-status-${params.id}`}
					icon={<AssessmentIcon />}
					label="Status"
					onClick={() => gotoDetails(params.id, 'status')}
					showInMenu
				/>);
				return items;
			},
		},
	];

	useEffect(() => {
		if (rows.length === 0) {
			setLoading(true);
			void DisplaysApi.searchDisplays().then(v => { if (v) setRows(v); }).finally(() => setLoading(false));
		}
	}, []);

	const rowClick: GridEventListener<'rowClick'> = (params: GridRowParams<IDisplaySearchModel>, event: MuiEvent<React.MouseEvent>) => {
		event.defaultMuiPrevented = true;
		const display = params.row;
		console.log(`cell click ${JSON.stringify(display)}`);
		if (display) {
			console.log(`display ${typeof gotoDetails} ${display.displayId}`);
			setTimeout(()=> gotoDetails(display.displayId, 'details') ,0);
			// navigate(`/displays/${display.displayId}/details`);
		}
	}

	return <DataGrid
		rows={rows}
		onRowClick={rowClick}
		getRowId={x => x.displayId}
		columns={columns}
		loading={loading}
		checkboxSelection={false}
		disableSelectionOnClick
	/>


}

export default DisplaysListing;
