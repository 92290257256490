import { Action } from '@reduxjs/toolkit'
import * as Actions from './'

export const openMenu = (): Action => {
	return { type: Actions.APP_OPEN_MENU };
}

export const closeMenu = (): Action => {
	return { type: Actions.APP_CLOSE_MENU };
}

export const fullScreenOn = (): Action => {
	return { type: Actions.APP_FULL_SCREEN_TEMPLATE_ON };
}

export const fullScreenOff = (): Action => {
	return { type: Actions.APP_FULL_SCREEN_TEMPLATE_OFF };
}

export const appActive = (): Action => {
	return { type: Actions.APP_ACTIVE };
}

export const appInactive = (): Action => {
	return { type: Actions.APP_INACTIVE };
}

export const appStart = (): Action => {
	return { type: Actions.APP_START };
}
