import React from 'react';
import Tab, { TabProps } from '@mui/material/Tab';
import ErrorIcon from '@mui/icons-material/Error';
import { useTheme } from '@mui/system';
import './index.scss';
import Tooltip from '@mui/material/Tooltip';

const RouterTab = (props: IRotuerTabProps) => {
	const { children, ...rest } = props;
	const { error, errorText, label, ...remainder } = rest;
	const { palette } = useTheme();
	const errorColor = (palette as unknown as { error: { main: string } }).error.main;

	return <Tab className='router-tab' {...remainder} label={<div className='text-wrapper'>
		{label}
		{error && (
			errorText ?
				<Tooltip placement={'top'} arrow title={errorText}>
					<ErrorIcon htmlColor={errorColor} />
				</Tooltip>
				:
				<ErrorIcon htmlColor={errorColor} />
		)}
	</div>}>{children}</Tab>
}

export interface IRotuerTabProps extends TabProps {
	error?: boolean;
	errorText?: string;
}

export default RouterTab;